import PropTypes from 'prop-types'
import React, { Fragment } from 'react'
import ReactMDDrawer from 'react-md/lib/Drawers'

import styles from './style.css'
import IconButton from '../../../../../client-common/components/button/icon'
import ItemTypes from '../item-types'

export default function MobileFilterDrawer ({
  filters,
  intl,
  itemTypes,
  onVisibilityChange,
  setFilter,
  visible
}) {
  return (
    <Fragment>
      <div className='__FILTER_DRAWER__' />
      <ReactMDDrawer
        defaultVisible={false}
        header={
          <div className={styles.mobileFilterHeader}>
            <span className={styles.mobileFilterHeaderText}>
              Filter Results
            </span>
            <IconButton
              onClick={() => {
                onVisibilityChange(false)
              }}
              style={{ marginRight: -13 }}
            >
              close
            </IconButton>
          </div>
        }
        navClassName={styles.mobileFilterNavItems}
        navItems={[
          <fieldset className={styles.mobileItemTypeFieldSet}>
            <legend className={styles.mobileItemTypeFilter}>
              Content Type
            </legend>

            <ItemTypes
              filters={filters}
              intl={intl}
              itemTypes={itemTypes}
              setFilter={setFilter}
            />
          </fieldset>
        ]}
        onVisibilityChange={onVisibilityChange}
        renderNode={document.querySelector('.__FILTER_DRAWER__')}
        type={ReactMDDrawer.DrawerTypes.TEMPORARY}
        visible={visible}
      />
    </Fragment>
  )
}

MobileFilterDrawer.displayName = 'MobileFilterDrawer'

MobileFilterDrawer.propTypes = {
  filters: PropTypes.object,
  intl: PropTypes.object,
  itemTypes: PropTypes.arrayOf(PropTypes.string),
  onVisibilityChange: PropTypes.func,
  setFilter: PropTypes.func,
  visible: PropTypes.bool
}
