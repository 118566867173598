export const DISPLAY_OPTION_TYPES = {
  EXPAND_COLLAPSE: 'expand-collapse',
  NONE: 'none',
  TRUNCATE: 'truncate'
}

export const EXPAND_COLLAPSE_TYPE = {
  COLLAPSE: 'collapse',
  EXPAND: 'expand'
}

export const initialDisplayOptions = {
  type: DISPLAY_OPTION_TYPES.NONE
}
