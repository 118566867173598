/* Copyright © 2016 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */

import { map } from 'lodash'
import PropTypes from 'prop-types'
import React from 'react'

const OptionsMultiselectView = props => {
  let { schema, data } = props
  if (schema && schema.type === 'OptionsCheckboxes') {
    data = [ ...data ].sort((a, b) => {
      const nameA = a.name || ''
      const nameB = b.name || ''
      return nameA.localeCompare(nameB)
    })
  }
  return (
    <div>
      {map(data, option => (
        <div key={option.id}>
          {option.name}
        </div>
      ))}
    </div>
  )
}

OptionsMultiselectView.displayName = 'OptionsMultiselectView'
OptionsMultiselectView.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired
    })
  )
}

export default OptionsMultiselectView
