/* Copyright © 2016 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */

/* eslint react/forbid-prop-types:0 */
import { get, map, sortBy } from 'lodash'
import PropTypes from 'prop-types'
import React from 'react'
import { Link } from 'react-router'

const CourseMultiselectView = props => {
  const courses = sortBy(get(props, 'courses'), '__catalogCourseId')
  return (
    <div>
      {courses &&
        map(courses, c => c && (
          <div key={c.pid}>
            <Link to={`/courses/${c.pid}`}>{get(c, '__catalogCourseId')} </Link>
            - {get(c, 'title', '')}
          </div>
        ))}
    </div>
  )
}

CourseMultiselectView.displayName = 'CourseMultiselectView'

CourseMultiselectView.propTypes = {
  courses: PropTypes.array
}

export default CourseMultiselectView
