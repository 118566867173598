import React from 'react'
import { map } from 'lodash'

import styles from './style'

const AliasView = props => {
  const { data } = props

  return (
    <table className={styles.table}>
      <thead>
        <tr>
          <th scope='col'>{'Subject Code'}</th>
          <th scope='col'>{'Number'}</th>
        </tr>
      </thead>
      <tbody>
        {map(data, alias => (
          <tr>
            <td>{alias.name || '--'}</td>
            <td>{alias.number || '--'}</td>
          </tr>
        ))}
      </tbody>
    </table>
  )
}

export default AliasView
