const webSafeFonts = [
  'Andale Mono',
  'Arial Black',
  'Arial',
  'Baskerville',
  'Bradley Hand',
  'Brush Script MT',
  'Comic Sans MS',
  'Courier New',
  'Courier',
  'Garamond',
  'Georgia',
  'Gill Sans',
  'Helvetica',
  'Impact',
  'Lucida',
  'Luminari',
  'Monaco',
  'Palatino',
  'Tahoma',
  'Times New Roman',
  'Trebuchet MS',
  'Verdana'
]

export default webSafeFonts
