/* Copyright © 2016 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */

import axios from 'axios'
import { get, isEmpty } from 'lodash'

let cache = {}
const EXPIRATION_TIME = 60000 * 60 // 1 hour

export async function getCachedEndpoint (endpoint, useCache = true) {
  const cachedData = cache[endpoint]
  const expired = isExpired(cachedData)
  if (isEmpty(cachedData) || expired || !useCache) {
    cache[endpoint] = await getCacheObject(endpoint)
  }
  return cache[endpoint].data
}

export function isExpired (_cache) {
  const timestamp = get(_cache, 'timestamp')
  return !timestamp || Date.now() - timestamp > EXPIRATION_TIME
}

export async function getCacheObject (endpoint) {
  const { data } = await axios(endpoint)
  return {
    data,
    timestamp: Date.now()
  }
}

export function setCache (newCache) {
  cache = newCache
}
