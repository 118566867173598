/* Copyright © 2016 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */

import { map } from 'lodash'
import PropTypes from 'prop-types'
import React from 'react'

import style from './style'

const ProgramOutcomesView = ({ data }) => (
  <div className={style.outcomes}>
    <ol>{map(data, (outcome, i) => <li key={i}>{outcome.value}</li>)}</ol>
  </div>
)

ProgramOutcomesView.displayName = 'ProgramOutcomesView'

ProgramOutcomesView.propTypes = {
  data: PropTypes.array
}

export default ProgramOutcomesView
