import PropTypes from 'prop-types'
import React from 'react'
import qs from 'querystring'

import IconButton from '../button/icon'
import Icon from '../icon'
import { injectIntl, defineMessages } from 'react-intl'
import { get } from 'lodash'

const messages = defineMessages({
  printThisPage: {
    id: 'print-button.print-this-page',
    defaultMessage: 'Print This Page'
  }
})

const PrintButton = ({ children, intl, catalogId }, { isPrint }) => {
  const openPrintView = () => {
    const splitUrl = window.location.href.split('?')

    let qParams = { isPrint: true }
    const { translated } = qs.parse(splitUrl[1])
    if (translated) {
      qParams.translated = true
    }
    const toOpen = `${splitUrl[0]}?${qs.stringify(qParams)}`
    const windowProps = 'menubar=0,location=0,resizable=0,scrollbars=0,status=0'

    let newWindow
    const postMessageWhenReady = msg => {
      if (get(msg, 'data.payload') === 'ready') {
        newWindow.postMessage({ source: 'catalog-admin', payload: catalogId })
        window.removeEventListener('message', postMessageWhenReady)
      }
    }

    window.addEventListener('message', postMessageWhenReady, false)
    newWindow = window.open(toOpen, '_blank', windowProps)
    newWindow.focus()
  }
  if (isPrint) return null

  if (children) {
    return (
      <button
        onClick={openPrintView}
        style={{
          color: 'inherit',
          fontFamily: 'inherit',
          fontSize: 16,
          lineHeight: '20px'
        }}
      >
        <Icon style={{ verticalAlign: 'top', paddingRight: 15 }}>print</Icon>
        {children}
      </button>
    )
  } else {
    return (
      <IconButton
        className='kuali-print-hide'
        name='Print This Page'
        tooltipLabel={intl.formatMessage(messages.printThisPage)}
        onClick={openPrintView}
      >
        print
      </IconButton>
    )
  }
}

PrintButton.displayName = 'Print Button'
PrintButton.contextTypes = {
  isPrint: PropTypes.bool
}

export default injectIntl(PrintButton)
