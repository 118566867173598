import React from 'react'
import { map, noop } from 'lodash'
import PropTypes from 'prop-types'
import replace from '../../../../../client-common/lib/ui-text-replace'

export default function ItemTypes ({ filters, intl, itemTypes, setFilter }) {
  function isChecked (itemType) {
    if (!filters[itemType]) return false
    return true
  }

  return map(itemTypes, itemType => {
    return (
      <div
        key={itemType}
        style={{
          display: 'flex',
          alignItems: 'center',
          verticalAlign: 'middle'
        }}
      >
        <input
          checked={isChecked(itemType)}
          id={`filter-${itemType}`}
          onChange={e => setFilter(itemType, e.target.checked)}
          type='checkbox'
        />
        <label htmlFor={`filter-${itemType}`}>
          {replace(itemType, true, intl.locale)}
        </label>
      </div>
    )
  })
}

ItemTypes.propTypes = {
  filters: PropTypes.object,
  intl: PropTypes.shape({ locale: PropTypes.string }),
  itemTypes: PropTypes.arrayOf(PropTypes.string),
  setFilter: PropTypes.func
}

ItemTypes.defaultProps = {
  filters: {},
  intl: { locale: 'en' },
  setFilter: noop
}
