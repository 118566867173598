/* Copyright © 2016 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */

import PropTypes from 'prop-types'
import React from 'react'
import ReactMDCircularProgress from 'react-md/lib/Progress/CircularProgress'

const CircularProgress = props => {
  const { thickness, ...rest } = props
  return (
    <ReactMDCircularProgress
      id='circular progress'
      scale={props.thickness}
      {...rest}
    />
  )
}

CircularProgress.displayName = 'CircularProgress'
CircularProgress.propTypes = {
  thickness: PropTypes.number
}

export default CircularProgress
