/* Copyright © 2016 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co..
 */

/* eslint react/no-danger: 0 */
import axios from 'axios'
import { get, map, isEmpty } from 'lodash'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import DocumentTitle from 'react-document-title'

import AddendumView from '../../../../client-common/components/addendum-view'
import CircularProgress from '../../../../client-common/components/circular-progress'
import TopLevelPanel from '../../../../client-common/components/top-level-panel'
import { PublicCatalogType } from '../../../../client-common/lib/types'
import ItemDetails from '../../../../common/item-details'
import style from './course-view.css'
import { defineMessages, FormattedMessage, injectIntl } from 'react-intl'
import { printIfNeeded } from '../printing'
import { appendExtensionToTitle } from '../../../../common/extensions/helpers'
import { loadFeatureFlags } from '../../../../common/lib/feature-flags'

import {
  maybeRenderTranslationButton,
  replaceItemKeysWithTranslation,
  shouldRenderTranslation,
  itemHasTranslations
} from '../../../../client-common/lib/translation/translation'

const messages = defineMessages({
  changesSincePublicationTitle: {
    id: 'changes-since-publication-title',
    defaultMessage: 'Changes Since {title} Publication'
  }
})

class CourseView extends Component {
  static displayName = 'CourseView'

  static propTypes = {
    catalog: PublicCatalogType,
    mq: PropTypes.string,
    params: PropTypes.shape({
      id: PropTypes.string,
      pid: PropTypes.string
    }),
    schemas: PropTypes.object
  }

  static contextTypes = {
    isPrint: PropTypes.bool
  }

  constructor () {
    super()
    this.state = { course: null, didPrint: false }
    this.printIfNeeded = printIfNeeded.bind(this)
  }

  async loadFeatureFlags () {
    const featureFlags = await loadFeatureFlags()
    this.setState({ featureFlags })
  }

  componentDidMount () {
    this.fetchCourse(this.props)
    this.loadFeatureFlags()
  }

  componentDidUpdate () {
    this.printIfNeeded(this.state.course)
  }

  componentWillReceiveProps (nextProps) {
    this.fetchCourse(nextProps)
  }

  buildTitle (course, catalogSettings, schemas, showTranslation) {
    let titleValue = get(course, 'title')

    if (showTranslation) {
      const titleTranslation = get(course, 'titletranslation', false)

      if (titleTranslation) {
        titleValue = titleTranslation
      }
    }
    const title = `${get(course, '__catalogCourseId')} - ${titleValue}`
    return appendExtensionToTitle(
      catalogSettings,
      course,
      'courses',
      schemas,
      title,
      showTranslation
    )
  }

  async fetchCourse (props) {
    if (
      !props.params ||
      (!props.params.pid && !props.params.id) ||
      props.params.pid === 'undefined' ||
      props.params.id === 'undefined'
    ) {
      window.location = '#/home'
    }
    const {
      catalog,
      params: { id, pid }
    } = props
    const endpoint = pid
      ? `/course/${catalog._id}/${pid}`
      : `/course/byId/${catalog._id}/${id}`
    try {
      const { data } = await axios.get(endpoint)
      this.getAddenda()
      this.setState({ course: data })
    } catch (e) {
      if (e.message.indexOf('404') !== -1) window.location = '#/404'
    }
  }

  getAddenda = async () => {
    const {
      catalog,
      params: { pid }
    } = this.props
    const { data: addenda } = await axios.get(
      `/addenda/courses/${catalog._id}/${pid}`
    )
    this.setState({ addenda })
  }

  render () {
    const { addenda, course, featureFlags } = this.state
    const { catalog, mq, schemas } = this.props
    const { title, settings } = catalog
    const { isPrint } = this.context
    const alternateLanguage = get(settings, 'alternateTranslation.language')
    let { keys: courseKeys } = get(settings, 'courses', {})

    let shouldShowTranslations = shouldRenderTranslation(catalog, featureFlags)

    let courseHasTranslations = itemHasTranslations(course, courseKeys)

    let translationLocaleCode
    if (shouldShowTranslations && courseHasTranslations) {
      courseKeys = replaceItemKeysWithTranslation(
        course,
        courseKeys,
        schemas,
        'courses'
      )
      translationLocaleCode = alternateLanguage
    }

    return (
      <div>
        <TopLevelPanel>
          {!course && <CircularProgress style={{ left: '50%' }} />}
          {course && (
            <div className={style.itemDetailContainer}>
              <DocumentTitle
                title={this.buildTitle(
                  course,
                  settings,
                  schemas,
                  shouldShowTranslations
                )}
              />

              <div className={style.itemTitleAndTranslationButton}>
                <div>
                  <h2>
                    {this.buildTitle(
                      course,
                      settings,
                      schemas,
                      shouldShowTranslations
                    )}
                  </h2>
                </div>

                <div className={style.translationButtonContainer}>
                  {maybeRenderTranslationButton(
                    catalog,
                    courseHasTranslations,
                    featureFlags,
                    isPrint
                  )}
                </div>
              </div>

              <ItemDetails
                catalogId={catalog._id}
                alternateLanguage={alternateLanguage}
                dataClassName={style.pre}
                displayOptions={get(settings, 'courses.displayOptions', {})}
                item={course || {}}
                itemType='courses'
                keys={courseKeys || []}
                labelClassName={style.label}
                replacements={get(settings, 'courses.replacements', {})}
                schemas={schemas || {}}
                translationLocaleCode={translationLocaleCode}
              />
            </div>
          )}
        </TopLevelPanel>
        {!isEmpty(addenda) && (
          <TopLevelPanel isMain={false}>
            <div className={style.addendaHeader}>
              <FormattedMessage
                {...messages.changesSincePublicationTitle}
                values={{ title: title }}
              />
            </div>
            {map(addenda, (addendum, index) => (
              <div className={style.addendumViewWrapper} key={index}>
                <AddendumView
                  addendum={addendum}
                  settings={settings}
                  key={index}
                  mq={mq}
                  schemas={schemas}
                />
              </div>
            ))}
          </TopLevelPanel>
        )}
      </div>
    )
  }
}

export default injectIntl(CourseView)
