/* Copyright © 2016 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */

/* eslint react/forbid-prop-types: 0 react/no-danger: 0 */
import cx from 'classnames'
import { get, has, map } from 'lodash'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { addLocaleData, injectIntl, IntlProvider } from 'react-intl'
import es from 'react-intl/locale-data/es'
import fr from 'react-intl/locale-data/fr'

import { Collapse } from 'react-md'

import AliasView from '../alias-view/index'
import CheckboxesView from '../checkboxes-view'
import CreditsView from '../credits-view'
import CollapsibleBox from '../collapsible-box'
import CourseMultiselectView from '../course-multiselect-view'
import OptionsMultiselectView from '../options-multiselect-view'
import OmegaRulesView from '../omega-rules-view'
import PickOneView from '../pickone-view'
import ProgramOutcomesView from '../program-outcomes-view'
import RepeatableForCreditsView from '../repeatable-for-credits-view'
import RichTextView from '../rich-text-view'
import TableView from '../table-view'
import TypeaheadView from '../typeahead-view'
import { loadFeatureFlags } from '../lib/feature-flags'
import {
  DISPLAY_OPTION_TYPES,
  EXPAND_COLLAPSE_TYPE
} from '../../client-common/lib/gadget-display-options'

import * as messagesES from './../../i18n/translations/locales/es.json'
import * as messagesFR from './../../i18n/translations/locales/fr.json'

import style from './style'

addLocaleData([...es, ...fr])

// The height of three lines at 20px
const TRUNCATED_HEIGHT = 60

const messages = {
  en: null,
  es: messagesES,
  fr: messagesFR
}

class ItemDetails extends Component {
  static displayName = 'ItemDetails'

  static defaultProps = {
    displayOptions: {}
  }

  static propTypes = {
    catalogId: PropTypes.string,
    className: PropTypes.string,
    dataClassName: PropTypes.string,
    displayOptions: PropTypes.object,
    item: PropTypes.object.isRequired,
    itemType: PropTypes.string.isRequired,
    keys: PropTypes.array.isRequired,
    labelClassName: PropTypes.string,
    replacements: PropTypes.object.isRequired,
    schemas: PropTypes.object.isRequired,
    translationLocaleCode: PropTypes.string
  }

  constructor (props) {
    super(props)
    this.state = {
      featureFlags: {},
      truncatedGadgets: {},
      textGadgetHeights: {},
      collapseMinHeights: {}
    }
    this.textGadgetRefs = {}
    this.collapseRefs = {}
  }

  onWindowResize = () => {
    this.setTextHeights()
  }

  setTextHeights = () => {
    const textGadgetHeights = {}
    for (const key in this.textGadgetRefs) {
      textGadgetHeights[key] = this.textGadgetRefs[key].offsetHeight
    }

    const collapseMinHeights = {}
    for (const key in this.collapseRefs) {
      collapseMinHeights[key] = this.collapseRefs[key].offsetHeight
    }
    this.setState({ textGadgetHeights, collapseMinHeights })
  }

  componentDidMount () {
    this.loadFeatureFlags()
    this.setTextHeights()
    window.addEventListener('resize', this.onWindowResize)
  }

  componentWillUnmount () {
    window.removeEventListener('resize', this.onWindowResize)
  }

  async loadFeatureFlags () {
    const featureFlags = await loadFeatureFlags()
    this.setState({ featureFlags })
  }

  renderTitle (type, data, itemType, schema, key, translationLocaleCode) {
    const { labelClassName, replacements } = this.props
    let maybeTranslatedReplacements = { ...replacements }

    let gadgetLabel = get(schema, `${key}.label`)
    if (translationLocaleCode) {
      let translatedLabel = get(
        schema,
        `${key}.translatedLabels.${translationLocaleCode}`
      )
      if (translatedLabel) {
        maybeTranslatedReplacements[key] = translatedLabel
      }
    }

    return (
      <ItemTitle
        gadgetLabel={gadgetLabel}
        gadgetKey={key}
        labelClassName={labelClassName}
        replacements={maybeTranslatedReplacements}
      />
    )
  }

  onTruncateClick (key) {
    const { truncatedGadgets } = this.state
    if (!has(truncatedGadgets, [key])) {
      truncatedGadgets[key] = false
    } else {
      truncatedGadgets[key] = !truncatedGadgets[key]
    }
    this.setState({ truncatedGadgets })
  }

  render () {
    const {
      alternateLanguage,
      catalogId,
      className,
      dataClassName,
      displayOptions,
      intl,
      item,
      itemType,
      keys,
      labelClassName,
      replacements,
      schemas,
      translationLocaleCode
    } = this.props
    const {
      featureFlags,
      truncatedGadgets,
      textGadgetHeights,
      collapseMinHeights
    } = this.state
    const schema = schemas[itemType]

    return (
      <div>
        {map(keys, key => {
          if (!item[key]) return null
          if (
            (schema[key].type === 'OptionsCheckboxes' || schema[key].type === 'Checkboxes') &&
            Object.values(item[key]).every(v => !v)
          ) {
            return null
          }

          const displayOptionType = get(
            displayOptions,
            [key, 'type'],
            DISPLAY_OPTION_TYPES.NONE
          )
          const initialExpandCollapse = get(
            displayOptions,
            [key, 'initialExpandCollapse'],
            EXPAND_COLLAPSE_TYPE.COLLAPSE
          )

          const gadgetType = get(schema, `${key}.type`)
          const gadgetLabel = get(schema, `${key}.label`)

          let dataKey = key
          const translatedLabel = get(
            schema,
            `${key}.translatedLabels.${translationLocaleCode}`
          )

          if (
            gadgetType === 'OmegaRules' &&
            translationLocaleCode &&
            translatedLabel
          ) {
            dataKey += 'translation'
          }

          const gadgetData = get(item, dataKey)
          const selfRenderProps = {
            dataClassName,
            featureFlags,
            gadgetLabel,
            labelClassName,
            replacements
          }
          const [gadgetDataRender, selfRendered] = renderData(
            gadgetType,
            gadgetData,
            intl,
            itemType,
            schema,
            key,
            selfRenderProps,
            catalogId,
            replacements,
            alternateLanguage,
            translationLocaleCode
          )
          const showTruncationToggle =
            displayOptionType === DISPLAY_OPTION_TYPES.TRUNCATE &&
            textGadgetHeights[key] > collapseMinHeights[key]
          const isTruncated = get(
            truncatedGadgets,
            [key],
            displayOptionType === DISPLAY_OPTION_TYPES.TRUNCATE
          )
          const truncatedToggleText = isTruncated ? 'See More' : 'See Less'
          return selfRendered ? (
            gadgetDataRender
          ) : (
            <div className={cx(className, 'noBreak')} key={`${key}-${item.id}`}>
              {displayOptionType === DISPLAY_OPTION_TYPES.EXPAND_COLLAPSE ? (
                <CollapsibleBox
                  collapsibleBoxClassName={style.collapsibleBox}
                  expandable
                  expandButtonClassName={style.expandButtonClassName}
                  headerClassName={style.collapsibleBoxHeader}
                  id={key}
                  linkButtonClassName={style.linkButtonClassName}
                  startExpanded={
                    initialExpandCollapse === EXPAND_COLLAPSE_TYPE.EXPAND
                  }
                  title={
                    translationLocaleCode
                      ? translatedLabel
                      : get(replacements, key, get(schema, `${key}.label`))
                  }
                  titleClassName={labelClassName}
                  titleComponent='h3'
                >
                  {/* This div has an overflow of hidden to prevent the margins from
                  collapsing. Otherwise the expand/collapse animation is janky. */}
                  <div className={dataClassName} style={{ overflow: 'hidden' }}>
                    {gadgetDataRender}
                  </div>
                </CollapsibleBox>
              ) : (
                <span>
                  {this.renderTitle(
                    gadgetType,
                    gadgetData,
                    itemType,
                    schema,
                    key,
                    translationLocaleCode
                  )}
                  <div ref={el => (this.collapseRefs[key] = el)}>
                    <Collapse
                      collapsed={isTruncated}
                      minHeight={get(
                        collapseMinHeights,
                        [key],
                        TRUNCATED_HEIGHT
                      )}
                    >
                      <div
                        className={
                          isTruncated &&
                          textGadgetHeights[key] > collapseMinHeights[key]
                            ? style.fade
                            : style.noFade
                        }
                      >
                        <div className={isTruncated ? style.wrapper : null}>
                          <div
                            className={dataClassName}
                            ref={el => (this.textGadgetRefs[key] = el)}
                          >
                            {gadgetDataRender}
                          </div>
                        </div>
                      </div>
                    </Collapse>
                  </div>
                  {showTruncationToggle ? (
                    <div
                      className={style.truncateToggle}
                      onClick={() => this.onTruncateClick(key)}
                    >
                      {truncatedToggleText}
                    </div>
                  ) : null}
                </span>
              )}
            </div>
          )
        })}
      </div>
    )
  }
}

export default injectIntl(ItemDetails)

export const ItemTitle = ({
  gadgetLabel,
  gadgetKey,
  labelClassName,
  replacements
}) => (
  <h3 className={labelClassName}>
    {get(replacements, gadgetKey, gadgetLabel)}
  </h3>
)

ItemTitle.propTypes = {
  gadgetLabel: PropTypes.string,
  gadgetKey: PropTypes.string,
  labelClassName: PropTypes.string,
  replacements: PropTypes.object.isRequired
}

export const renderData = (
  type,
  data,
  intl,
  itemType,
  schema,
  key,
  selfRenderProps,
  catalogId,
  replacements,
  alternateLanguage,
  translationLocaleCode
) => {
  switch (type) {
    case 'Alias':
      return [<AliasView data={data} />]
    case 'Checkboxes':
      return [<CheckboxesView data={data} options={schema[key].options} />]
    case 'CourseMultiselect':
      return [<CourseMultiselectView courses={data} />]
    case 'Dropdown':
    case 'Radios':
      // Currently radios and dropdown have the same data structure
      return [<PickOneView data={data} options={schema[key].options} />]
    case 'OptionsCheckboxes':
    case 'OptionsMultiselect':
      return [<OptionsMultiselectView data={data} schema={schema[key]} />]
    case 'Credits':
      return [<CreditsView data={data} />]
    case 'GroupsTypeahead':
    case 'OptionsDropdown':
    case 'OptionsTypeahead':
    case 'ProgramTypeahead':
      if (key === 'subjectCode') {
        return [<div>{get(data, 'name', data)}</div>]
      }
      return [<TypeaheadView data={data} type={type} locale={intl.locale} />]
    case 'Outcomes':
      const gadgetLabel = get(replacements, key, get(schema, `${key}.label`))
      const labelClassName = get(selfRenderProps, 'labelClassName', '')
      const dataClassName = get(selfRenderProps, 'dataClassName', '')
      const featureFlags = get(selfRenderProps, 'featureFlags', {})
      const collapseOutcomesFlag = get(featureFlags, 'collapseOutcomes.value')
      return collapseOutcomesFlag &&
        (itemType === 'programs' || itemType === 'courses')
        ? [
          <div key={key}>
            <CollapsibleBox
              collapsibleBoxClassName={style.collapsibleBox}
              expandable
              headerClassName={style.collapsibleBoxHeader}
              id={key}
              startExpanded={false}
              title={gadgetLabel}
              titleClassName={labelClassName}
              titleComponent='h3'
            >
              <div className={dataClassName}>
                <ProgramOutcomesView data={data} />
              </div>
            </CollapsibleBox>
          </div>,
          true
        ]
        : [<ProgramOutcomesView data={data} />]
    case 'OmegaRules':
      if (translationLocaleCode) {
        return [
          <IntlProvider
            locale={alternateLanguage}
            messages={messages[alternateLanguage]}
          >
            <OmegaRulesView rules={data} catalogId={catalogId} />
          </IntlProvider>
        ]
      }
      return [<OmegaRulesView rules={data} catalogId={catalogId} />]
    case 'RepeatableForCredits':
      return [<RepeatableForCreditsView data={data} />]
    case 'RichText':
      return [<RichTextView data={data} />]
    case 'NewSpecializations':
      return [
        <div id='specializationsWrapper' key={key}>
          <ItemTitle
            gadgetLabel={get(selfRenderProps, 'gadgetLabel', '')}
            gadgetKey={key}
            labelClassName={get(selfRenderProps, 'labelClassName', '')}
            replacements={get(selfRenderProps, 'replacements', {})}
          />
          {data.specializations}
        </div>,
        true
      ]
    case 'Table':
      if (
        has(schema[key], ['translatedRowLabels', [intl.locale]]) &&
        intl.locale !== 'en'
      ) {
        schema[key].rowLabels = schema[key].translatedRowLabels[intl.locale]
      }
      return [<TableView data={data} config={schema[key]} />]
    case 'Text':
    case 'Textarea':
      return [<div>{data}</div>]
    default:
      return [<div>No renderer for this gadget({type})! :_(</div>]
  }
}
