/* Copyright © 2016 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */

/* eslint react/no-danger: 0 */
import axios from 'axios'
import { isEmpty, map } from 'lodash'
import PropTypes from 'prop-types'
import React, { Component, Fragment } from 'react'
import DocumentTitle from 'react-document-title'

import AddendumView from '../../../client-common/components/addendum-view'
import TopLevelPanel from '../../../client-common/components/top-level-panel'
import { PublicCatalogType } from '../../../client-common/lib/types'
import styles from './style'
import { injectIntl, defineMessages, FormattedMessage } from 'react-intl'
import { loadFeatureFlags } from '../../../common/lib/feature-flags'

import {
  maybeRenderTranslationButton,
  shouldRenderTranslation
} from '../../../client-common/lib/translation/translation'

const messages = defineMessages({
  changesSincePublicationTitle: {
    id: 'changes-since-publication-title',
    defaultMessage: 'Changes Since {title} Publication'
  }
})

class Policy extends Component {
  static displayName = 'Policy'

  static propTypes = {
    catalog: PublicCatalogType,
    params: PropTypes.shape({
      pid: PropTypes.string
    })
  }

  state = {
    addenda: [],
    policy: {}
  }

  async loadFeatureFlags () {
    const featureFlags = await loadFeatureFlags()
    this.setState({ featureFlags })
  }

  async componentWillReceiveProps (nextProps) {
    await this.fetchPolicy(nextProps)
  }

  async componentDidMount () {
    await this.fetchPolicy(this.props)
    await this.loadFeatureFlags()
  }

  async fetchPolicy (props = this.props) {
    const { catalog } = props
    const { pid } = props.params
    try {
      const { data } = await axios.get(`/policy/${catalog._id}/${pid}`)
      await this.fetchAddenda(props)
      this.setState({ policy: data })
    } catch (e) {
      if (e.message.indexOf('404') !== -1) window.location = '#/404'
    }
  }

  shouldRenderTranslationButtonForPolicy (policy, isPrint) {
    return policy && policy.bodytranslation && !isPrint
  }

  async fetchAddenda (props) {
    const {
      catalog,
      params: { pid }
    } = props
    const { data: addenda } = await axios.get(
      `/addenda/policies/${catalog._id}/${pid}`
    )
    this.setState({ addenda })
  }

  render () {
    const { catalog, schemas } = this.props
    const { settings, title } = catalog
    const { isPrint } = this.context

    const { addenda, featureFlags, policy } = this.state

    let shouldShowTranslations = shouldRenderTranslation(catalog, featureFlags)
    let shouldRenderTranslationButton = this.shouldRenderTranslationButtonForPolicy(
      policy,
      isPrint
    )

    return (
      <Fragment>
        <TopLevelPanel>
          {policy.title && <DocumentTitle title={policy.title} />}
          {renderPolicy(
            policy,
            catalog,
            shouldShowTranslations,
            shouldRenderTranslationButton,
            featureFlags
          )}
        </TopLevelPanel>
        {!isEmpty(addenda) && (
          <TopLevelPanel isMain={false}>
            <h3 className={styles.addendaHeading}>
              <FormattedMessage
                {...messages.changesSincePublicationTitle}
                values={{ title: title }}
              />
            </h3>
            {map(addenda, addendum => (
              <AddendumView
                key={addendum.id}
                addendum={addendum}
                schemas={schemas}
                settings={settings}
              />
            ))}
          </TopLevelPanel>
        )}
      </Fragment>
    )
  }
}

// we do this for testing purposes
const fetchPolicy = Policy.prototype.fetchPolicy

export { fetchPolicy }

export default injectIntl(Policy)

function maybeRenderTitleOrTranslationButton (
  titleText,
  catalog,
  shouldRenderTranslationButton,
  featureFlags
) {
  return (
    <div className={styles.itemTitleAndTranslationButton}>
      <div>
        <h2>{titleText}</h2>
      </div>

      <div className={styles.translationButtonContainer}>
        {maybeRenderTranslationButton(
          catalog,
          shouldRenderTranslationButton,
          featureFlags
        )}
      </div>
    </div>
  )
}

export function renderPolicy (
  policy,
  catalog,
  shouldShowTranslations,
  shouldRenderTranslationButton,
  featureFlags,
  showTitle = true
) {
  let bodyText = policy.ckBodyPublished || policy.body
  let titleText = policy.title

  if (shouldShowTranslations) {
    bodyText = policy.bodytranslation
    titleText = policy.titletranslation
  }

  return (
    <div className={styles.wrapper}>
      {policy && (
        <div>
          {showTitle &&
            maybeRenderTitleOrTranslationButton(
              titleText,
              catalog,
              shouldRenderTranslationButton,
              featureFlags
            )}
          <div
            className={styles.policyContainer}
            dangerouslySetInnerHTML={{
              __html: bodyText
            }}
          />
        </div>
      )}
    </div>
  )
}
