/* Copyright © 2016 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import React, { Fragment, useEffect, useState } from 'react'
import { debounce, get, keys, pickBy, size, some } from 'lodash'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router'
import { defineMessages, injectIntl } from 'react-intl'
import qs from 'querystring'
import axios from 'axios'
import { PageNavigation } from '@kuali/wok-ui/lib/page-navigation'
import { TextField } from '@kuali/wok-ui/lib/text-field'
import { SearchIcon } from '@kuali/wok-ui/lib/icons/search'

import CircularProgress from '../../../../client-common/components/circular-progress'
import styles from './style.css'
import { filterableItemTypes } from '../../../../common/lib/filterable-item-types'
import SearchResult from './search-result'
import ItemTypes from './item-types'
import FilterButton from './filter-button'
import MobileFilterDrawer from './mobile-filter-drawer'
import HomeLink from './home-link'

const DEFAULT_PAGE_SIZE = 20

/* This debounced function must be cancellable so it is defined outside
   the functional component. Otherwise it would be recreated each render
   and the old function would not be cancelled */
export const setSearchQuery = debounce((fetchSearchResults, newValue) => {
  fetchSearchResults(newValue)
}, 1000)

export const orderItemTypes = itemTypes => {
  const order = [
    'courses',
    'programs',
    'specializations',
    'experiences',
    'policies',
    'institutional-information'
  ]
  const ordered = []
  order.forEach(itemType => {
    if (itemTypes.includes(itemType)) ordered.push(itemType)
  })

  return ordered
}

export function Search ({ catalog, intl, location, router, windowWidth }) {
  const messages = defineMessages({
    numberOfResults: {
      id: 'search.number-of-results',
      defaultMessage: '{totalCount} Search Results'
    }
  })

  const limitFromUrl = parseInt(
    get(location, ['query', 'limit'], DEFAULT_PAGE_SIZE)
  )
  const skip = parseInt(get(location, ['query', 'skip'], 0))
  const searchTerm = get(location, ['query', 'q'], '')
  const itemTypes = orderItemTypes(filterableItemTypes(catalog.settings))

  const [filters, setFilters] = useState({})
  const setFilter = (itemType, value) => {
    setFilters({
      ...filters,
      [itemType]: value
    })
    setPageNumber(1)
  }

  const [limit] = useState(limitFromUrl)
  const [searchBuffer, setSearchBuffer] = useState(searchTerm)
  const [loading, setLoading] = useState(true)
  const [pageNumber, setPageNumber] = useState(
    Math.floor(skip / limitFromUrl) + 1
  )
  const [results, setResults] = useState([])
  const [totalCount, setTotalCount] = useState(0)
  const [showMobileFilters, setShowMobileFilters] = useState(false)

  async function fetchSearchResults (newSearchTerm) {
    const queryString = qs.stringify({
      q: newSearchTerm || searchTerm,
      itemTypes: keys(pickBy(filters)),
      limit,
      skip: (pageNumber - 1) * limit
    })

    router.replace(`/search?${queryString}`)

    if (size(searchTerm) === 0) {
      setLoading(false)
      setResults([])
      setTotalCount(0)

      return
    }

    setLoading(true)

    const { _id: catalogId } = catalog

    const response = await axios.get(`/search/${catalogId}?${queryString}`)

    setLoading(false)
    setResults(response.data)
    setTotalCount(get(response, ['headers', 'item-count'], 0))
  }

  useEffect(() => {
    fetchSearchResults()
  }, [filters, pageNumber, searchTerm])

  function onBackClicked () {
    router.goBack()
  }

  return [
    <div className={styles.topSection}>
      {windowWidth < 763 && (
        <Fragment>
          <MobileFilterDrawer
            filters={filters}
            intl={intl}
            itemTypes={itemTypes}
            onVisibilityChange={shouldShow => setShowMobileFilters(shouldShow)}
            setFilter={setFilter}
            visible={showMobileFilters}
          />

          <i className={`md-icon ${styles.backButton}`} onClick={onBackClicked}>
            keyboard_arrow_left
          </i>
          <span style={{ flex: 1, marginLeft: 15 }}>
            <TextField
              autoFocus
              display='block'
              onChange={newValue => {
                setSearchBuffer(newValue)
                setSearchQuery.cancel()
                setSearchQuery(fetchSearchResults, newValue)
              }}
              renderLeft={
                <SearchIcon
                  size='medium'
                  style={{ margin: '8px 0 0 6px' }}
                  ui='light'
                />
              }
              style={{ fontSize: 16, paddingLeft: 25, width: '100%' }}
              type='search'
              value={searchBuffer}
            />
          </span>
        </Fragment>
      )}
    </div>,
    <div className={styles.middleSection}>
      {windowWidth >= 763 && (
        <div className={styles.sidebar}>
          <div style={{ marginBottom: '28px' }}>
            <HomeLink intl={intl} />
          </div>
          <span className={styles.itemTypeFilters}>
            <ItemTypes
              filters={filters}
              intl={intl}
              itemTypes={itemTypes}
              setFilter={setFilter}
            />
          </span>
        </div>
      )}
      <div className={styles.searchResults}>
        {searchTerm && (
          <div className={styles.searchHeader}>
            <span className={styles.searchCount}>
              {intl.formatMessage(messages.numberOfResults, { totalCount })}
            </span>
            {windowWidth < 763 && (
              <FilterButton
                active={some(filters)}
                onClick={() => {
                  setShowMobileFilters(!showMobileFilters)
                }}
              />
            )}
          </div>
        )}
        {!loading && results ? (
          <div data-testid='searchResult'>
            {results.map(result => (
              <SearchResult
                key={get(result, 'pid')}
                searchTerm={searchTerm}
                item={result}
                intl={intl}
              />
            ))}
          </div>
        ) : (
          <CircularProgress />
        )}
        {totalCount > limit && (
          <span className={styles.pageNavigation}>
            <PageNavigation
              current={pageNumber}
              numPages={Math.ceil(totalCount / limit)}
              onPageLinkClicked={setPageNumber}
            />
          </span>
        )}
      </div>
    </div>
  ]
}

Search.displayName = 'Search'

Search.propTypes = {
  catalog: PropTypes.shape({
    _id: PropTypes.string.isRequired
  }).isRequired,
  intl: PropTypes.object,
  location: PropTypes.shape({
    query: PropTypes.shape({
      limit: PropTypes.string,
      q: PropTypes.string,
      skip: PropTypes.string
    }).isRequired
  }).isRequired,
  router: PropTypes.shape({ push: PropTypes.func.isRequired }).isRequired,
  windowWidth: PropTypes.number
}

Search.defaultProps = {
  windowWidth: 1080
}

export default withRouter(injectIntl(Search))
