/* Copyright © 2016 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co...
 */

import axios from 'axios'
import { get, isEmpty } from 'lodash'

import { isSuperAdmin } from '../../common/lib/users-utils'

let featureFlagsCache

export const loadFeatureFlags = async user => {
  try {
    const { data: flags } = await axios.get(
      `/featureflags?includeSuperAdminFlags=${user ? isSuperAdmin(user) : true}`
    )
    if (!featureFlagsCache) featureFlagsCache = flags
    return flags
  } catch (e) {
    return {}
  }
}

export const checkFeatureFlag = flag =>
  !isEmpty(featureFlagsCache) && get(featureFlagsCache, flag, false)

export const setCache = newFlags => (featureFlagsCache = newFlags)
