/* Copyright © 2016 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */

/* eslint consistent-return: 0 */
import cx from 'classnames'
import { produce } from 'immer'
import {
  capitalize,
  each,
  find,
  fromPairs,
  get,
  groupBy,
  isEmpty,
  toLower,
  map,
  remove,
  sortBy,
  size,
  trim,
  uniq
} from 'lodash'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import DocumentTitle from 'react-document-title'
import { scroller } from 'react-scroll'
import qs from 'querystring'

import styles from './style'
import CircularProgress from '../../../../client-common/components/circular-progress'
import CollapsibleBox from '../../../../common/collapsible-box'
import Search from '../../../../client-common/components/search'
import SelectField from '../../../../client-common/components/select-field'
import TopLevelPanel from '../../../../client-common/components/top-level-panel'
import { page } from '../../../../client-common/lib/analytics'
import { getCachedEndpoint } from '../../../../client-common/lib/cacher'
import { PublicCatalogType } from '../../../../client-common/lib/types'
import replace from '../../../../client-common/lib/ui-text-replace'
import { injectIntl, defineMessages, FormattedMessage } from 'react-intl'
import { printIfNeeded } from '../printing'
import MultiselectChip from '../../../../client-admin/app/components/chip/index'
import {
  filterItems,
  getFilterLabel,
  setFilterOptions
} from '../../../../client-common/lib/filters'
import ItemContents from '../extensions/index'

const messages = defineMessages({
  program: {
    id: 'programs.program',
    defaultMessage: 'program'
  },
  programsList: {
    id: 'programs.programs-list',
    defaultMessage: '{programs} List'
  },
  viewPrograms: {
    id: 'programs.view-programs',
    defaultMessage: 'View {programs}'
  },
  loadingPrintView: {
    id: 'programs.loading-print-view',
    defaultMessage: 'Loading Print View'
  },
  noProgramResults: {
    id: 'programs.no-program-results',
    defaultMessage: 'No {program} results'
  },
  searchForProgram: {
    id: 'programs.search-for-program',
    defaultMessage: 'Search for {program}'
  },
  search: {
    id: 'programs.search',
    defaultMessage: 'Search'
  },
  none: {
    id: 'programs.none',
    defaultMessage: 'None'
  },
  filterByLabel: {
    id: 'programs.filter-by-label',
    defaultMessage: 'Filter by {label}'
  },
  programSpecializations: {
    id: 'programs.program-specializations',
    defaultMessage: '{program} specializations'
  },
  loadingPrograms: {
    id: 'programs.loading-programs',
    defaultMessage: 'Loading {programs}'
  },
  title: {
    id: 'programs.title',
    defaultMessage: 'Title'
  },
  resultsFound: {
    id: 'programs.results-found',
    defaultMessage: 'results found'
  },
  viewProgram: {
    id: 'programs.view-program',
    defaultMessage: 'View Program'
  },
  programs: {
    id: 'programs.programs',
    defaultMessage: 'Programs'
  }
})

class ProgramList extends Component {
  static displayName = 'ProgramList'

  static propTypes = {
    catalog: PublicCatalogType,
    mq: PropTypes.string,
    params: PropTypes.shape({
      categoryId: PropTypes.string.optional
    })
  }

  static contextTypes = {
    isPrint: PropTypes.bool
  }

  constructor (props) {
    super(props)
    this.state = {
      didPrint: false,
      filterValuesMap: {},
      latestSearchTerm: '',
      loading: true,
      programs: null,
      selectedFilterOptions: []
    }
    page('Programs')
    this.fetchPrograms('')
    const { href } = window.location
    const { group } = qs.parse(href.split('?')[1])
    if (group) {
      this.state.singleGroup = group
    }
    this.printIfNeeded = printIfNeeded.bind(this)
  }

  componentDidUpdate (prevProps) {
    const { singleGroup } = this.state
    const { href } = window.location
    const { group } = qs.parse(href.split('?')[1])
    if (group !== singleGroup) {
      this.setState({ singleGroup: group })
    }

    this.printIfNeeded(this.state.programs)
    if (get(prevProps, 'params.categoryId') !== get(this.props, 'params.categoryId')) {
      this.fetchPrograms(this.state.latestSearchTerm || '')
    }
  }

  componentDidMount () {
    this._isMounted = true
  }

  componentWillUnmount () {
    this._isMounted = false
  }

  getCombinedFilterSettings (catalog) {
    const legacyFilter = get(catalog, 'settings.programs.filter')
    const filters = get(catalog, 'settings.programs.filters', [])
    if (legacyFilter && legacyFilter.trim()) {
      return uniq([legacyFilter, ...filters])
    }
    return filters
  }

  async fetchPrograms (searchTerm) {
    this.setState({ latestSearchTerm: searchTerm, loading: true })

    const { catalog } = this.props
    const queryObj = { q: searchTerm }
    const categoryId = get(this.props, 'params.categoryId')
    if (categoryId && categoryId !== 'all') {
      queryObj.categoryId = categoryId
    }
    const query = qs.stringify(queryObj)

    const programEndpoint = `/programs/${catalog._id}?${query}`
    const programs = await getCachedEndpoint(programEndpoint)

    if (this.state.latestSearchTerm !== searchTerm) {
      return
    }

    const category = get(catalog, 'settings.programs.category')

    const filteredPrograms = this.filterPrograms(programs)
    const groups =
      isEmpty(searchTerm) && category && category.trim()
        ? this.groupByAndSort(filteredPrograms)
        : {}
    const { expanded } = qs.parse(window.location.href.split('?')[1])
    if (this._isMounted) {
      this.setState(
        {
          loading: false,
          filteredPrograms,
          programs,
          groups,
          searchTerm,
          expanded
        },
        () => this.getFilterValues(programs)
      )
      if (expanded) {
        scroller.scrollTo(expanded, {
          duration: 500,
          delay: 250,
          smooth: true
        })
      }
    }
  }

  async getFilterValues (programs) {
    const { catalog, schemas, intl } = this.props
    const programFilters = this.getCombinedFilterSettings(catalog)
    if (programFilters.length < 1) return

    const { filterValuesMap, filterLabelsMap } = produce(
      this.state,
      draftState => {
        setFilterOptions(
          draftState,
          intl,
          programs,
          programFilters,
          schemas.programs
        )
      }
    )
    this.setState({ filterValuesMap, filterLabelsMap, loading: false })
  }

  groupByAndSort = items => {
    const {
      catalog: { settings },
      intl
    } = this.props
    const category = get(settings, 'programs.category')

    if (!category || !category.trim()) return {}

    let groups
    if (intl.locale === 'es') {
      groups = groupBy(items, `${category}.customFields.${'Spanish Name'}`)
    } else {
      groups = groupBy(items, `${category}.name`)
    }
    each(groups, (group, id) => {
      group = group.sort((a, b) =>
        toLower(a.title) > toLower(b.title) ? 1 : -1
      )
    })
    const keys = Object.keys(groups)
    const sortedKeys = sortBy(keys)

    return fromPairs(map(sortedKeys, key => [key, groups[key]]))
  }

  setFilter = (filter, newFilterValue, label) => {
    const { programs } = this.state
    const filterValue = newFilterValue === '' ? null : newFilterValue
    const selectedFilterOptions = produce(
      get(this.state, 'selectedFilterOptions'),
      selectedFilterOptionsDraft => {
        if (!find(selectedFilterOptionsDraft, { filter, value: filterValue })) {
          selectedFilterOptionsDraft.push({ filter, label, value: filterValue })
        }
      }
    )

    this.setState({ selectedFilterOptions }, () => {
      const filteredPrograms = this.filterPrograms(programs)
      const groups = this.groupByAndSort(filteredPrograms)
      this.setState({ filteredPrograms, groups })
    })
  }

  filterPrograms = programs => {
    const { schemas } = this.props
    const { selectedFilterOptions } = this.state
    return filterItems(selectedFilterOptions, programs, schemas.programs)
  }

  renderGroupsView (programTitleExtension) {
    const { catalog, schemas } = this.props
    const { groups, selectedFilterOptions, singleGroup } = this.state

    if (singleGroup) {
      return (
        <CollapsibleBox
          key={singleGroup}
          title={singleGroup}
          titleComponent='h2'
        >
          <ul className={styles.withDivider}>
            {map(groups[singleGroup], (p, i, { length }) => (
              <li className={styles.item} key={i}>
                <ItemContents
                  catalogSettings={get(catalog, 'settings')}
                  extension={p[programTitleExtension]}
                  group={singleGroup}
                  item={p}
                  itemType='programs'
                  pid={p.pid}
                  schemas={schemas}
                  title={p.title}
                />
                <ul>
                  {map(p.specializations, (s, i) => (
                    <li className={styles.item} key={i}>
                      <ItemContents
                        catalogSettings={get(catalog, 'settings')}
                        extension={s[programTitleExtension]}
                        group={singleGroup}
                        indented
                        item={s}
                        itemType='programs'
                        pid={p.pid}
                        schemas={schemas}
                        title={s.title}
                      />
                    </li>
                  ))}
                </ul>
              </li>
            ))}
          </ul>
        </CollapsibleBox>
      )
    } else {
      return (
        <ul>
          {map(groups, (programGroup, groupTitle) => {
            if (groupTitle === 'undefined') return
            return (
              <li key={groupTitle}>
                <CollapsibleBox
                  expandable
                  id={groupTitle}
                  key={groupTitle}
                  link={
                    window.location.href.split('?')[0] +
                    `?${qs.stringify({ group: groupTitle })}`
                  }
                  startExpanded={
                    this.state.expanded === groupTitle ||
                    selectedFilterOptions.length > 0
                  }
                  title={groupTitle}
                  titleComponent='h2'
                >
                  <ul className={cx(styles.withDivider, styles.indented)}>
                    {map(programGroup, (p, i, { length }) => (
                      <li className={styles.item} key={i}>
                        <ItemContents
                          catalogSettings={get(catalog, 'settings')}
                          extension={p[programTitleExtension]}
                          group={groupTitle}
                          item={p}
                          itemType='programs'
                          pid={p.pid}
                          schemas={schemas}
                          title={p.title}
                        />
                        {p.specializations && (
                          <div
                            className={styles.specializationsList}
                            tabIndex={0}
                            aria-label={`${p.title} ${replace(
                              'specializations'
                            )}`}
                          >
                            <ul>
                              {map(p.specializations, (s, i) => (
                                <li className={styles.item} key={i}>
                                  <ItemContents
                                    catalogSettings={get(catalog, 'settings')}
                                    extension={s[programTitleExtension]}
                                    group={singleGroup}
                                    indented
                                    item={s}
                                    itemType='programs'
                                    pid={p.pid}
                                    schemas={schemas}
                                    title={s.title}
                                  />
                                </li>
                              ))}
                            </ul>
                          </div>
                        )}
                      </li>
                    ))}
                  </ul>
                </CollapsibleBox>
              </li>
            )
          })}
        </ul>
      )
    }
  }

  renderSearchHeader (category, extension) {
    const {
      schemas: { programs: programSchema },
      intl
    } = this.props
    const hasExtension = !isEmpty(trim(extension))

    if (!category && !hasExtension) {
      return (
        <div className={styles.resultHeader}>
          <FormattedMessage {...messages.title} />
        </div>
      )
    }

    return (
      <div className={`${styles.columns} ${styles.resultHeader}`}>
        <span>
          <FormattedMessage {...messages.title} />
        </span>
        {hasExtension && (
          <span>
            {intl.locale !== 'en'
              ? get(
                programSchema[extension],
                ['translatedLabels', intl.locale],
                programSchema[extension].label
              )
              : `${programSchema[extension].label}`}
          </span>
        )}
        {category && (
          <span>
            {intl.locale !== 'en'
              ? get(
                programSchema[category],
                ['translatedLabels', intl.locale],
                programSchema[category].label
              )
              : `${programSchema[category].label}`}
          </span>
        )}
      </div>
    )
  }

  renderSearchView (
    filteredPrograms,
    programCategory,
    catalog,
    programTitleExtension
  ) {
    const { intl, schemas } = this.props
    const category =
      programCategory && programCategory !== ' ' ? programCategory : null
    return (
      <div>
        <span role='alert'>
          <b>{`${filteredPrograms.length}`}</b>{' '}
          <FormattedMessage {...messages.resultsFound} />
        </span>
        {this.renderSearchHeader(category, programTitleExtension)}
        <ul className={cx(styles.withDivider, styles.indented)}>
          {map(filteredPrograms, (p, i, { length }) => (
            <li className={styles.item} key={i}>
              <ItemContents
                catalogSettings={get(catalog, 'settings')}
                extension={p[programTitleExtension]}
                item={p}
                itemType='programs'
                pid={p.pid}
                schemas={schemas}
                title={p.title}
              >
                {intl.locale === 'es' && category && (
                  <div className={styles.extension}>
                    {get(p, `${category}.customFields.${'Spanish Name'}`)}
                  </div>
                )}
                {intl.locale === 'en' && category && (
                  <div className={styles.extension}>
                    {get(p, `${category}.name`)}
                  </div>
                )}
              </ItemContents>
              {p.specializations && (
                <div
                  className={styles.specializationsList}
                  tabIndex={0}
                  aria-label={`${p.title} ${replace('specializations')}`}
                >
                  <ul>
                    {map(p.specializations, (s, i) => (
                      <li className={styles.item} key={i}>
                        <ItemContents
                          catalogSettings={get(catalog, 'settings')}
                          extension={s[programTitleExtension]}
                          indented
                          item={s}
                          itemType='programs'
                          pid={p.pid}
                          schemas={schemas}
                          title={s.title}
                        >
                          {category && (
                            <span>{/* placeholder for column */}</span>
                          )}
                        </ItemContents>
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </li>
          ))}
        </ul>
      </div>
    )
  }

  renderUngroupedView (filteredPrograms, programTitleExtension) {
    const { catalog, schemas } = this.props
    return (
      <ul className={styles.withDivider}>
        {map(filteredPrograms, (p, i, { length }) => (
          <li className={styles.item} key={i}>
            <ItemContents
              catalogSettings={get(catalog, 'settings')}
              extension={p[programTitleExtension]}
              item={p}
              itemType='programs'
              pid={p.pid}
              schemas={schemas}
              title={p.title}
            />
            {map(p.specializations, (s, i) => (
              <div className={styles.item} key={i}>
                <ItemContents
                  catalogSettings={get(catalog, 'settings')}
                  extension={s[programTitleExtension]}
                  indented
                  item={s}
                  itemType='programs'
                  pid={p.pid}
                  schemas={schemas}
                  title={s.title}
                />
              </div>
            ))}
          </li>
        ))}
      </ul>
    )
  }

  handleChipRemove (filterKey, filterValue) {
    const { programs } = this.state
    const selectedFilterOptions = produce(
      this.state.selectedFilterOptions,
      selectedFilterOptionsDraft => {
        selectedFilterOptionsDraft = remove(
          selectedFilterOptionsDraft,
          ({ value, filter }) => value === filterValue && filter === filterKey
        )
      }
    )
    this.setState({ selectedFilterOptions }, () => {
      const filteredPrograms = this.filterPrograms(programs)
      const groups = this.groupByAndSort(filteredPrograms)
      this.setState({ filteredPrograms, groups })
    })
  }

  renderFilterChips () {
    const { selectedFilterOptions } = this.state

    const filterChips = []
    if (!isEmpty(selectedFilterOptions)) {
      selectedFilterOptions.forEach(({ filter, label, value }) =>
        filterChips.push(
          <MultiselectChip
            key={value}
            label={label}
            onClick={() => {
              this.handleChipRemove(filter, value)
            }}
          />
        )
      )
      return <div className={styles.filterChipsWrapper}>{filterChips}</div>
    }
    return null
  }

  hasCurFilter () {
    const { curFiltersMap } = this.state
    for (const filter in curFiltersMap) {
      if (curFiltersMap[filter].length > 0) {
        return true
      }
    }
    return false
  }

  render () {
    const { isPrint } = this.context
    const {
      filteredPrograms,
      filterValuesMap,
      groups,
      loading,
      searchTerm,
      singleGroup
    } = this.state
    const { catalog, intl, mq, schemas } = this.props
    const {
      programs: {
        category: programCategory,
        titleExtension: programTitleExtension
      }
    } = catalog.settings
    const programFilters = this.getCombinedFilterSettings(catalog)

    return (
      <div className={styles.wrapper}>
        <DocumentTitle
          title={intl.formatMessage(messages.viewPrograms, {
            programs: capitalize(replace('Programs', false, intl.locale))
          })}
        />

        <h1 className='hidden'>
          <FormattedMessage
            {...messages.programsList}
            values={{ programs: replace('Programs', false, intl.locale) }}
          />
        </h1>

        {!singleGroup && (
          <div className={styles.twoColumns}>
            <div className={size(programFilters) > 1 ? styles.left1 : styles.left}>
              <Search
                onChange={this.fetchPrograms.bind(this)}
                hintText={
                  mq !== 'small'
                    ? intl.formatMessage(messages.searchForProgram, {
                      program: replace('program', false, intl.locale)
                    })
                    : intl.formatMessage(messages.search)
                }
              />
            </div>
            <div className={styles.right}>
              <div className={styles.filters}>
                {map(programFilters, filter => {
                  const filterLabel = getFilterLabel(
                    filter,
                    intl,
                    schemas.programs
                  )
                  const filterValues = filterValuesMap[filter] || []
                  return (
                    <SelectField
                      className={styles.selectFilter}
                      fullWidth
                      floatingLabelText={filterLabel}
                      id={filterLabel}
                      key={filterLabel}
                      menuItems={[...filterValues]}
                      name={filterLabel}
                      onChange={(val, i) => {
                        const label = filterValues[i].label
                        this.setFilter(filter, val, label)
                      }}
                      style={{ float: 'right' }}
                      value={''}
                    />
                  )
                })}
              </div>
            </div>
          </div>
        )}
        {this.renderFilterChips()}
        <TopLevelPanel>
          {!loading && !!filteredPrograms.length && (
            <div className={styles.itemContainer}>
              <h2 className={styles.hidden}>
                <FormattedMessage {...messages.programs} />
              </h2>
              {!isEmpty(groups) &&
                !searchTerm &&
                this.renderGroupsView(programTitleExtension)}
              {isEmpty(groups) &&
                !searchTerm &&
                this.renderUngroupedView(
                  filteredPrograms,
                  programTitleExtension
                )}
              {searchTerm &&
                filteredPrograms.length &&
                this.renderSearchView(
                  filteredPrograms,
                  programCategory,
                  catalog,
                  programTitleExtension
                )}
            </div>
          )}
          {loading && (
            <div className={styles.progressWrapper}>
              <div className='hidden' role='alert'>
                <FormattedMessage
                  {...messages.loadingPrograms}
                  values={{ programs: replace('Programs', false, intl.locale) }}
                />
              </div>
              {isPrint && intl.formatMessage(messages.loadingPrintView)}
              <CircularProgress />
            </div>
          )}
          {!loading && !filteredPrograms.length && searchTerm && (
            <div role='alert'>
              <FormattedMessage
                {...messages.noProgramResults}
                values={{ program: replace('program', false, intl.locale) }}
              />
            </div>
          )}
        </TopLevelPanel>
      </div>
    )
  }
}

export default injectIntl(ProgramList)
