/* Copyright © 2016 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */

import axios from 'axios'
import { detect } from 'detect-browser'
import { compact, debounce, find, get, map, mapValues, size } from 'lodash'
import PropTypes from 'prop-types'
import qs from 'querystring'
import React, { Component, Fragment } from 'react'
import { Link, routerShape } from 'react-router'
import WebFont from 'webfontloader'
import Promise from 'bluebird'
import { SearchIcon } from '@kuali/wok-ui/lib/icons/search'

import styles from './style'
import AppBar from '../../../../client-common/components/app-bar'
import { isColorDark } from '../../../../common/lib/color-detector'
import Drawer from '../../../../client-common/components/drawer'
import NavBar from '../../../../client-common/components/nav-bar'
import PrintButton from '../../../../client-common/components/print-button'
import SelectField, {
  Positions
} from '../../../../client-common/components/select-field'
import replace from '../../../../client-common/lib/ui-text-replace'
import { defineMessages, injectIntl, FormattedMessage } from 'react-intl'
import NavListItem from '../../../../client-common/components/nav-bar/nav-list-item/index'
import webSafeFonts from '../../../../common/lib/websafe-fonts'
import { Autocomplete } from './autocomplete'
import { itemPath } from '../search/search-result'

export function choosePaginationColor (linkColor, primaryColor) {
  if (isColorDark(linkColor)) {
    return linkColor
  }

  if (isColorDark(primaryColor)) {
    return primaryColor
  }

  return 'var(--primary)'
}

class Main extends Component {
  static displayName = 'Main'

  static propTypes = {
    children: PropTypes.node,
    handleLanguageChange: PropTypes.func,
    intl: PropTypes.object,
    location: PropTypes.shape({ pathname: PropTypes.string }),
    mq: PropTypes.string,
    windowWidth: PropTypes.number
  }

  static defaultProps = {
    windowWidth: 1080
  }

  static contextTypes = {
    isPrint: PropTypes.bool,
    router: routerShape,
    theme: PropTypes.object
  }

  constructor (props, context) {
    super(props, context)

    this.messages = defineMessages({
      academicCatalog: {
        id: 'main.academic-catalog',
        defaultMessage: get(props, 'featureFlags.dontPrependAcademic.value')
          ? replace('catalog', true)
          : `Academic ${replace('catalog', true)}`
      },
      archivedCatalogs: {
        id: 'main.archived-catalogs',
        defaultMessage: `Archived ${replace('catalog', true)}s`
      },
      catalog: {
        id: 'main.catalog',
        defaultMessage: replace('catalog', true)
      },
      printThisPage: {
        id: 'print-button.print-this-page',
        defaultMessage: 'Print this page'
      },
      searchCatalog: {
        id: 'main.search-catalog',
        defaultMessage: `Search ${replace('catalog', true)}`
      },
      searching: {
        id: 'main.searching',
        defaultMessage: 'Searching'
      },
      seeAllResults: {
        id: 'main.see-all-results',
        defaultMessage: `See all results for '{query}'`
      },
      selectCatalog: {
        id: 'main.select-catalog',
        defaultMessage: `Select ${replace('catalog', true)}`
      }
    })

    this.state = {
      globalSearchOptions: [],
      globalSearchValue: '',
      openDrawer: false,
      viewableCatalogs: [],
      currentPath: window.location.hash.substr(1),
      routeListenerRegistered: false
    }
    this.fetchData()
    // IE compatability
    const browserInfo = detect()
    if (get(browserInfo, 'name') === 'ie') {
      WebFont.load({ google: { families: ['Material Icons'] } })
    }

    const fontFamily = get(context, ['theme', 'fontFamily'], 'Roboto')
    if (!webSafeFonts.includes(fontFamily)) {
      WebFont.load({ google: { families: [fontFamily] } })
      WebFont.load({ google: { families: [`${fontFamily}:bold`] } })
    }

    const { router } = context
    if (!router) {
      return
    }
    router.listen(route => {
      if (this.state.currentPath !== route.pathname) {
        this.setState({ currentPath: route.pathname })
      }
    })
  }

  isCurrentRoute = path => {
    // exact match
    if (this.state.currentPath.toLowerCase() === path.toLowerCase()) return true

    // fuzzy match based on specific item types
    const pathMap = {
      '/policies': '/policy/',
      '/courses': '/courses/',
      '/programs': '/programs/',
      '/specializations': '/specializations/',
      '/experiences': '/experiences/'
    }

    if (pathMap[path]) {
      return this.state.currentPath.toLowerCase().startsWith(pathMap[path])
    }
    if (path.startsWith('/sets/') && this.state.currentPath.startsWith(path)) {
      return true
    }
  }

  async fetchData () {
    if (window.catalogId) {
      this.setState({ viewableCatalogs: [] })
      this.switchCatalog(window.catalogId, false)
    } else {
      const { data: viewableCatalogs } = await axios.get('/public/catalogs')
      this.setState({ viewableCatalogs })

      if (this.props.catalogId) {
        this.switchCatalog(this.props.catalogId, false)
      } else {
        const { data: currentCatalog } = await axios.get(
          '/public/catalogs/current'
        )
        this.switchCatalog(currentCatalog._id, false)
      }
    }
  }

  fetchSchemas = async ({ _id }) => {
    const schemas = await Promise.props({
      courses: axios.get(`/schema/${_id}/courses`),
      experiences: axios.get(`/schema/${_id}/experiences`),
      policies: axios.get(`/schema/${_id}/policies`),
      programs: axios.get(`/schema/${_id}/programs`),
      specializations: axios.get(`/schema/${_id}/specializations`)
    }).then(s => mapValues(s, 'data'))
    return schemas
  }

  handleCatalogChange () {
    const { catalog } = this.state
    let locale = 'en'
    if (get(catalog, 'settings.language')) {
      locale = get(catalog, 'settings.language')
    }
    this.props.handleLanguageChange(locale, catalog._id)
  }

  switchCatalog = async (value, goToHome) => {
    const { data: catalog } = await axios.get(`/public/catalogs/${value}`)
    const schemas = await this.fetchSchemas(catalog)
    this.setState({ catalog, schemas }, this.handleCatalogChange)
    if (goToHome) {
      this.context.router.push(
        `${get(catalog, 'settings.catalog.navigation[0].to', '/courses')}`
      )
    }
  }

  getThemeColors = () => {
    const theme = this.context.theme || {}
    const fontColor = theme.fontColor || '#111111'
    const linkColor = theme.linkColor || '#0066CC'
    const primaryColor = theme.primaryColor || '#E5F2F9'

    let altFontColor = fontColor
    let navLinkColor = linkColor
    if (isColorDark(primaryColor)) {
      altFontColor = '#FFFFFF'
      navLinkColor = '#FFFFFF'
    }

    return {
      altFontColor,
      fontColor,
      linkColor,
      navLinkColor,
      paginationColor: choosePaginationColor(linkColor, primaryColor),
      primaryColor
    }
  }

  getTheme = () => {
    const {
      altFontColor,
      fontColor,
      linkColor,
      navLinkColor,
      paginationColor,
      primaryColor
    } = this.getThemeColors()
    const theme = this.context.theme || {}
    const fontFamily = theme.fontFamily || 'Roboto'

    const style = {
      __html: `
        :root {
          --theme-alt-font-color: ${altFontColor};
          --theme-font-color: ${fontColor};
          --theme-link-color: ${linkColor};
          --theme-nav-link-color: ${navLinkColor};
          --theme-pagination-color: ${paginationColor};
          --theme-primary-color: ${primaryColor};
        }

        .__KUALI_THEMABLE__ {
          color: ${fontColor};
          font-family: ${fontFamily} !important;
        }

        #kuali-catalog a, #kuali-catalog a * {
          color: ${linkColor};
        }

        #kuali-catalog .md-background--primary, #kuali-catalog .md-divider--text-field:not(.md-divider--text-field-error)::after {
          background: ${primaryColor} !important;
          color: ${altFontColor}
        }

        #kuali-catalog .md-text {
          color: ${fontColor};
        }

        #kuali-catalog .md-btn--text {
          text-transform: capitalize;
        }

        #kuali-catalog .md-text--theme-primary, #kuali-catalog .md-floating-label--active {
          color: ${primaryColor};
        }

        #kuali-catalog .md-circular-progress-path {
          stroke: ${primaryColor};
        }

        #kuali-catalog .md-floating-label {
          color: rgba(0, 0, 0, 1.0)
        }

        #kuali-catalog .appBarText,
        #kuali-catalog .md-title--toolbar,
        #kuali-catalog .md-toolbar--action-left,
        #kuali-catalog .${
          styles.mobileCatalogSwitcher
        } .md-select-field.md-text--secondary,
        #kuali-catalog .${
          styles.mobileCatalogSwitcher
        } .md-select-field--text-field,
        #kuali-catalog .${styles.mobileCatalogSwitcher} .md-icon
        {
          color: ${altFontColor};
          background: ${primaryColor};
        }

        #kuali-catalog .selectedNavItem{
          background: ${primaryColor};
        }

        #kuali-catalog .selectedNavItem a{
          color: ${altFontColor};
        }

        #kuali-catalog .nestedNavItem{
          color: ${linkColor};
        }

        #kuali-catalog .selectedNestedItem{
          background: ${primaryColor};
          border-radius: 4px;
          color: ${navLinkColor};
        }

        #kuali-catalog .selectedNestedItem:hover{
         background: ${primaryColor};
         text-decoration: underline;
         color: ${navLinkColor};
        }

        #kuali-catalog .linkColor {
          color: ${linkColor}
        }

        #kuali-catalog .coursePopover h3 {
          color: ${primaryColor};
        }
      `
    }

    return style
  }

  onNavItemClick = (isExternal, route) => {
    const { router } = this.context
    const { translated } = window.location.href
      ? qs.parse(window.location.href.split('?')[1])
      : false
    let augmentedRoute = translated
      ? route + '?' + qs.stringify({ translated: true })
      : route
    isExternal
      ? window.open(route, '_blank')
      : setTimeout(() => {
        // Give document level events a chance to be handled (like search
        // autocomplete to close). This may not be needed with react-router > 3
        router.push(augmentedRoute)
      }, 0)
  }

  debouncedSearchChange = debounce(async (queryString, catalogId) => {
    const { intl } = this.props
    const { globalSearchValue } = this.state

    // Don't search if query is blank
    if (queryString.indexOf('q=&') === 0) {
      this.setState({
        globalSearchOptionsData: [],
        globalSearchOptions: []
      })
      return
    }

    const response = await axios.get(`/search/${catalogId}?${queryString}`)
    const options = map(response.data, item => {
      const itemCode = item.type === 'courses' ? item.code : undefined
      const optionTitle = compact([itemCode, item.title]).join(' ')
      return {
        label: (
          <Link className={styles.optionLink} to={itemPath(item)}>
            <div className={styles.option}>
              <span className={styles.optionTitle}>{optionTitle}</span>
              {item.type && (
                <span className={styles.optionItemType}>
                  {replace(item.type, true, intl.locale)}
                </span>
              )}
            </div>
          </Link>
        ),
        value: item.type === 'institutional-information' ? item.id : item.pid
      }
    })

    const availableResults = response.headers['item-count']
    if (parseInt(availableResults) > 6 && size(globalSearchValue) > 0) {
      options.push({
        label: (
          <div>
            <Link
              className={`${styles.optionLink} ${styles.allResults}`}
              to={`/search/${catalogId}?q=${globalSearchValue}`}
            >
              {intl.formatMessage(this.messages.seeAllResults, {
                query: globalSearchValue
              })}
            </Link>
          </div>
        ),
        value: '__seeAll'
      })
    }

    this.setState({
      globalSearchOptionsData: response.data,
      globalSearchOptions: options
    })
  }, 500)

  handleSearchEnterPressed = () => {
    const { globalSearchValue } = this.state
    if (!globalSearchValue) return
    const catalogId = get(this, 'state.catalog._id', '')
    this.context.router.push(`/search/${catalogId}?q=${globalSearchValue}`)
  }

  handleSearchOptionChosen = (optionValue, evtType) => {
    if (evtType === 'ENTER') {
      if (optionValue === '__seeAll') {
        const catalogId = get(this, 'state.catalog._id', '')
        this.context.router.push(
          `search/${catalogId}?q=${this.state.globalSearchValue}`
        )
        return true
      }

      const item = findItem(this.state.globalSearchOptionsData, optionValue)
      this.context.router.push(itemPath(item))
    }
    return true
  }

  handleSearchChange = query => {
    this.debouncedSearchChange(
      getSearchQueryString(query),
      get(this, 'state.catalog._id', '')
    )
    const pendingSearchOptions = [
      {
        value: 'pendingResults',
        label: (
          <span className={styles.optionLink} style={{ color: '#666' }}>
            {this.props.intl.formatMessage(this.messages.searching)}...
          </span>
        )
      }
    ]
    this.setState({
      globalSearchOptions: pendingSearchOptions,
      globalSearchValue: query
    })
  }

  closeDrawer = () => {
    this.setState({ openDrawer: false })
  }

  mobileAppBarActionItems = () => {
    const { router } = this.context
    const { viewableCatalogs } = this.state
    const { intl } = this.props
    const { altFontColor } = this.getThemeColors()
    const onSearchPage = get(router, 'location.pathname') === '/search'

    const appBarActionItems = []
    if (viewableCatalogs.length > 1) {
      appBarActionItems.push(
        <MobileCatalogSwitcher
          otherCatalogs={viewableCatalogs}
          placeholder={intl.formatMessage(this.messages.selectCatalog)}
          switchCatalog={this.switchCatalog}
        />
      )
    }
    if (!onSearchPage) {
      appBarActionItems.push(
        <a
          href='#/search'
          style={{
            appearance: 'none',
            backgroundColor: 'transparent',
            border: 0,
            cursor: 'pointer',
            display: 'inline-block',
            height: 35,
            margin: '12px 24px 0 10px'
          }}
        >
          <SearchIcon
            color={altFontColor}
            size='medium'
            style={{ verticalAlign: 'middle' }}
          />
        </a>
      )
    }
    return appBarActionItems
  }

  renderMobile () {
    const { children, intl } = this.props
    const { catalog, openDrawer, schemas } = this.state
    const archiveLink = get(catalog, 'settings.catalog.externalArchiveLink')

    const appBarActionItems = this.mobileAppBarActionItems()

    const navItems = map(
      get(catalog, 'settings.catalog.navigation', []),
      item => (
        <div className={styles.navItemWrapperMobile}>
          <NavListItem
            primaryText={replace(item.title, false, intl.locale)}
            route={item.to}
            isExternal={item.external}
            onClick={this.onNavItemClick}
            isCurrentRoute={this.isCurrentRoute}
            isMobile
            intl={intl}
            nestedItems={item.nestedItems}
            closeDrawer={this.closeDrawer}
          />
        </div>
      )
    )
    if (archiveLink) {
      navItems.push(
        <NavListItem
          primaryText={intl.formatMessage(this.messages.archivedCatalogs)}
          route={archiveLink}
          onClick={this.onNavItemClick}
          isCurrentRoute={this.isCurrentRoute}
          isExternal
          closeDrawer={this.closeDrawer}
        />
      )
    }
    return (
      <div key='main' className={styles.pageWrapperMobile}>
        <style dangerouslySetInnerHTML={this.getTheme()} />
        <div
          className={[
            !openDrawer ? styles.bringToFront : '',
            styles.sticky,
            styles.navWrapperMobile
          ].join(' ')}
        >
          <AppBar
            actions={<div>{appBarActionItems}</div>}
            className={'appBarText ' + styles.appBar}
            onLeftIconButtonTouchTap={() =>
              this.setState({ openDrawer: !openDrawer })
            }
            title={intl.formatMessage(this.messages.catalog)}
            titleClassName={styles.mobileTitle}
          />
        </div>
        <Drawer
          docked={false}
          navItems={navItems}
          open={openDrawer}
          onRequestChange={newState => this.setState({ openDrawer: newState })}
        />
        <main>{React.cloneElement(children, { catalog, schemas })}</main>
      </div>
    )
  }

  renderDesktop () {
    const { isPrint, router } = this.context
    const { children, intl } = this.props
    const {
      catalog,
      globalSearchOptions,
      globalSearchValue,
      schemas,
      viewableCatalogs
    } = this.state
    const catalogTitle = get(catalog, 'settings.catalog.title')
    const archiveLink = get(catalog, 'settings.catalog.externalArchiveLink')

    return (
      <div key='main' className={styles.pageWrapper}>
        <div className='hidden'>
          <div
            tabIndex={0}
            onKeyDown={e =>
              e.keyCode === 13 &&
              document.getElementById('kuali-catalog-main').focus()
            }
          >
            <FormattedMessage
              id='main.skip-to-main-content'
              defaultMessage='Skip to main content'
            />
          </div>
        </div>
        <style dangerouslySetInnerHTML={this.getTheme()} />
        <div className={styles.header}>
          <div className={styles.headerLeft} role='banner'>
            <h1
              className={styles.pageHeader}
              onClick={() => router.push('/home')}
            >
              {catalogTitle ||
                intl.formatMessage(this.messages.academicCatalog)}
            </h1>
          </div>
          {!isPrint && (
            <Fragment>
              <span>
                <div className={styles.autoCompleteContainer}>
                  <Autocomplete
                    menuStyle={{ width: 518, left: -126 }}
                    onEnterPressed={this.handleSearchEnterPressed}
                    onOptionChosen={this.handleSearchOptionChosen}
                    onSearchChange={this.handleSearchChange}
                    options={globalSearchOptions}
                    placeholder={intl.formatMessage(
                      this.messages.searchCatalog
                    )}
                    intl={intl}
                    value={globalSearchValue}
                  />
                </div>
              </span>
            </Fragment>
          )}
        </div>
        <div className={styles.body}>
          {get(router, 'location.pathname') !== '/search' && !isPrint && (
            <div className={`${styles.sticky} ${styles.navWrapper}`}>
              {viewableCatalogs.length > 1 && (
                <SelectField
                  className={styles.sideCatalogDropDown}
                  floatingLabelText={intl.formatMessage(
                    this.messages.selectCatalog
                  )}
                  id='Select Catalog'
                  inputClassName={styles.sideCatalogDropdownInput}
                  listClassName={styles.dropdown}
                  menuItems={map(viewableCatalogs, c => c.title)}
                  name='Select Catalog'
                  onChange={(_, i, __) =>
                    this.switchCatalog(
                      get(viewableCatalogs, `[${i}]._id`),
                      true
                    )
                  }
                  position={Positions.BELOW}
                  value={get(catalog, 'title')}
                />
              )}
              <NavBar
                archiveLink={archiveLink}
                items={get(catalog, 'settings.catalog.navigation', [])}
                onItemClick={this.onNavItemClick}
                isCurrentRoute={this.isCurrentRoute}
              />
              <div className={styles.sidePrintButton}>
                <PrintButton catalogId={catalog._id}>
                  {intl.formatMessage(this.messages.printThisPage)}
                </PrintButton>
              </div>
            </div>
          )}
          <main
            id='kuali-catalog-main'
            tabIndex={-1}
            className={styles.contentWrapper}
          >
            {React.cloneElement(children, { catalog, schemas })}
          </main>
        </div>
      </div>
    )
  }

  render () {
    const { windowWidth } = this.props
    const { catalog } = this.state
    if (!catalog) {
      return (
        <div key='main' className={styles.parent}>
          <style dangerouslySetInnerHTML={this.getTheme()} />
        </div>
      )
    }
    return windowWidth < 763 ? this.renderMobile() : this.renderDesktop()
  }
}

export default injectIntl(Main)

export function MobileCatalogSwitcher ({
  otherCatalogs,
  placeholder,
  switchCatalog
}) {
  return (
    <SelectField
      className={styles.mobileCatalogSwitcher}
      id='Switch Catalog'
      listClassName={styles.dropdown}
      menuItems={map(otherCatalogs, catalog => catalog.title)}
      name='Switch Catalog'
      onChange={(_, i, e) => {
        e.preventDefault()
        switchCatalog(otherCatalogs[i]._id, true)
      }}
      placeholder={placeholder}
      position={Positions.BOTTOM_RIGHT}
      toggleClassName={styles.mobileCatalogSwitcherToggle}
    />
  )
}

MobileCatalogSwitcher.displayName = 'MobileCatalogSwitcher'
MobileCatalogSwitcher.propTypes = {
  otherCatalogs: PropTypes.arrayOf(PropTypes.object),
  placeholder: PropTypes.string,
  switchCatalog: PropTypes.func
}

export function findItem (optionsData, optionValue) {
  return find(optionsData, option => option.pid === optionValue)
}

export function getSearchQueryString (query) {
  return qs.stringify({
    q: query,
    limit: 6
  })
}
