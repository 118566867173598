import React, { Component } from 'react'
import { Chip } from 'react-md'
import { CloseIcon } from '@kuali/wok-ui/lib/icons/close'

import styles from './style'

export class MultiselectChip extends Component {
  handleClick = () => {
    this.props.onClick(this.props.value)
  }

  render () {
    const { label } = this.props
    return (
      <Chip
        className={styles.chip}
        removable
        label={label}
        labelClassName={styles.chipLabel}
        onClick={this.handleClick}
      >
        <CloseIcon
          size='small'
          color='var(--medium-gray-400)'
          style={{ verticalAlign: 'middle', marginLeft: '8px', padding: '1px' }}
        />
      </Chip>
    )
  }
}

export default MultiselectChip
