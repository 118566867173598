/* Copyright © 2016 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */

import PropTypes from 'prop-types'
import React from 'react'
import ReactMDTextField from 'react-md/lib/TextFields'

const TextField = props => {
  const { floatingLabelText, hintText, name, value, ...rest } = props
  return (
    <ReactMDTextField
      id={props.name || props.floatingLabelText}
      label={props.floatingLabelText}
      placeholder={props.hintText}
      value={value || ''}
      {...rest}
    />
  )
}

TextField.displayName = 'TextField'
TextField.propTypes = {
  floatingLabelText: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  hintText: PropTypes.string,
  name: PropTypes.string
}

export default TextField
