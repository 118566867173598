/* Copyright © 2016 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */

/* eslint-disable */
/*
 * This file will not be tested
 */
import 'babel-polyfill'
import '../../client-common/scss/index.scss'
import '../../client-common/css/style.css'
import '@kuali/common/style.css'
import '../../client-common/vendors/css/font-awesome.min.css'
import Bluebird from 'bluebird'
import React from 'react'
import ReactDOM from 'react-dom'
import { useRouterHistory } from 'react-router'
import { createHashHistory } from 'history'
import { initSentryBrowser } from '../../client-common/lib/sentry'
import '@kuali/wok-ui/lib/variables.css'

Bluebird.config({ warnings: false })
window.Promise = Bluebird

initSentryBrowser()

if (process.env.NODE_ENV === 'production') {
  const Analytics = require('../../client-common/lib/analytics')
  Analytics.init()
}

import App from './app'

const el = document.getElementById('kuali-catalog')
const hashHistory = useRouterHistory(createHashHistory)()
/************************************************
 * This message sending / listening business is
 * here for catalog preview functionality, and
 * should be modified with care. Ideally,
 * clicking the preview catalog button from the
 * admin console will open this window, which
 * will communicate once it is ready to recieve
 * the catalog preview id, then listen for said
 * id.
 * See client-admin/app/catalogs/utils.js
 ************************************************/
if (!window.catalogId && !!window.opener) {
  window.opener.postMessage(
    { source: 'catalog-preview', payload: 'ready' },
    '*'
  )
  window.addEventListener(
    'message',
    msg => {
      if (msg.data && msg.data.source === 'catalog-admin') {
        window.catalogId = msg.data.payload
        ReactDOM.unmountComponentAtNode(el)
        ReactDOM.render(<App history={hashHistory} />, el)
      }
    },
    false
  )
  ReactDOM.render(<App history={hashHistory} />, el)
} else {
  ReactDOM.render(<App history={hashHistory} />, el)
}
