/* Copyright © 2016 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */

/* eslint react/forbid-prop-types: 0 react/no-danger: 0 */
import PropTypes from 'prop-types'
import React from 'react'

import styles from './style'

const RichTextView = props => {
  const { data } = props
  return (
    <div
      className={styles.richTextWrapper}
      dangerouslySetInnerHTML={{ __html: data }}
    />
  )
}

RichTextView.displayName = 'RichTextView'

RichTextView.propTypes = {
  data: PropTypes.string
}

export default RichTextView
