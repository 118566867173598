/* Copyright © 2016 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */

/* eslint react/forbid-prop-types:0 */
import { get, isArray, map } from 'lodash'
import PropTypes from 'prop-types'
import React from 'react'

import styles from './style'

const createTable = (headers, rowLabels, rows) => {
  const table = []
  const tableHeaders = []
  if (rowLabels && rowLabels.length) {
    tableHeaders.push(<th />)
  }
  for (const header of headers) {
    tableHeaders.push(<th>{header}</th>)
  }
  table.push(<thead><tr>{tableHeaders}</tr></thead>)
  const tableData = []
  // NOTE: rows is almost always an array, but on occasion is an object with numbered keys
  //       so we can't do a .forEach or anything like that
  for (const i in rows) {
    const row = rows[i]
    const tableRow = []
    if (rowLabels) {
      tableRow.push(<th>{rowLabels[i] || ''}</th>)
    }
    for (let i = 0; i < headers.length; i++) {
      tableRow.push(<td key={i}>{row[i]}</td>)
    }
    tableData.push(<tr>{tableRow}</tr>)
  }
  table.push(<tbody>{tableData}</tbody>)
  return <table>{table}</table>
}

const TableView = props => {
  const { data, config } = props
  const { columns, defaultRowCount, rowLabels, columnCount } = config
  if (rowLabels && defaultRowCount && columnCount === 1) {
    // Render layout for table  with defaultRowCount set
    return (
      <div className={styles.wrapper}>
        {data &&
          map(data, (d, index) => (
            <div key={index} className={styles.tableRow}>
              <span>{get(rowLabels, index)}</span>
              <span className={styles.underline} />
              <span className={styles.right}>{get(d, `[0]`, 0)}</span>
            </div>
          ))}
      </div>
    )
  } else if (isArray(columns)) {
    // Render layout for table with dynamic number of rows
    const headers = columns.map((item, key) => item.header)
    return createTable(headers, rowLabels, data)
  } else {
    return <table />
  }
}

TableView.displayName = 'TableView'

TableView.propTypes = {
  data: PropTypes.array,
  labels: PropTypes.object
}

export default TableView
