/* Copyright © 2016 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */

import { find, get } from 'lodash'
import React, { Component } from 'react'

import ContentItem from './content-item'
import { PublicCatalogType } from '../../client-common/lib/types'

import { injectIntl, defineMessages, FormattedMessage } from 'react-intl'

const messages = defineMessages({
  noHomeItemIs: {
    id: 'app.no-home-item-is',
    defaultMessage: "No 'Home' Item is set on the current catalog"
  }
})

class Home extends Component {
  static displayName = 'Home'

  static propTypes = {
    catalog: PublicCatalogType
  }

  render () {
    const { props } = this
    const navigation = get(props, 'catalog.settings.catalog.navigation', [])
    const homeItem = find(navigation, nav => nav.homeItem) || navigation[0]
    if (!homeItem) {
      return (
        <div>
          <FormattedMessage {...messages.noHomeItemIs} />
        </div>
      )
    }
    return <ContentItem id={homeItem.id} home {...props} />
  }
}

export default injectIntl(Home)
