/* Copyright © 2016 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */

import PropTypes from 'prop-types'

export const ContentType = PropTypes.shape({
  _id: PropTypes.string,
  body: PropTypes.string,
  title: PropTypes.string
})

export const SetType = PropTypes.shape({
  _id: PropTypes.string,
  items: PropTypes.arrayOf(ContentType),
  title: PropTypes.string
})

export const CatalogType = PropTypes.shape({
  _id: PropTypes.string,
  items: PropTypes.arrayOf(SetType),
  title: PropTypes.string
})

export const SettingsType = PropTypes.shape({
  courseDescription: PropTypes.string,
  coursePreReqKey: PropTypes.string,
  courseTitle: PropTypes.string,
  homeItemId: PropTypes.string,
  number: PropTypes.string
})

export const PublicCatalogType = PropTypes.shape({
  _id: PropTypes.string,
  items: PropTypes.arrayOf(SetType),
  settings: SettingsType,
  title: PropTypes.string
})
