import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Collapse } from 'react-md'
import NestedNavItem from '../nav-list-item/nested-nav-item'
import { map } from 'lodash'
import replace from '../../../lib/ui-text-replace'
import styles from './style'

export class NavListItem extends Component {
  constructor (props) {
    super(props)

    this.state = {
      isHovered: false,
      isExpanded: this.anyNestedItemIsSelected()
    }
  }

  onMouseEnter = () => {
    this.setState({ isHovered: true })
  }

  onMouseLeave = () => {
    this.setState({ isHovered: false })
  }

  onClick = event => {
    event.preventDefault()

    const { closeDrawer, isExternal, onClick, route } = this.props
    if (this.hasNestedItems()) {
      this.setState({ isExpanded: !this.state.isExpanded })
      return
    }
    if (closeDrawer) {
      closeDrawer()
    }
    onClick(isExternal, route)
  }

  hasNestedItems () {
    const nestedItems = this.props.nestedItems
    return nestedItems && nestedItems.length > 0
  }

  anyNestedItemIsSelected () {
    return (
      this.hasNestedItems() &&
      this.props.nestedItems.find(item => this.props.isCurrentRoute(item.to))
    )
  }

  isSelected () {
    return this.props.isCurrentRoute(this.props.route) || this.state.isExpanded
  }

  render () {
    const {
      primaryText,
      isMobile,
      nestedItems,
      isCurrentRoute,
      intl,
      closeDrawer
    } = this.props
    const { isHovered } = this.state
    let animationClassName = ''
    if (this.isSelected()) {
      animationClassName = styles.rotate
    }
    const listItemStyle =
      this.isSelected() && !this.hasNestedItems()
        ? styles.selectedListItem + ' selectedNavItem'
        : styles.navListItem

    const linkStyle = styles.itemContentWrapper + ' md-text'

    const hidden = (isHovered || this.isSelected() || isMobile) && this.hasNestedItems() ? '' : styles.hidden
    const caret = <i className={`${styles.caretIcon} ${animationClassName} ${hidden}`} />

    return (
      <React.Fragment>
        <li
          className={listItemStyle}
          onMouseEnter={this.onMouseEnter}
          onMouseLeave={this.onMouseLeave}
          onClick={this.onClick}
        >
          <a href='#' className={linkStyle}>
            {primaryText}
            {caret}
          </a>
        </li>
        {this.hasNestedItems() ? (
          <Collapse collapsed={!this.isSelected()}>
            <ul className={styles.nestedList}>
              {map(nestedItems, nestedItem => {
                return (
                  <NestedNavItem
                    key={nestedItem._id}
                    primaryText={replace(nestedItem.title, false, intl.locale)}
                    onClick={() => {
                      this.props.onClick(nestedItem.isExternal, nestedItem.to)
                    }}
                    route={nestedItem.to}
                    isExternal={nestedItem.external}
                    isSelected={isCurrentRoute(nestedItem.to)}
                    closeDrawer={closeDrawer}
                  />
                )
              })}
            </ul>
          </Collapse>
        ) : null}
      </React.Fragment>
    )
  }
}

NavListItem.propTypes = {
  closeDrawer: PropTypes.func,
  isExternal: PropTypes.bool,
  isMobile: PropTypes.bool,
  isCurrentRoute: PropTypes.func,
  primaryText: PropTypes.string,
  route: PropTypes.string,
  intl: PropTypes.object,
  onClick: PropTypes.func,
  nestedItems: PropTypes.arrayOf(PropTypes.object)
}

export default NavListItem
