/* Copyright © 2016 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */

import axios from 'axios'
import { get, map } from 'lodash'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import DocumentTitle from 'react-document-title'

import styles from './style'
import BreadcrumbsLink from '../../../client-common/components/breadcrumbs-link'
import TopLevelPanel from '../../../client-common/components/top-level-panel'
import { printIfNeeded } from '../pages/printing'

import { injectIntl, defineMessages } from 'react-intl'

const messages = defineMessages({
  contentSet: {
    id: 'content-set.content-set',
    defaultMessage: 'Content Set'
  }
})

const paramsType = PropTypes.shape({
  id: PropTypes.string
})

class ContentSet extends Component {
  static displayName = 'ContentSet'

  static propTypes = {
    params: paramsType
  }

  static contextTypes = {
    isPrint: PropTypes.bool
  }

  constructor (props) {
    super(props)
    this.state = {
      contentSet: null,
      didPrint: false
    }

    const {
      params: { id }
    } = this.props
    if (!id || id === 'undefined') window.location = `#/home`
    else this.fetchContentSet(id)

    this.printIfNeeded = printIfNeeded.bind(this)
  }

  componentWillReceiveProps (nextProps) {
    const { id } = nextProps.params
    if (!id || id === 'undefined') window.location = `#/home`
    else this.fetchContentSet(id)
  }

  componentDidMount () {
    this._isMounted = true
  }

  componentDidUpdate () {
    this.printIfNeeded(this.state.contentSet)
  }

  componentWillUnmount () {
    this._isMounted = false
  }

  fetchContentSet (id) {
    axios
      .get(`/sets/hydrated/${id}`)
      .then(({ data: contentSet }) => {
        if (this._isMounted) this.setState({ contentSet })
      })
      .catch(err => {
        if (get(err, 'response.status') === 404) window.location = `#/404`
      })
  }

  render () {
    const { contentSet } = this.state
    const intl = this.props.intl
    return (
      <TopLevelPanel>
        <DocumentTitle
          title={`${
            contentSet
              ? contentSet.title
              : intl.formatMessage(messages.contentSet)
          }`}
        />
        <div className={styles.wrapper}>
          {contentSet &&
            map(contentSet.items, item => {
              if (item.title) {
                return (
                  <h2 className={styles.h2} key={item._id}>
                    <BreadcrumbsLink
                      current={item.title}
                      className={styles.link}
                      to={`sets/${this.props.params.id}/content/${item._id}`}
                    >
                      {item.title}
                    </BreadcrumbsLink>
                  </h2>
                )
              }
            })}
        </div>
      </TopLevelPanel>
    )
  }
}

export default injectIntl(ContentSet)
