/* Copyright © 2016 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */

/* eslint react/no-danger: 0 */
import axios from 'axios'
import { find, get, map, isEmpty } from 'lodash'
import ReactPropTypes from 'prop-types'
import React, { Component } from 'react'
import DocumentTitle from 'react-document-title'
import { routerShape } from 'react-router'
import { Element, scroller } from 'react-scroll'

import style from './program-view.css'
import AddendumView from '../../../../client-common/components/addendum-view'
import CircularProgress from '../../../../client-common/components/circular-progress'
import CollapsibleBox from '../../../../common/collapsible-box'
import TopLevelPanel from '../../../../client-common/components/top-level-panel'
import { PublicCatalogType } from '../../../../client-common/lib/types'
import replace from '../../../../client-common/lib/ui-text-replace'
import ItemDetails from '../../../../common/item-details'
import { defineMessages, FormattedMessage, injectIntl } from 'react-intl'
import { printIfNeeded } from '../printing'
import {
  appendExtensionToTitle,
  getExtensionValue
} from '../../../../common/extensions/helpers'

import { loadFeatureFlags } from '../../../../common/lib/feature-flags'

import {
  itemHasTranslations,
  maybeRenderTranslationButton,
  replaceItemKeysWithTranslation,
  shouldRenderTranslation
} from '../../../../client-common/lib/translation/translation'

const messages = defineMessages({
  changesSincePublicationTitle: {
    id: 'changes-since-publication-title',
    defaultMessage: 'Changes Since {title} Publication'
  }
})

class ProgramView extends Component {
  static displayName = 'ProgramView'

  static propTypes = {
    catalog: PublicCatalogType,
    mq: ReactPropTypes.string,
    params: ReactPropTypes.shape({
      id: ReactPropTypes.string,
      pid: ReactPropTypes.string,
      specPid: ReactPropTypes.string
    }),
    schemas: ReactPropTypes.object
  }

  static contextTypes = {
    isPrint: ReactPropTypes.bool,
    router: routerShape
  }

  constructor (props) {
    super(props)
    this.state = {
      activeSpecialization: null,
      didPrint: false,
      program: null
    }
    this.printIfNeeded = printIfNeeded.bind(this)
  }

  async loadFeatureFlags () {
    const featureFlags = await loadFeatureFlags()
    this.setState({ featureFlags })
  }

  componentDidMount () {
    this.fetchProgram(this.props)
    this.loadFeatureFlags()
  }

  componentDidUpdate () {
    this.printIfNeeded(this.state.program)
  }

  componentWillReceiveProps (nextProps) {
    this.fetchProgram(nextProps)
  }

  async fetchProgram (props) {
    const {
      catalog,
      params: { id, pid, specPid }
    } = props
    const { program } = this.state
    if (
      (pid && get(program, 'pid') === pid) ||
      (id && get(program, 'id') === id)
    ) {
      return
    }
    const endpoint = pid
      ? `/program/${catalog._id}/${pid}`
      : `/program/byId/${catalog._id}/${id}`
    try {
      const { data: program } = await axios.get(endpoint)
      this.setState({ program })
      if (specPid) this.setSpecialization(specPid)
      this.getAddenda()
    } catch (e) {
      if (e.message.indexOf('404') !== -1) window.location = '#/404'
    }
  }

  getAddenda = async () => {
    const {
      catalog,
      params: { pid }
    } = this.props
    const { data: addenda } = await axios.get(
      `/addenda/programs/${catalog._id}/${pid}`
    )
    this.setState({ addenda })
  }

  setSpecialization = async specPid => {
    if (!specPid) return
    const { catalog } = this.props
    const { program } = this.state
    const activeSpecialization = find(program.specializations, { pid: specPid })
    const { href } = window.location
    const queryString =
      href.indexOf('?') !== -1 ? href.slice(href.indexOf('?')) : ''
    this.setState({ activeSpecialization })
    if (activeSpecialization) {
      scroller.scrollTo(specPid, {
        duration: 500,
        delay: 250,
        isDynamic: true,
        smooth: true
      })
    }
    const { specPid: curSpecPid } = this.props.params
    if (curSpecPid !== specPid) {
      this.context.router.push({
        pathname: `programs/${program.pid}/${specPid}${queryString}`
      })
    }
    if (specPid) {
      const { data: specAddenda } = await axios.get(
        `/addenda/specializations/${catalog._id}/${specPid}`
      )
      this.setState({ specAddenda })
    }
  }

  maybeTranslateTitle (item, showTranslation) {
    let titleValue = get(item, 'title')

    if (showTranslation) {
      const titleTranslation = get(item, 'titletranslation', false)

      if (titleTranslation) {
        titleValue = titleTranslation
      }
    }
    return titleValue
  }

  render () {
    const { catalog, mq, schemas } = this.props
    const { settings, title } = catalog
    const { activeSpecialization, addenda, specAddenda, featureFlags, program } = this.state
    const { isPrint } = this.context
    const alternateLanguage = get(settings, 'alternateTranslation.language')

    let catKeys = get(settings, 'programs.keys', [])
    let shouldShowTranslation = shouldRenderTranslation(catalog, featureFlags)

    let programHasTranslations = itemHasTranslations(program, catKeys)

    let translationLocaleCode
    if (shouldShowTranslation && programHasTranslations) {
      catKeys = replaceItemKeysWithTranslation(
        program,
        catKeys,
        schemas,
        'programs'
      )
      translationLocaleCode = alternateLanguage
    }

    let programTitle = this.maybeTranslateTitle(program, shouldShowTranslation)

    let specializationsAtBottom = true
    let specializationsHeader
    let specializationsContent
    const programWithSpecializations = {}
    if (!isEmpty(get(program, 'specializations'))) {
      // Add newSpecializations placeholder if object exists in schema.
      // This allows the specializations to be some place other than the bottom of the program.
      specializationsHeader = (
        <h3 className={style.label}>
          {shouldShowTranslation
            ? replace('Specializations', true, alternateLanguage)
            : replace('Specializations')}
        </h3>
      )
      specializationsContent = (
        <div>
          {map(get(program, 'specializations', []), (s, i) => (
            <Element key={i} name={s.pid}>
              <div className={style.specWrapper}>
                <CollapsibleBox
                  expandable
                  expanded={s.pid === get(activeSpecialization, 'pid')}
                  id={this.maybeTranslateTitle(s, shouldShowTranslation)}
                  onExpandChange={expanded =>
                    this.setSpecialization(expanded ? s.pid : 'none')
                  }
                  title={this.maybeTranslateTitle(s, shouldShowTranslation)}
                  titleExtension={getExtensionValue(
                    settings,
                    schemas,
                    s,
                    'programs'
                  )}
                >
                  <div className={style.item}>
                    <ItemDetails
                      catalogId={catalog._id}
                      alternateLanguage={alternateLanguage}
                      dataClassName={style.pre}
                      displayOptions={get(
                        settings,
                        'specializations.displayOptions',
                        {}
                      )}
                      item={s || {}}
                      itemType='specializations'
                      keys={
                        shouldShowTranslation && programHasTranslations
                          ? replaceItemKeysWithTranslation(
                            s,
                            get(settings, 'specializations.keys', []),
                            schemas,
                            'specializations'
                          )
                          : get(settings, 'specializations.keys', [])
                      }
                      labelClassName={style.label}
                      schemas={schemas || {}}
                      translationLocaleCode={translationLocaleCode}
                      replacements={get(
                        settings,
                        'specializations.replacements',
                        {}
                      )}
                    />
                  </div>
                </CollapsibleBox>
              </div>
            </Element>
          ))}
        </div>
      )
      for (const gkey of catKeys) {
        const gadget = schemas.programs[gkey]
        if (gadget && gadget.type === 'NewSpecializations') {
          programWithSpecializations[gkey] = {
            specializations: specializationsContent
          }
          specializationsAtBottom = false
          break
        }
      }
    }
    return (
      <div>
        <TopLevelPanel>
          {!program && <CircularProgress style={{ left: '50%' }} />}
          {program && (
            <div className={style.wrapper}>
              <DocumentTitle title={programTitle || 'View Program'} />
              <div className={style.itemTitleAndTranslationButton}>
                <div>
                  <h2
                    className={style.title}
                    onClick={() => this.setSpecialization('none')}
                  >
                    {appendExtensionToTitle(
                      settings,
                      program,
                      'programs',
                      schemas,
                      programTitle,
                      shouldShowTranslation
                    )}
                  </h2>
                </div>
                <div className={style.translationButtonContainer}>
                  {maybeRenderTranslationButton(
                    catalog,
                    programHasTranslations,
                    featureFlags,
                    isPrint
                  )}
                </div>
              </div>

              <ItemDetails
                catalogId={catalog._id}
                alternateLanguage={alternateLanguage}
                dataClassName={style.pre}
                displayOptions={get(settings, 'programs.displayOptions', {})}
                item={{ ...program, ...programWithSpecializations }}
                itemType='programs'
                keys={catKeys}
                labelClassName={style.label}
                schemas={schemas || {}}
                translationLocaleCode={translationLocaleCode}
                replacements={get(settings, 'programs.replacements', {})}
              />
              {specializationsAtBottom && (
                <div>
                  {specializationsHeader}
                  {specializationsContent}
                </div>
              )}
            </div>
          )}
        </TopLevelPanel>
        {(!isEmpty(addenda) || !isEmpty(specAddenda)) && (
          <TopLevelPanel isMain={false}>
            <div className={style.addendaHeader}>
              <div className={style.cardTitle}>
                <FormattedMessage
                  {...messages.changesSincePublicationTitle}
                  values={{ title: title }}
                />
              </div>
            </div>
            {map(addenda, (addendum, index) => (
              <div className={style.addendumViewWrapper} key={index}>
                <AddendumView
                  addendum={addendum || {}}
                  settings={settings}
                  mq={mq}
                  schemas={schemas}
                />
              </div>
            ))}
            {map(specAddenda, (addendum, index) => (
              <div className={style.addendumViewWrapper} key={index}>
                <AddendumView
                  addendum={addendum || {}}
                  settings={settings}
                  mq={mq}
                  schemas={schemas}
                />
              </div>
            ))}
          </TopLevelPanel>
        )}
      </div>
    )
  }
}

export default injectIntl(ProgramView)
