/* Copyright © 2016 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */

import React, { Component } from 'react'

import styles from './style'

import { injectIntl, defineMessages, FormattedMessage } from 'react-intl'

const messages = defineMessages({
  sorryWeAreCurrently: {
    id: 'maintenance.sorry-we-are-currently',
    defaultMessage: 'Sorry, we are currently undergoing maintenance'
  },
  pleaseVisitUsAgain: {
    id: 'maintenance.please-visit-us-again',
    defaultMessage: 'Please visit us again in 30 minutes!'
  }
})

class Maintenance extends Component {
  render () {
    return (
      <div className={styles.wrapper}>
        <h2>
          <FormattedMessage {...messages.sorryWeAreCurrently} />
        </h2>
        <h2>
          <FormattedMessage {...messages.pleaseVisitUsAgain} />
        </h2>
      </div>
    )
  }
}

export default injectIntl(Maintenance)
