/* Copyright © 2016 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */

/* eslint react/forbid-prop-types:0 */
import { find, get, has } from 'lodash'
import PropTypes from 'prop-types'
import React from 'react'
import { injectIntl } from 'react-intl'

const PickOneView = props => {
  const { data, options, intl } = props
  const selected = find(options, o => o.key === data)
  let text = get(selected, 'text')
  if (
    intl.locale !== 'en' &&
    has(selected, ['translatedText', [intl.locale]])
  ) {
    text = selected.translatedText[intl.locale]
  }
  return <div>{selected ? text : 'Selected option not found!'}</div>
}

PickOneView.displayName = 'PickOneView'
PickOneView.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  options: PropTypes.arrayOf(PropTypes.object)
}

export default injectIntl(PickOneView)
