/* Copyright © 2016 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */

/* eslint react/no-did-mount-set-state:0 */
import {
  each,
  filter,
  get,
  isArray,
  isEmpty,
  lowerCase,
  map,
  orderBy,
  uniqBy
} from 'lodash'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import DocumentTitle from 'react-document-title'

import styles from './style'
import AddendumView from '../../../../client-common/components/addendum-view'
import CircularProgress from '../../../../client-common/components/circular-progress'
import Divider from '../../../../client-common/components/divider'
import SelectField from '../../../../client-common/components/select-field'
import TopLevelPanel from '../../../../client-common/components/top-level-panel'
import { page } from '../../../../client-common/lib/analytics'
import { PublicCatalogType } from '../../../../client-common/lib/types'
import { getCachedEndpoint } from '../../../../client-common/lib/cacher'
import replace from '../../../../client-common/lib/ui-text-replace'
import { injectIntl, defineMessages, FormattedMessage } from 'react-intl'

const messages = defineMessages({
  filterByType: {
    id: 'addenda.filter-by-type',
    defaultMessage: 'Filter by Type'
  },
  all: {
    id: 'addenda.all',
    defaultMessage: 'All'
  }
})

class AddendaView extends Component {
  static displayName = 'AddendaView'

  static propTypes = {
    catalog: PublicCatalogType,
    mq: PropTypes.string
  }

  constructor () {
    super()
    this.state = {
      addenda: 'loading',
      curFilter: null,
      settings: {}
    }
    page('Addenda')
  }

  async componentDidMount () {
    try {
      const {
        catalog: { _id }
      } = this.props
      const unsortedAddenda = await getCachedEndpoint(`/addenda/${_id}`)
      const addenda = orderBy(unsortedAddenda, 'date', ['desc'])
      this.setState({ addenda })
    } catch (e) {
      console.error(e)
      this.setState({ addenda: [] })
    }
  }

  filterAddenda () {
    const { addenda, curFilter } = this.state
    if (!curFilter) return addenda
    return filter(addenda, a => a.type === curFilter)
  }

  buildFilterOptions (addenda, intl) {
    const options = [{ key: intl.formatMessage(messages.all), value: '' }]
    if (!isArray(addenda)) return options

    each(addenda, a => {
      options.push({
        key: replace(get(a, 'type'), true, intl.locale),
        value: a.type
      })
    })

    return uniqBy(options, 'key')
  }

  render () {
    const {
      catalog: { settings },
      mq,
      schemas,
      intl
    } = this.props
    const { addenda } = this.state
    const filteredAddenda = this.filterAddenda(addenda)
    let content
    if (isArray(addenda)) {
      if (isEmpty(addenda)) {
        content = (
          <h3>
            <FormattedMessage
              id='addenda.there-are-no-addenda'
              defaultMessage={`There are no addenda for this ${lowerCase(replace('catalog'))}`}
            />
          </h3>
        )
      } else {
        content = map(filteredAddenda, (addendum, index) => (
          <AddendumView
            addendum={addendum}
            key={addendum.id}
            mq={mq}
            settings={settings}
            schemas={schemas}
          />
        ))
      }
    } else if (addenda === 'loading') {
      content = (
        <div className={styles.progressWrapper}>
          <CircularProgress />
        </div>
      )
    } else {
      content = (
        <h3>
          <FormattedMessage
            id='addenda.something-went-wrong-try'
            defaultMessage='¯\_(ツ)_/¯ Something went wrong. Try refreshing the page to see if
          that fixes the problem.'
          />
        </h3>
      )
    }
    return (
      <TopLevelPanel>
        <DocumentTitle title='Addenda' />
        <div className={styles.threeColumns}>
          <h2 className={styles.left}>
            <FormattedMessage id='addenda.addenda' defaultMessage='Addenda' />
          </h2>
          {!isEmpty(addenda) && (
            <div className={[styles.center, 'kuali-print-hide'].join(' ')}>
              <SelectField
                fullWidth
                floatingLabelText={intl.formatMessage(messages.filterByType)}
                itemLabel='key'
                menuItems={this.buildFilterOptions(addenda, intl)}
                name={'Filter by Type'}
                onChange={val => this.setState({ curFilter: val })}
              />
            </div>
          )}
        </div>
        <Divider style={{ margin: '16px 0px' }} />
        {content}
      </TopLevelPanel>
    )
  }
}

export default injectIntl(AddendaView)
