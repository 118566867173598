import { get, includes } from 'lodash'

// This mapping represents the link between the Catalog navigation settings object
// and item types that are searchable
const itemTypesMap = {
  '/courses': 'courses',
  '/programs': 'programs',
  '/programs/category/all': 'programs',
  '/policies': 'policies',
  '/experiences': 'experiences',
  '/institutional-information': 'institutional-information'
}

const filterableItemTypes = settings => {
  const configuredNavItems = get(settings, 'catalog.navigation', [])

  const itemTypes = []

  configuredNavItems.forEach(item => {
    const mapping = itemTypesMap[item.to]
    if (mapping) itemTypes.push(mapping)
    if (item.to === '/programs') {
      itemTypes.push('specializations')
    }
    if (includes(item.to, '/category')) {
      const type = item.to.split('/')[1]
      if (!itemTypes.includes(type)) {
        itemTypes.push(type)
      }
    }
  })

  itemTypes.push('institutional-information')
  return itemTypes
}

export { filterableItemTypes }
