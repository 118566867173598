/* Copyright © 2016 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */

import classNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'

import styles from './style'
import Breadcrumbs from '../breadcrumb'
import GoToTop from '../go-to-top'
import ScrollSpyify from '../scroll-spy'

function TopLevelPanel (
  { children, scrollRight, scrollTop, scrollY, isMain = true },
  { mq }
) {
  return (
    <div>
      <div className={styles.topLevelPanelWrapper}>
        {isMain && <Breadcrumbs mq={mq} />}
        <div
          id='__KUALI_TLP'
          className={classNames({
            [styles.topLevelPanel]: true,
            [styles.topLevelPanelMobile]: mq === 'small'
          })}
          zDepth={2}
        >
          {children}
          {isMain && (
            <GoToTop
              className={classNames({
                [styles.goToTopButton]: true,
                [styles.goToTopButtonShow]: scrollTop < scrollY
              })}
              show={scrollTop < scrollY}
              style={{
                bottom: '24px',
                left: `${scrollRight - 80}px`,
                position: 'sticky'
              }}
            />
          )}
        </div>
      </div>
    </div>
  )
}

TopLevelPanel.displayName = 'TopLevelPanel'
TopLevelPanel.propTypes = {
  children: PropTypes.node,
  scrollTop: PropTypes.number,
  scrollY: PropTypes.number
}
TopLevelPanel.contextTypes = {
  mq: PropTypes.string
}

export default ScrollSpyify(TopLevelPanel, '__KUALI_TLP')
