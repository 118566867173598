import { find, get, groupBy, sortBy } from 'lodash'

export function getFilterLabel (filter, intl, schema) {
  let filterLabel = get(schema, `${filter}.label`)
  if (intl.locale !== 'en') {
    filterLabel = get(schema, `${filter}.translatedLabels.${intl.locale}`)
  }
  return filterLabel
}

export function setFilterOptions (state, intl, items, itemFilters, itemSchema) {
  itemFilters.forEach(itemFilter => {
    const gadgetType = get(itemSchema, `${itemFilter}.type`)

    // this object will act as a set to store unique filter values and their respective label
    const filterValuesToLabels = {}

    items.forEach(item => {
      switch (gadgetType) {
        case 'Checkboxes':
          const checkboxValues = get(item, itemFilter)
          if (!checkboxValues) return
          const options = get(itemSchema, `${itemFilter}.options`, {})
          Object.keys(checkboxValues).forEach(key => {
            if (!checkboxValues[key]) return
            const option = find(options, o => o.key === key)
            if (!option || get(option, 'hidden')) return
            filterValuesToLabels[key] = option.text
          })
          break

        default:
          let label
          if (intl.locale !== 'en') {
            label = get(
              item,
              `${itemFilter}.translatedNames.${intl.locale}`,
              get(item, `${itemFilter}.name`)
            )
          } else {
            label = get(item, `${itemFilter}.name`)
          }
          const value = get(item, `${itemFilter}.name`)
          if (value) {
            filterValuesToLabels[value] = label
          }
          break
      }
    })

    const { filterValuesMap } = state
    filterValuesMap[itemFilter] = sortBy(
      Object.keys(filterValuesToLabels).map(key => {
        return {
          filter: itemFilter,
          label: filterValuesToLabels[key],
          value: key
        }
      }),
      ['label']
    )
  })
}

export function filterItems (selectedFilterOptions, items, itemSchema) {
  // group filters by filter type to handle OR logic
  const filterGroups = groupBy(selectedFilterOptions, 'filter')
  return items.filter(item => {
    for (const filterGroup in filterGroups) {
      let isFiltered = true
      for (const filterOption of filterGroups[filterGroup]) {
        const { filter, value: filterValue } = filterOption
        const gadgetType = get(itemSchema, `${filter}.type`)
        switch (gadgetType) {
          case 'Checkboxes':
            if (get(item, `${filter}.${filterValue}`)) {
              isFiltered = false
            }
            break
          default:
            if (filterValue === get(item, `${filter}.name`)) {
              isFiltered = false
            }
            break
        }
      }
      if (isFiltered) {
        return false
      }
    }
    return true
  })
}
