/* Copyright © 2016 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */

/* eslint react/forbid-prop-types: 0 react/no-danger: 0 */
import classnames from 'classnames'
import { get, filter, toLower } from 'lodash'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import parse, { domToReact } from 'html-react-parser'
import axios from 'axios'
import DOMPurify from 'dompurify'
import CircularProgress from '../../client-common/components/circular-progress'
import replace from '../../client-common/lib/ui-text-replace'

import styles from './style'
import { injectIntl, defineMessages } from 'react-intl'

const courseCache = {}

const messages = defineMessages({
  totalCredits: {
    id: 'omegaRulesView.totalCredits',
    defaultMessage: 'Total Credits'
  },
  grandTotalCredits: {
    id: 'omegaRulesView.grandTotalCredits',
    defaultMessage: 'Grand Total Credits'
  },
  complete: {
    id: 'omegaRulesView.complete',
    defaultMessage: 'Complete'
  },
  allOfTheFollowing: {
    id: 'omegaRulesView.allOfTheFollowing',
    defaultMessage: 'all of the following'
  },
  ofTheFollowing: {
    id: 'omegaRulesView.ofTheFollowing',
    defaultMessage: 'of the following'
  }
})
class OmegaRulesView extends Component {
  static displayName = 'OmegaRulesView'

  static propTypes = {
    rules: PropTypes.string
  }

  constructor (props) {
    super(props)
    this.state = {
      collapsedSections: {}
    }
  }

  toggleSection = (key) => {
    this.setState(prevState => ({
      collapsedSections: {
        ...prevState.collapsedSections,
        [key]: !prevState.collapsedSections[key]
      }
    }))
  }

  renderRules () {
    const rules = get(this, 'props.rules', '')
    const intl = this.props.intl
    const { collapsedSections } = this.state
    const handleToggleClick = (event, key) => {
      event.preventDefault()
      this.toggleSection(key)
    }
    const fixedRules = rules
      .replace(/<h2><!-- react-text: (\d*) -->Rules for.*?<\/h2>/g, '')
      .replace(/<div><button.*?\/button><\/div>/g, '')
      .replace(/<button><i class="fa fa-angle-up"><\/i><\/button>/g, '')
      .replace(/target="_blank"/g, '')
      .replace(/<h3>/g, `<div class=${styles.itemHeaderH3}>`)
      .replace(/<\/h3>/g, '</div>')
      .replace(
        /Grand Total Credits/g,
        intl.formatMessage(messages.grandTotalCredits)
      )
      .replace(/Total Credits/g, intl.formatMessage(messages.totalCredits))
      .replace(/Complete/g, intl.formatMessage(messages.complete))
      .replace(
        /<!-- -->all<!-- --> of the following/g,
        intl.formatMessage(messages.allOfTheFollowing)
      )
      .replace(/of the following/g, intl.formatMessage(messages.ofTheFollowing))
    let courseSetCounter = 0
    const options = {
      replace: (domNode) => {
        if (domNode.type === 'tag' && domNode.name === 'h2') {
          return (
            <div
              className={styles.itemHeaderH2}
            >
              {domToReact(domNode.children, options)}
            </div>
          )
        } else if (domNode.type === 'tag' && domNode.name === 'section') {
          let key = get(domNode, "children[0].attribs['data-test']")
          let isSubgroup = false
          if (!key) {
            key = get(domNode, 'children[0].children[0].children[0].children[0].children[0].data')
            if (key) {
              isSubgroup = true
            }
          }
          const isCourseSet = get(domNode, "children[0].children[0].attribs['data-test']") === 'courseSetTitle'
          if (isCourseSet) {
            key = `courseset${courseSetCounter}`
          }
          const isHidden = collapsedSections[key]
          if (isCourseSet) {
            return (
              <div className={`${styles.courseSet} ${isHidden ? styles.collapsed : ''}`}>
                {domToReact(domNode.children, options)}
              </div>
            )
          }
          return (
            <section
              key={key}
              className={isHidden ? (isSubgroup ? styles.collapsedSubgroup : styles.collapsed) : ''}
            >
              {domToReact(domNode.children, options)}
            </section>
          )
        } else if (domNode.type === 'tag' && domNode.name === 'header') {
          let key = get(domNode, "attribs['data-test']")
          let isSubgroup = false
          if (!key) {
            key = get(domNode, 'children[0].children[0].children[0].data')
            if (key) {
              isSubgroup = true
            }
          }
          const isCourseSet = get(domNode, "children[0].attribs['data-test']") === 'courseSetTitle'
          if (isCourseSet) {
            key = `courseset${courseSetCounter++}`
          }
          const isHidden = collapsedSections[key]
          return <header {...domNode.attribs} style={isCourseSet ? { background: 'none' } : {}} className={isCourseSet ? styles.courseSetHeader : ''}>
            {isCourseSet && <span
              onClick={(event) => key && handleToggleClick(event, key)}
            >
              {key ? (isHidden
                ? <i style={{ verticalAlign: 'middle' }} class='md-icon material-icons md-text--inherit'>keyboard_arrow_down</i>
                : <i style={{ verticalAlign: 'middle' }} class='md-icon material-icons md-text--inherit'>keyboard_arrow_up</i>
              ) : ''}
            </span>}
            {domToReact(filter(domNode.children, (v, i) => i < 2), options)}
            {isSubgroup && <div />}
            {!isCourseSet && <div
              onClick={(event) => key && handleToggleClick(event, key)}
            >
              {key ? (isHidden
                ? <i class='md-icon material-icons md-text--inherit'>keyboard_arrow_down</i>
                : <i class='md-icon material-icons md-text--inherit'>keyboard_arrow_up</i>
              ) : ''}
            </div>}
          </header>
        } else if (domNode.type === 'tag' && domNode.name === 'a' && /#\/courses\/view\/[0-9a-f]+/.test(get(domNode, 'attribs.href'))) {
          const courseId = domNode.attribs.href.split('/').pop()
          return <a
            onMouseOver={e => this.loadCourseTooltip(e, e.target, courseId)}
            onMouseOut={this.hideCourseTooltip}
            {...domNode.attribs}
          >
            {domToReact(domNode.children, options)}
          </a>
        }
        // Return null to keep the original HTML element unchanged
        return null
      }
    }
    return parse(fixedRules, options)
  }

  hideCourseTooltip = (e) => {
    this.setState({ courseTooltip: null })
  }

  loadCourseTooltip = (e, target, courseId) => {
    const { catalogId } = this.props
    if (!catalogId) return
    const { x, y } = target.getBoundingClientRect()
    const credits = toLower(replace('credits')) || 'credits'
    const title = target.parentElement.innerText.replace(/ \((\d+(\.\d+)?)\)/, ` ($1 ${credits})`).replace(/ - /, ' ')
    this.setState({ courseTooltip: { x, y, courseId, title } })
    this.loadCourseDescription(courseId)
  }

  loadCourseDescription = async (courseId) => {
    const { catalogId } = this.props
    const key = `${catalogId}_${courseId}`
    let description
    if (courseCache[key]) {
      description = courseCache[key]
    } else {
      const { data } = await axios.get(`/course/byId/${catalogId}/${courseId}`)
      courseCache[key] = data.description
      description = data.description
    }
    this.setState(state => {
      if (state.courseTooltip && state.courseTooltip.courseId === courseId) {
        return { ...state, courseTooltip: { ...state.courseTooltip, description } }
      } else {
        return state
      }
    })
  }

  render () {
    const renderedRules = this.renderRules()
    const { courseTooltip } = this.state
    return <>
      <div className={classnames('rules-wrapper', styles.rulesWrapper)}>
        {renderedRules}
      </div>
      {courseTooltip && <div className={`coursePopover ${styles.coursePopover}`} style={{ top: 'auto', bottom: `calc(100vh - ${courseTooltip.y}px)`, left: courseTooltip.x - 200 }}>
        <h3>{courseTooltip.title}</h3>
        {courseTooltip.description ? <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(courseTooltip.description) }} /> : <CircularProgress />}
      </div>}
    </>
  }
}

export default injectIntl(OmegaRulesView)
