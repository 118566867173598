/* Copyright © 2016 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */

import { debounce, noop } from 'lodash'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import updateQuery from 'update-query'
import qs from 'querystring'

import styles from './style'
import IconButton from '../button/icon'
import * as Icons from '../icon'
import TextField from '../text-field'

export default class Search extends Component {
  static displayName = 'Search'

  static propTypes = {
    hintText: PropTypes.string,
    onChange: PropTypes.func,
    searchIconStyle: PropTypes.object
  }

  static contextTypes = {
    isPrint: PropTypes.bool
  }

  constructor (props) {
    super(props)
    this.state = {
      debouncedTrigger: debounce(this.trigger, 500),
      oldValue: null,
      value: this.getSearchTermFromQS()
    }
  }

  componentDidMount () {
    this.trigger()
  }

  getSearchTermFromQS () {
    const { searchTerm } = qs.parse(window.location.href.split('?')[1])
    const value = !searchTerm || searchTerm === '' ? null : searchTerm
    return value
  }

  onChange (value) {
    const newValue = value === '' ? null : value
    const shouldUpdate = value.length > 2 || !value.length
    this.setState(
      { value: newValue },
      shouldUpdate ? this.state.debouncedTrigger : noop
    )
  }

  updateQS () {
    const { value } = this.state
    const path = updateQuery(window.location.href, { searchTerm: value })
    window.history.pushState({ path }, '', path)
  }

  trigger () {
    const { oldValue, value } = this.state
    if (oldValue !== value) {
      this.updateQS()
      this.props.onChange(value)
      this.setState({ oldValue: value })
    }
  }

  clear () {
    this.setState({ value: null }, this.trigger)
  }

  onKeyDown (keyCode) {
    if (keyCode === 13) return this.trigger()
  }

  render () {
    const { isPrint } = this.context
    const { hintText, searchIconStyle } = this.props
    const { value } = this.state
    if (isPrint) return null
    return (
      <div className={styles.searchWrapper} role='search'>
        {value && (
          <div className={styles.clearIconWrapper}>
            <IconButton
              className={styles.iconWrapper}
              tooltip='Clear'
              onClick={this.clear.bind(this)}
            >
              <Icons.Clear className={styles.clearIcon} color='dcdcdc' />
            </IconButton>
          </div>
        )}
        <div className={styles.searchBox}>
          <TextField
            autoFocus
            floatingLabelText={
              <div>
                {hintText && (
                  <span className={styles.searchLabel}>{hintText}</span>
                )}
              </div>
            }
            fullWidth
            name='Search'
            onChange={value => this.onChange(value)}
            onKeyDown={e => this.onKeyDown(e.keyCode)}
            value={value}
          />
        </div>
        <div className={styles.iconWrapper} style={searchIconStyle}>
          <IconButton onClick={this.trigger.bind(this)}>
            <Icons.Search className={styles.searchIcon} color='#4c4c4c' />
          </IconButton>
        </div>
      </div>
    )
  }
}
