import React, { Component } from 'react'
import styles from './style'
import errorImg from '../../../../client-common/img/landings/error.svg'
import { ArrowBack } from '../../../../client-common/components/icon'
import { Link, routerShape } from 'react-router'

export default class NotFound extends Component {
  static displayName = 'NotFound'

  static contextTypes = {
    router: routerShape
  }

  render () {
    return (
      <div className={styles.container}>
        <div className={styles.section}>
          <object data={errorImg} type='image/svg+xml' />
        </div>
        <h1 className={[styles.section, styles.secondary].join(' ')}>
          404 Error
        </h1>
        <div className={[styles.section, styles.secondary].join(' ')}>
          The requested page was not found.
        </div>
        <div className={styles.section}>
          <span className={styles.action}>
            <a href='#' onClick={this.goBack}>
              <ArrowBack className='linkColor' />
              Go Back
            </a>
          </span>
          <span className={styles.action}>
            <Link to='/'>Return to Catalog Home</Link>
          </span>
        </div>
      </div>
    )
  }

  goBack = event => {
    event.preventDefault()
    this.context.router.goBack()
  }
}
