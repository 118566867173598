/* global RAVEN_DSN */

import * as Sentry from '@sentry/browser'

const ravenDsn =
  typeof RAVEN_DSN === 'undefined' ? null : /* istanbul ignore next */ RAVEN_DSN

const release = process.env.NODE_ENV || 'DEV'

export function initSentryBrowser () {
  if (!ravenDsn) return
  Sentry.init({
    dsn: ravenDsn,
    release,
    ignoreErrors: [
      // Random plugins/extensions
      'top.GLOBALS',
      // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error. html
      'originalCreateNotification',
      'canvas.contentDocument',
      'MyApp_RemoveAllHighlights',
      'http://tt.epicplay.com',
      "Can't find variable: ZiteReader",
      'jigsaw is not defined',
      'ComboSearch is not defined',
      'http://loading.retry.widdit.com/',
      'atomicFindClose',
      // Facebook borked
      'fb_xd_fragment',
      // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to
      // reduce this. (thanks @acdha)
      // See http://stackoverflow.com/questions/4113268
      'bmi_SafeAddOnload',
      'EBCallBackMessageReceived',
      // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
      'conduitPage'
    ],
    whitelistUrls: [/kuali\.co/]
  })
}
