/* globals SEGMENT_TOKEN */

import Analytics from 'analytics.js-loader'

const segmentToken =
  typeof SEGMENT_TOKEN === 'undefined'
    ? null
    : /* istanbul ignore next */ SEGMENT_TOKEN
const ANALYTICS_ERROR = 'ANALYTICS ERROR'
let analytics

export const init = () => {
  if (segmentToken) {
    analytics = Analytics({
      writeKey: segmentToken,
      skipPageCall: true
    })
  }
}

export const page = name => {
  if (segmentToken) {
    const institution = window.subdomain.split('//')[1].split('.')[0]
    try {
      analytics.page(name, { institution })
    } catch (e) {
      console.error(ANALYTICS_ERROR, e)
    }
  }
}
