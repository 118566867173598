/* Copyright © 2016 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { produce } from 'immer'
import {
  find,
  fromPairs,
  get,
  isEmpty,
  isUndefined,
  map,
  remove,
  sortBy
} from 'lodash'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import DocumentTitle from 'react-document-title'
import { scroller } from 'react-scroll'
import qs from 'querystring'

import styles from './style'
import BreadcrumbLink from '../../../../client-common/components/breadcrumbs-link'
import CircularProgress from '../../../../client-common/components/circular-progress'
import CollapsibleBox from '../../../../common/collapsible-box'
import MultiselectChip from '../../../../client-admin/app/components/chip/index'
import SelectField from '../../../../client-common/components/select-field'
import TopLevelPanel from '../../../../client-common/components/top-level-panel'
import { getCachedEndpoint } from '../../../../client-common/lib/cacher'
import { PublicCatalogType } from '../../../../client-common/lib/types'
import { page } from '../../../../client-common/lib/analytics'
import replace from '../../../../client-common/lib/ui-text-replace'
import { injectIntl, defineMessages, FormattedMessage } from 'react-intl'
import { printIfNeeded } from '../printing'
import {
  filterItems,
  getFilterLabel,
  setFilterOptions
} from '../../../../client-common/lib/filters'
import ItemContents from '../extensions/index'
import {
  getHashParams,
  getHashParamsString
} from '../../../../client-common/lib/translation/translation'

const messages = defineMessages({
  course: {
    id: 'courses.course',
    defaultMessage: 'course'
  },
  noCourseResults: {
    id: 'courses.no-course-results',
    defaultMessage: 'No course results'
  },
  searchForCourse: {
    id: 'courses.search-for-course',
    defaultMessage: 'Search for {course}'
  },
  viewCourses: {
    id: 'courses.view-courses',
    defaultMessage: 'View {courses}'
  },
  coursesList: {
    id: 'courses.courses-list',
    defaultMessage: 'Courses List'
  },
  courseSearchResults: {
    id: 'courses.course-search-results',
    defaultMessage: 'Course Search Results'
  },
  subject: {
    id: 'courses.subject',
    defaultMessage: 'Subject'
  },
  loadingCourses: {
    id: 'courses.loading-courses',
    defaultMessage: 'Loading Courses'
  },
  search: {
    id: 'courses.search',
    defaultMessage: 'Search'
  },
  title: {
    id: 'courses.title',
    defaultMessage: 'Title'
  },
  resultsFound: {
    id: 'courses.resultsFound',
    defaultMessage: 'results found'
  }
})

class CourseList extends Component {
  static displayName = 'CourseList'

  static propTypes = {
    catalog: PublicCatalogType
  }

  static contextTypes = {
    isPrint: PropTypes.bool
  }

  constructor (props) {
    super(props)
    this.state = {
      courses: null,
      didPrint: false,
      filterValuesMap: {},
      latestSearchTerm: '',
      loading: true,
      selectedFilterOptions: []
    }
    page('Courses')
    this.fetchCourses('')
    const { href } = window.location
    const { group } = qs.parse(href.split('?')[1])
    if (group) {
      this.state.singleGroup = group
    }
    this.printIfNeeded = printIfNeeded.bind(this)
  }

  componentDidUpdate () {
    const { singleGroup } = this.state

    const { href } = window.location
    const { group } = qs.parse(href.split('?')[1])
    if (group !== singleGroup) {
      this.setState({ singleGroup: group })
    }
    this.printIfNeeded(this.state.courses)
  }

  componentDidMount () {
    this._isMounted = true
  }

  componentWillUnmount () {
    this._isMounted = false
  }

  async fetchCourses (searchTerm) {
    this.setState({ latestSearchTerm: searchTerm, loading: true })

    const catalogId = this.props.catalog._id
    const query = qs.stringify({ q: searchTerm })
    const courses = await getCachedEndpoint(`/courses/${catalogId}?${query}`)
    if (this.state.latestSearchTerm !== searchTerm) {
      return
    }

    const groups = isEmpty(searchTerm)
      ? this.groupByAndSort(courses, 'subjectCode.description')
      : {}
    const { expanded } = qs.parse(window.location.href.split('?')[1])
    if (this._isMounted) {
      this.setState(
        { courses, groups, loading: false, searchTerm, expanded },
        () => this.getFilterValues(courses)
      )
      if (expanded) {
        scroller.scrollTo(expanded, {
          duration: 500,
          delay: 250,
          smooth: true
        })
      }
    }
  }

  async getFilterValues (courses) {
    const { catalog, schemas, intl } = this.props
    const courseFilters = get(catalog, 'settings.courses.filters', [])
    if (courseFilters.length < 1) return

    const { filterValuesMap } = produce(this.state, draftState => {
      setFilterOptions(
        draftState,
        intl,
        courses,
        courseFilters,
        schemas.courses
      )
    })
    this.setState({ filterValuesMap, loading: false })
  }

  setFilter = (filter, newFilterValue, label) => {
    const { courses } = this.state
    const filterValue = newFilterValue === '' ? null : newFilterValue
    const selectedFilterOptions = produce(
      get(this.state, 'selectedFilterOptions'),
      selectedFilterOptionsDraft => {
        if (!find(selectedFilterOptionsDraft, { filter, value: filterValue })) {
          selectedFilterOptionsDraft.push({ filter, label, value: filterValue })
        }
      }
    )

    this.setState({ selectedFilterOptions }, () => {
      const filteredCourses = this.filterCourses(courses)
      const groups = this.groupByAndSort(filteredCourses)
      this.setState({ filteredCourses, groups })
    })
  }

  filterCourses = courses => {
    const { schemas } = this.props
    const { selectedFilterOptions } = this.state
    return filterItems(selectedFilterOptions, courses, schemas.courses)
  }

  handleChipRemove (filterKey, filterValue) {
    const { courses } = this.state
    const selectedFilterOptions = produce(
      this.state.selectedFilterOptions,
      selectedFilterOptionsDraft => {
        selectedFilterOptionsDraft = remove(
          selectedFilterOptionsDraft,
          ({ value, filter }) => value === filterValue && filter === filterKey
        )
      }
    )
    this.setState({ selectedFilterOptions }, () => {
      const filteredCourses = this.filterCourses(courses)
      const groups = this.groupByAndSort(filteredCourses)
      this.setState({ filteredCourses, groups })
    })
  }

  renderFilterChips () {
    const { selectedFilterOptions } = this.state
    const filterChips = []
    if (!isEmpty(selectedFilterOptions)) {
      selectedFilterOptions.forEach(({ filter, label, value }) =>
        filterChips.push(
          <MultiselectChip
            key={value}
            label={label}
            onClick={() => {
              this.handleChipRemove(filter, value)
            }}
          />
        )
      )
      return <div className={styles.filterChipsWrapper}>{filterChips}</div>
    }
    return null
  }

  groupByAndSort = courses => {
    const { intl } = this.props
    const {
      catalog: { settings }
    } = this.props
    const useLinkedGroup = get(settings, 'courses.useLinkedGroup')
    let groupKey = useLinkedGroup === true ? 'linkedGroup' : 'description'
    const groups = {}
    courses.forEach(course => {
      let key = get(course, `subjectCode.${groupKey}`)
      if (intl.locale !== 'en') {
        key = get(course, `subjectCode.translatedNames.${intl.locale}`, key)
      }
      if (groups[key]) {
        groups[key].push(course)
      } else {
        groups[key] = [course]
      }
    })

    const keys = Object.keys(groups)
    const sortedKeys = sortBy(keys)
    return fromPairs(map(sortedKeys, key => [key, groups[key]]))
  }

  renderSearchView (courses, searchTerm) {
    const { intl } = this.props
    const hashParamsString = getHashParamsString()

    return (
      <div>
        <span role='alert'>
          <b>{`${courses.length} `}</b>{' '}
          <FormattedMessage {...messages.resultsFound} />
        </span>
        <table className={styles.searchResults}>
          <caption className='hidden'>
            <FormattedMessage {...messages.courseSearchResults} />
          </caption>
          <thead>
            <tr>
              <th scope='col'>
                <FormattedMessage {...messages.course} />
              </th>
              <th scope='col'>
                <FormattedMessage {...messages.title} />
              </th>
              <th scope='col'>
                <FormattedMessage {...messages.subject} />
              </th>
            </tr>
          </thead>
          <tbody>
            {map(courses, (c, i, { length }) => (
              <tr key={c.id}>
                <th scope='row'>
                  <BreadcrumbLink
                    aria-label={`${get(c, '__catalogCourseId')} ${get(
                      c,
                      'title'
                    )}`}
                    current={get(c, 'title', '')}
                    itemType='Courses'
                    to={`courses/${c.pid}${hashParamsString}`}
                  >
                    {get(c, '__catalogCourseId')}
                  </BreadcrumbLink>
                </th>
                <td>{get(c, 'title')}</td>
                <td>
                  {intl.locale === 'en'
                    ? get(c, 'subjectCode.description')
                    : get(
                      c,
                      `subjectCode.translatedNames.${intl.locale}`,
                      get(c, 'subjectCode.description')
                    )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    )
  }

  renderGroupsView (courseTitleExtension) {
    const { catalog, schemas } = this.props
    const {
      expanded,
      groups,
      selectedFilterOptions,
      singleGroup: group
    } = this.state

    const hashParams = getHashParams()
    return (
      <div className={styles.groups}>
        {!isUndefined(group) && (
          <CollapsibleBox key={group} title={group} titleComponent='h2'>
            <ul className={styles.withDivider}>
              {map(groups[group], (c, i) => (
                <li className={styles.item} key={c.id}>
                  <ItemContents
                    catalogSettings={get(catalog, 'settings')}
                    extension={c[courseTitleExtension]}
                    group={group}
                    item={c}
                    itemType='courses'
                    pid={c.pid}
                    schemas={schemas}
                    title={`${get(c, '__catalogCourseId')} - ${get(
                      c,
                      'title',
                      ''
                    )}`}
                  />
                </li>
              ))}
            </ul>
          </CollapsibleBox>
        )}
        {isUndefined(group) && (
          <ul>
            {map(groups, (courses, curSubjectCode) => (
              <li key={curSubjectCode}>
                <CollapsibleBox
                  expandable
                  id={curSubjectCode}
                  link={
                    window.location.href.split('?')[0] +
                    `?${qs.stringify(
                      Object.assign({}, hashParams, { group: curSubjectCode })
                    )}`
                  }
                  startExpanded={
                    curSubjectCode === expanded ||
                    selectedFilterOptions.length > 0
                  }
                  title={curSubjectCode}
                  titleComponent='h2'
                >
                  <ul className={styles.withDivider}>
                    {map(courses, (c, i, { length }) => (
                      <li className={styles.item} key={i}>
                        <ItemContents
                          catalogSettings={get(catalog, 'settings')}
                          extension={c[courseTitleExtension]}
                          group={curSubjectCode}
                          item={c}
                          itemType='courses'
                          pid={c.pid}
                          schemas={schemas}
                          title={`${get(c, '__catalogCourseId')} - ${get(
                            c,
                            'title',
                            ''
                          )}`}
                        />
                      </li>
                    ))}
                  </ul>
                </CollapsibleBox>
              </li>
            ))}
          </ul>
        )}
      </div>
    )
  }

  render () {
    const { isPrint } = this.context
    const { catalog, intl, schemas } = this.props
    const { courses, filterValuesMap, loading, searchTerm } = this.state
    const {
      filters: courseFilters,
      titleExtension: courseTitleExtension
    } = get(catalog, 'settings.courses', {})

    return (
      <div className={styles.wrapper}>
        <DocumentTitle
          title={intl.formatMessage(messages.viewCourses, {
            courses: replace('courses', true, intl.locale)
          })}
        />
        <h1 className='hidden'>
          {intl.formatMessage(messages.coursesList, {
            courses: replace('courses', false, intl.locale)
          })}
        </h1>
        <div className={styles.filters}>
          {map(courseFilters, filter => {
            const filterLabel = getFilterLabel(filter, intl, schemas.courses)
            const filterValues = filterValuesMap[filter] || []
            return (
              <SelectField
                className={styles.selectFilter}
                fullWidth
                floatingLabelText={filterLabel}
                id={filterLabel}
                key={filterLabel}
                menuItems={[...filterValues]}
                name={filterLabel}
                onChange={(val, i) => {
                  const label = filterValues[i].label
                  this.setFilter(filter, val, label)
                }}
                style={{ float: 'right' }}
                value={''}
              />
            )
          })}
        </div>
        {this.renderFilterChips()}
        <TopLevelPanel>
          <h2 className={styles.hidden}>Courses</h2>
          {loading && (
            <div className={styles.progressWrapper}>
              <div className='hidden' role='alert'>
                <FormattedMessage {...messages.loadingCourses} />
              </div>
              {isPrint && 'Loading Print View'}
              <CircularProgress />
            </div>
          )}
          {!loading &&
            !searchTerm &&
            this.renderGroupsView(courseTitleExtension)}
          {!loading &&
            searchTerm &&
            courses &&
            courses.length &&
            this.renderSearchView(courses, searchTerm)}
          {!loading && courses && !courses.length && searchTerm && (
            <div role='alert'>
              {intl.formatMessage(messages.noCourseResults, {
                course: replace('course', false, intl.locale)
              })}
            </div>
          )}
        </TopLevelPanel>
      </div>
    )
  }
}

export default injectIntl(CourseList)
