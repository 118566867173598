import React from 'react'
import { defineMessages } from 'react-intl'
import { Link } from 'react-router'
import { ArrowBack } from '../../../../../client-common/components/icon'
import styles from './style.css'
import replace from '../../../../../client-common/lib/ui-text-replace'

export default function HomeLink ({ intl }) {
  const messages = defineMessages({
    catalogHome: {
      id: 'home-link.catalog-home',
      defaultMessage: `${replace('catalog', true)} Home`
    }
  })
  return (
    <Link className={styles.container} to='/home'>
      <ArrowBack className={`linkColor ${styles.arrow}`} />{' '}
      {intl.formatMessage(messages.catalogHome)}
    </Link>
  )
}
