import { get } from 'lodash'
import PropTypes from 'prop-types'
import React from 'react'

import BreadcrumbLink from '../../../../../client-common/components/breadcrumbs-link'
import replace from '../../../../../client-common/lib/ui-text-replace'
import styles from './style.css'
import SearchDescription from '../search-description'
import { getHashParamsString } from '../../../../../client-common/lib/translation/translation'

export default function SearchResult ({ item, searchTerm, intl }) {
  const title = []

  if (get(item, 'code') && get(item, 'type') === 'courses') {
    title.push(<span className={styles.itemCode}>{get(item, 'code')}</span>)
  }

  title.push(get(item, 'title'))
  const itemType = get(item, 'type', '')

  return (
    <div>
      {item.type && (
        <div className={styles.type}>
          {replace(item.type, true, intl.locale)}{' '}
          {item.groupFilter1 && `/ ${item.groupFilter1.name}`}
        </div>
      )}
      <div className={styles.searchResultLink}>
        <BreadcrumbLink
          aria-label={title}
          current={title}
          itemType={itemType === 'specializations' ? 'programs' : itemType}
          to={itemPath(item)}
        >
          {title}
        </BreadcrumbLink>
      </div>
      <SearchDescription item={item} searchTerm={searchTerm} />
      <hr className={styles.searchSeparator} />
    </div>
  )
}

export function itemPath (item) {
  const itemType = get(item, 'type', '')
  if (itemType === 'specializations') {
    return `programs/${get(item, 'inheritedFrom')}/${get(
      item,
      'pid'
    )}${getHashParamsString()}`
  }
  if (itemType === 'institutional-information') {
    return `content/${get(item, 'id')}${getHashParamsString()}`
  }
  const prefix = itemType === 'policies' ? 'policy' : itemType
  return `${prefix}/${get(item, 'pid')}${getHashParamsString()}`
}

SearchResult.displayName = 'SearchResult'

SearchResult.propTypes = {
  intl: PropTypes.object,
  item: PropTypes.shape({
    __catalogCourseId: PropTypes.string,
    group: PropTypes.string,
    pid: PropTypes.string,
    title: PropTypes.string,
    type: PropTypes.string
  }),
  searchTerm: PropTypes.string
}
