/* Copyright © 2016 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */

import { get, includes } from 'lodash'

export const isSuperAdmin = user =>
  get(user, 'username', 'notkualiadmin') === 'kualiadmin'

export const isCMAdmin = user =>
  includes(['admin', 'cmadmin'], get(user, 'role', 'user'))
