/* Copyright © 2016 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */

/* eslint consistent-return: 0 */
import {
  compact,
  each,
  filter,
  fromPairs,
  get,
  groupBy,
  isEmpty,
  toLower,
  map,
  sortBy,
  uniq
} from 'lodash'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import DocumentTitle from 'react-document-title'
import { scroller } from 'react-scroll'
import qs from 'querystring'

import styles from './style'
import BreadcrumbLink from '../../../../client-common/components/breadcrumbs-link'
import CircularProgress from '../../../../client-common/components/circular-progress'
import CollapsibleBox from '../../../../common/collapsible-box'
import Divider from '../../../../client-common/components/divider'
import TopLevelPanel from '../../../../client-common/components/top-level-panel'
import { page } from '../../../../client-common/lib/analytics'
import { getCachedEndpoint } from '../../../../client-common/lib/cacher'
import { PublicCatalogType } from '../../../../client-common/lib/types'
import replace from '../../../../client-common/lib/ui-text-replace'
import { printIfNeeded } from '../printing'

export default class ExperienceList extends Component {
  static displayName = 'ExperienceList'

  static propTypes = {
    catalog: PublicCatalogType,
    mq: PropTypes.string
  }

  static contextTypes = {
    isPrint: PropTypes.bool
  }

  constructor (props) {
    super(props)
    this.state = {
      curFilter: null,
      didPrint: false,
      filterLabel: null,
      filterValues: [],
      latestSearchTerm: '',
      loading: true,
      experiences: null
    }
    page('Experiences')
    this.fetchExperiences('')
    const { href } = window.location
    const { group } = qs.parse(href.split('?')[1])
    if (group) {
      this.state.singleGroup = group
    }
    this.printIfNeeded = printIfNeeded.bind(this)
  }

  componentDidUpdate () {
    const { href } = window.location
    const { group } = qs.parse(href.split('?')[1])
    if (group !== this.state.singleGroup) {
      this.setState({ singleGroup: group })
    }

    this.printIfNeeded(this.state.experiences)
  }

  componentDidMount () {
    this._isMounted = true
  }

  componentWillUnmount () {
    this._isMounted = false
  }

  async fetchExperiences (searchTerm) {
    this.setState({ latestSearchTerm: searchTerm, loading: true })

    const { catalog } = this.props
    const query = qs.stringify({ q: searchTerm })

    const experienceEndpoint = `/experiences/${catalog._id}?${query}`
    const experiences = await getCachedEndpoint(experienceEndpoint)

    if (this.state.latestSearchTerm !== searchTerm) {
      return
    }

    const filteredExperiences = this.filterExperiences(experiences)
    const groups = isEmpty(searchTerm)
      ? this.groupByAndSort(filteredExperiences)
      : {}

    const { expanded } = qs.parse(window.location.href.split('?')[1])
    if (this._isMounted) {
      this.setState(
        {
          loading: false,
          filteredExperiences,
          experiences,
          groups,
          searchTerm,
          expanded
        },
        () => this.getFilterValues(experiences)
      )
      if (expanded) {
        scroller.scrollTo(expanded, {
          duration: 500,
          delay: 250,
          smooth: true
        })
      }
    }
  }

  async getFilterValues (experiences) {
    const { catalog, schemas } = this.props
    this.setState({ loading: false })
    const experienceFilter = get(catalog, 'settings.experiences.filter')
    if (!experienceFilter) return

    const filterLabel = get(schemas.experiences, `${experienceFilter}.label`)
    const filterValues = sortBy(
      compact(uniq(map(experiences, p => get(p, `${experienceFilter}.name`))))
    )

    this.setState({ filterValues, filterLabel })
  }

  groupByAndSort = items => {
    const {
      catalog: { settings }
    } = this.props
    const category = get(settings, 'experiences.category')
    if (!category || !category.trim()) return {}

    const groups = groupBy(items, `${category}.name`)
    each(groups, (group, id) => {
      group = group.sort((a, b) =>
        toLower(a.title) > toLower(b.title) ? 1 : -1
      )
    })
    const keys = Object.keys(groups)
    const sortedKeys = sortBy(keys)

    return fromPairs(map(sortedKeys, key => [key, groups[key]]))
  }

  buildExperienceTitle = (pid, title, extension, group) => {
    let compiledTitle = title
    if (extension && !isEmpty(extension)) {
      const addition = get(extension, 'name', extension)
      compiledTitle = `${title} (${addition})`
    }
    return (
      <h3>
        <BreadcrumbLink
          current={title}
          group={group}
          itemType='experiences'
          to={`experiences/${pid}`}
        >
          {compiledTitle}
        </BreadcrumbLink>
      </h3>
    )
  }

  setFilter = newFilter => {
    const { experiences } = this.state
    const filterValue = newFilter === '' ? null : newFilter
    this.setState({ curFilter: filterValue }, () => {
      const filteredExperiences = this.filterPrograms(experiences)
      const groups = this.groupByAndSort(filteredExperiences)
      this.setState({ filteredExperiences, groups })
    })
  }

  filterExperiences = experiences => {
    const {
      catalog: {
        settings: {
          experiences: { filter: experienceFilter }
        }
      }
    } = this.props
    const { curFilter } = this.state
    return filter(experiences, p => {
      return curFilter ? get(p, `${experienceFilter}.name`) === curFilter : true
    })
  }

  renderGroupsView (experienceTitleExtension) {
    const { singleGroup, groups, curFilter } = this.state
    if (singleGroup) {
      return (
        <CollapsibleBox
          key={singleGroup}
          title={singleGroup}
          titleComponent='h2'
        >
          <ul>
            {map(groups[singleGroup], (p, i, { length }) => (
              <li className={styles.item} key={i}>
                {this.buildExperienceTitle(
                  p.pid,
                  p.title,
                  p[experienceTitleExtension],
                  singleGroup
                )}
                <ul>
                  {map(p.specializations, (s, i) => (
                    <li className={styles.item} key={i}>
                      <h3>
                        <BreadcrumbLink
                          current={s.title}
                          itemType='experiences'
                          to={`experiences/${p.pid}/${s.pid}`}
                        >
                          - {s.title}
                        </BreadcrumbLink>
                      </h3>
                    </li>
                  ))}
                </ul>
                {i < length - 1 && <Divider className={styles.divider} />}
              </li>
            ))}
          </ul>
        </CollapsibleBox>
      )
    } else {
      return (
        <ul>
          {map(groups, (experienceGroup, groupTitle) => {
            if (groupTitle === 'undefined') return
            return (
              <li key={groupTitle}>
                <CollapsibleBox
                  expandable={!curFilter}
                  id={groupTitle}
                  key={groupTitle}
                  link={
                    window.location.href.split('?')[0] +
                    `?${qs.stringify({ group: groupTitle })}`
                  }
                  startExpanded={this.state.expanded === groupTitle}
                  title={groupTitle}
                  titleComponent='h2'
                >
                  <ul>
                    {map(experienceGroup, (p, i, { length }) => (
                      <li className={styles.item} key={i}>
                        {this.buildExperienceTitle(
                          p.pid,
                          p.title,
                          p[experienceTitleExtension],
                          groupTitle
                        )}
                        {p.specializations && (
                          <div
                            tabIndex={0}
                            aria-label={`${p.title} ${replace(
                              'specializations'
                            )}`}
                          >
                            <ul>
                              {map(p.specializations, (s, i) => (
                                <li className={styles.item} key={i}>
                                  <h3>
                                    <BreadcrumbLink
                                      current={s.title}
                                      group={groupTitle}
                                      itemType='experiences'
                                      to={`experiences/${p.pid}/${s.pid}`}
                                    >
                                      - {s.title}
                                    </BreadcrumbLink>
                                  </h3>
                                </li>
                              ))}
                            </ul>
                          </div>
                        )}
                        {i < length - 1 && (
                          <Divider className={styles.divider} />
                        )}
                      </li>
                    ))}
                  </ul>
                </CollapsibleBox>
              </li>
            )
          })}
        </ul>
      )
    }
  }

  renderSearchView (
    filteredExperiences,
    curFilter,
    experienceCategory,
    catalog,
    experienceTitleExtension
  ) {
    const {
      schemas: { experiences: experienceSchema }
    } = this.props
    return (
      <div>
        <span role='alert'>
          <b>{`${filteredExperiences.length} ${curFilter || ''} `}</b>
          results found
        </span>
        {!experienceCategory && (
          <div className={styles.resultHeader}>Title</div>
        )}
        {experienceCategory && (
          <div className={`${styles.twoColumns} ${styles.resultHeader}`}>
            <span className={styles.left}>Title</span>
            <span className={styles.right}>
              {`${experienceSchema[experienceCategory].label}`}
            </span>
          </div>
        )}
        {map(filteredExperiences, (p, i, { length }) => (
          <div key={i}>
            <div className={experienceCategory ? styles.twoColumns : ''}>
              <div
                className={`${styles.item} ${
                  experienceCategory ? styles.left : ''
                }`}
              >
                {this.buildExperienceTitle(
                  p.pid,
                  p.title,
                  p[experienceTitleExtension]
                )}
                {map(p.specializations, (s, i) => (
                  <div className={styles.item} key={i}>
                    <h3>
                      <BreadcrumbLink
                        current={p.title}
                        itemType='experiences'
                        to={`experiences/${p.pid}/${s.pid}`}
                      >
                        - {s.title}
                      </BreadcrumbLink>
                    </h3>
                  </div>
                ))}
              </div>
              {experienceCategory && (
                <div className={styles.right}>
                  {get(p, `${experienceCategory}.name`)}
                </div>
              )}
            </div>
            {i < length - 1 && <Divider className={styles.divider} />}
          </div>
        ))}
      </div>
    )
  }

  renderUngroupedView (filteredExperiences, experienceTitleExtension) {
    return (
      <ul>
        {map(filteredExperiences, (p, i, { length }) => (
          <li className={styles.item} key={i}>
            {this.buildExperienceTitle(
              p.pid,
              p.title,
              p[experienceTitleExtension]
            )}
            {map(p.specializations, (s, i) => (
              <div className={styles.item} key={i}>
                <h3>
                  <BreadcrumbLink
                    current={p.title}
                    itemType='experiences'
                    to={`experiences/${p.pid}/${s.pid}`}
                  >
                    - {s.title}
                  </BreadcrumbLink>
                </h3>
              </div>
            ))}
            {i < length - 1 && <Divider className={styles.divider} />}
          </li>
        ))}
      </ul>
    )
  }

  render () {
    const { isPrint } = this.context
    const {
      curFilter,
      filteredExperiences,
      groups,
      loading,
      searchTerm
    } = this.state
    const { catalog } = this.props
    const {
      experiences: {
        category: experienceCategory,
        titleExtension: experienceTitleExtension
      }
    } = catalog.settings

    return (
      <div className={styles.wrapper}>
        <DocumentTitle title={`View ${replace('Experiences', true)}`} />
        <h1 className='hidden'>{replace('Experiences')} List</h1>
        <TopLevelPanel>
          {!loading && !!filteredExperiences.length && (
            <div className={styles.itemContainer}>
              <h2 className={styles.hidden}>Experiences</h2>
              {!isEmpty(groups) &&
                !searchTerm &&
                this.renderGroupsView(experienceTitleExtension)}
              {isEmpty(groups) &&
                !searchTerm &&
                this.renderUngroupedView(
                  filteredExperiences,
                  experienceTitleExtension
                )}
              {searchTerm &&
                filteredExperiences.length &&
                this.renderSearchView(
                  filteredExperiences,
                  curFilter,
                  experienceCategory,
                  catalog,
                  experienceTitleExtension
                )}
            </div>
          )}
          {loading && (
            <div className={styles.progressWrapper}>
              <div className='hidden' role='alert'>
                Loading Experiences
              </div>
              {isPrint && 'Loading Print View'}
              <CircularProgress />
            </div>
          )}
          {!loading && !filteredExperiences.length && searchTerm && (
            <div role='alert'>{`No ${replace('experience')} results`}</div>
          )}
        </TopLevelPanel>
      </div>
    )
  }
}
