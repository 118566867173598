/* Copyright © 2016 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */

import ReactMDSelectField from 'react-md/lib/SelectFields'
import PropTypes from 'prop-types'
import React from 'react'

const SelectField = props => {
  const { floatingLabelText, ...rest } = props
  return (
    <ReactMDSelectField
      aria-label={props.floatingLabelText || props.label}
      id={props.floatingLabelText}
      label={props.floatingLabelText}
      placeholder={props.floatingLabelText}
      sameWidth
      {...rest}
    />
  )
}

SelectField.displayName = 'SelectField'
SelectField.propTypes = {
  floatingLabelText: PropTypes.node
}

export default SelectField
export const Positions = ReactMDSelectField.Positions
