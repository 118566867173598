import classNames from 'classnames'
import { isEmpty } from 'lodash'
import React from 'react'
import BreadcrumbsLink from '../../../../client-common/components/breadcrumbs-link/index'
import { getExtensionValue } from '../../../../common/extensions/helpers'
import { injectIntl } from 'react-intl'
import styles from './style'
import { getHashParamsString } from '../../../../client-common/lib/translation/translation'

function ItemContents ({
  catalogSettings,
  children,
  extension,
  group,
  indented,
  intl,
  item,
  itemType,
  pid,
  schemas,
  title
}) {
  let addition = null
  if (extension && !isEmpty(extension)) {
    const text = getExtensionValue(catalogSettings, schemas, item, itemType)
    addition = (
      <span
        className={classNames({
          [styles.extension]: true,
          [styles.indentedExtension]: indented
        })}
      >
        {text}
      </span>
    )
  } else {
    // using empty span here to make sure the search view for programs renders correctly
    addition = <span className={styles.extension} />
  }
  const linkStyle = indented ? styles.indented : ''
  const hashParamString = getHashParamsString()
  return (
    <div className={styles.columns}>
      <h3>
        <div className={linkStyle}>
          <BreadcrumbsLink
            current={title}
            group={group}
            itemType={itemType}
            to={`${itemType}/${pid}${hashParamString}`}
          >
            {indented ? '- ' : null}
            {title}
          </BreadcrumbsLink>
        </div>
      </h3>
      {addition}
      {children}
    </div>
  )
}

export default injectIntl(ItemContents)
