/* Copyright © 2016 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */

import { capitalize, get, includes, map } from 'lodash'
import moment from 'moment'
import PropTypes from 'prop-types'
import React from 'react'

import style from './style'
import CollapsibleBox from '../../../common/collapsible-box'
import replace from '../../lib/ui-text-replace'
import ItemDetails, { renderData } from '../../../common/item-details'
import { renderPolicy } from '../../../client-student/app/policy'
import { injectIntl, defineMessages } from 'react-intl'
import { getExtensionValue } from '../../../common/extensions/helpers'

const messages = defineMessages({
  new: {
    id: 'addendum-view.new',
    defaultMessage: 'NEW'
  },
  old: {
    id: 'addendum-view.old',
    defaultMessage: 'OLD'
  },
  added: {
    id: 'addendum-view.added',
    defaultMessage: 'ADDED'
  },
  removed: {
    id: 'addendum-view.removed',
    defaultMessage: 'REMOVED'
  }
})

const AddendumUpdateView = (addendum, settings, schemas, intl) => {
  const { changedKeys, itemType, newVersion, oldVersion } = addendum
  const schema = schemas[itemType]

  return map(changedKeys, key => (
    <div key={key} className={style.addendaWrapper}>
      {itemType !== 'policies' && (
        <div>
          <h3 className={style.addendaHeader}>
            {getKeyLabel(
              schema,
              get(settings, `${itemType}.replacements`, {}),
              itemType,
              key
            )}
          </h3>
          <hr />
        </div>
      )}
      {key in newVersion && (
        <div>
          <div className={style.box}>
            <div className={style.green}>
              {key in oldVersion
                ? intl.formatMessage(messages.new)
                : intl.formatMessage(messages.added)}
            </div>
          </div>
          <div>{renderGadgetData(newVersion, itemType, schema, key, intl)}</div>
        </div>
      )}
      {key in oldVersion && (
        <div>
          <div className={style.box}>
            <div className={style.red}>
              {key in newVersion
                ? intl.formatMessage(messages.old)
                : intl.formatMessage(messages.removed)}
            </div>
          </div>
          <div>{renderGadgetData(oldVersion, itemType, schema, key, intl)}</div>
        </div>
      )}
    </div>
  ))
}

const AddendumAdditionView = (addendum, settings, schemas, intl) => {
  const { item, itemType, title } = addendum
  const keys = get(settings, `${itemType}.keys`)

  let body = ''
  let titleExtension = null
  if (includes(['programs', 'courses', 'specializations'], itemType)) {
    titleExtension = getExtensionValue(settings, schemas, item, itemType)
  }
  if (itemType === 'policies') body = renderPolicy(item)
  else {
    body = (
      <div>
        <h3>
          {title}
          {titleExtension ? ` - ${titleExtension}` : ''}
        </h3>
        <ItemDetails
          dataClassName={style.pre}
          displayOptions={get(settings, `${itemType}.displayOptions`, {})}
          item={item}
          itemType={itemType}
          keys={keys}
          labelClassName={style.label}
          schemas={schemas}
          replacements={get(settings, `${itemType}.replacements`, {})}
        />
      </div>
    )
  }

  return <div className={style.item}>{body}</div>
}

const AddendumView = props => {
  const { addendum, settings, schemas, intl } = props
  const { item, itemType, newVersion, title, type, date, addendaDate } = addendum
  const typeLabel = intl.formatMessage({ id: type, defaultMessage: type })
  const itemTypeLabel = intl.formatMessage({
    id: [`${itemType}.${itemType}`],
    defaultMessage: itemType
  })
  const renderer = addendaTypeMap[type]
  const addenda = renderer(addendum, settings, schemas, intl)
  const _title = `${replace(itemTypeLabel, true, intl.locale)}: ${title}`
  const _date = addendaDate || date || (item || newVersion).catalogActivationDate
  const jsDate = new Date(_date)
  let displayDate
  if (!isNaN(jsDate.valueOf())) {
    const ISODate = new Date(_date).toISOString().slice(0, 10)
    displayDate = moment(ISODate).format('MM/D/YYYY')
  } else {
    displayDate = moment(_date).format('MM/D/YYYY')
  }
  return (
    <div className={style.cardWrapper}>
      <CollapsibleBox
        capitalize
        expandable
        id={_title}
        label={`${capitalize(replace(typeLabel))}`}
        subtitle={displayDate}
        title={_title}
      >
        <div>{addenda}</div>
      </CollapsibleBox>
    </div>
  )
}

const getKeyLabel = (schema, replacements, type, key) => {
  return get(replacements, key, get(schema, `${key}.label`))
}

const renderGadgetData = (version, type, schema, key, intl) => {
  const gType = get(schema, `${key}.type`)
  const gadgetLabel = get(schema, `${key}.label`)
  const gData = get(version, key)
  const selfRenderProps = {
    dataClassName: '',
    featureFlags: {},
    gadgetLabel,
    labelClassName: ''
  }
  const [renderedData] = renderData(
    gType,
    gData,
    intl,
    type,
    schema,
    key,
    selfRenderProps
  )
  return renderedData
}

const addendaTypeMap = {
  new: AddendumAdditionView,
  retired: AddendumAdditionView,
  updated: AddendumUpdateView,
  removed: AddendumAdditionView
}

AddendumView.displayName = 'AddendumView'
AddendumView.propTypes = {
  addendum: PropTypes.object,
  mq: PropTypes.string,
  settings: PropTypes.object,
  schemas: PropTypes.object
}

export default injectIntl(AddendumView)
