/* Copyright © 2016 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */

import { Link } from 'react-router'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { get } from 'lodash'

export default class TypeaheadView extends Component {
  static displayName = 'TypeaheadView'

  static propTypes = {
    data: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
    type: PropTypes.string.isRequired,
    locale: PropTypes.string
  }

  constructor (props) {
    super(props)
    this.state = {
      denormalizedData: props.data && props.data.name
    }
  }

  render () {
    const { type, data, locale } = this.props
    const { denormalizedData } = this.state
    if (type === 'ProgramTypeahead' && data) {
      return <Link to={`/programs/${data.pid}`}>{(data && data.title) || '--'}</Link>
    } else if (locale !== 'en') {
      const translatedData = get(data, `translatedNames.${locale}`)
      if (translatedData) {
        return <div>{translatedData}</div>
      }
    }
    return <div>{denormalizedData}</div>
  }
}
