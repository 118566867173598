import React, { Component } from 'react'
import PropTypes from 'prop-types'

import styles from './style'

export class NestedNavItem extends Component {
  constructor (props) {
    super(props)

    this.state = {
      isHovered: false
    }
  }

  onMouseEnter = () => {
    this.setState({ isHovered: true })
  }

  onMouseLeave = () => {
    this.setState({ isHovered: false })
  }

  onKeyPress = e => {
    if (e.key === 'Enter') {
      this.onClick()
    }
  }

  onClick = () => {
    const { route, external, onClick, closeDrawer } = this.props
    if (closeDrawer) {
      closeDrawer()
    }
    onClick(external, route)
  }

  render () {
    const { primaryText, isSelected } = this.props
    let classNames = `${styles.nestedNavItem} nestedNavItem`
    if (isSelected) {
      classNames += ' selectedNestedItem'
    }
    return (
      <li
        aria-label={primaryText}
        role={'link'}
        tabIndex={0}
        className={classNames}
        onMouseEnter={this.onMouseEnter}
        onMouseLeave={this.onMouseLeave}
        onClick={this.onClick}
        onKeyPress={this.onKeyPress}
      >
        <div className={styles.labelWrapper}>{primaryText}</div>
      </li>
    )
  }
}

NestedNavItem.propTypes = {
  closeDrawer: PropTypes.func,
  isExternal: PropTypes.bool,
  isSelected: PropTypes.bool,
  primaryText: PropTypes.string,
  route: PropTypes.string
}

export default NestedNavItem
