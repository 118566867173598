/* Copyright © 2016 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */

import PropTypes from 'prop-types'
import React from 'react'
import { each } from 'lodash'

const CREDIT_TYPES = {
  FIXED: 'fixed',
  MULTIPLE: 'multiple',
  RANGE: 'range'
}

const getMultiCreditString = values => {
  let multiCreditString = ''
  each(values, (value, index) => {
    if (!value) {
      value = '--'
    }
    if (index === 0) {
      multiCreditString += value
    } else if (index < value.length - 1) {
      multiCreditString += ', ' + value
    } else {
      multiCreditString += ' or ' + value
    }
  })
  return multiCreditString
}

const CreditsView = ({ data }) => {
  return (
    <div>
      {data.chosen === CREDIT_TYPES.FIXED && <div>{data.credits.min}</div>}
      {data.chosen === CREDIT_TYPES.MULTIPLE && (
        <div>{getMultiCreditString(data.value)}</div>
      )}
      {data.chosen === CREDIT_TYPES.RANGE && (
        <div>
          {data.credits.min} - {data.credits.max}
        </div>
      )}
    </div>
  )
}

CreditsView.displayName = 'CreditsView'
CreditsView.propTypes = {
  data: PropTypes.shape({
    chosen: PropTypes.string,
    credits: PropTypes.shape({
      max: PropTypes.string,
      min: PropTypes.string
    }),
    value: PropTypes.string
  })
}

export default CreditsView
