import { get, isEmpty, noop, size } from 'lodash'
import React, { useEffect, useRef, useState } from 'react'
import { defineMessages } from 'react-intl'
import PropTypes from 'prop-types'
import shortid from 'shortid'
import styles from './style'
import { CloseIcon } from '@kuali/wok-ui/lib/icons/close'
import { SearchIcon } from '@kuali/wok-ui/lib/icons/search'

const KEYS = {
  BACKSPACE: 8,
  DELETE: 46,
  DOWN: 40,
  ENTER: 13,
  ESC: 27,
  LEFT: 37,
  RIGHT: 39,
  SHIFT: 16,
  SPACE: 32,
  TAB: 9,
  UP: 38
}

const OPTION_HEIGHT = 35
const MAX_OPTIONS_SHOWING = 7
const MAX_MENU_HEIGHT = OPTION_HEIGHT * MAX_OPTIONS_SHOWING

export function Autocomplete ({
  container,
  intl,
  label,
  menuStyle,
  onEnterPressed,
  onOptionChosen,
  onSearchChange,
  options,
  placeholder,
  value
}) {
  const messages = defineMessages({
    noResults: {
      id: 'autocomplete.no-results',
      defaultMessage: 'No results'
    }
  })
  const [activeOption, setActiveOption] = useState()
  const [id] = useState(shortid.generate())
  const [menuShowing, setMenuShowing] = useState(false)
  const [openUp, setOpenUp] = useState(false)

  const optionRefs = {}
  const textBoxRef = useRef()
  const controlRef = useRef()

  function checkForClickOff (evt) {
    if (controlRef.current && !controlRef.current.contains(evt.target)) {
      setMenuShowing(false)
    }
  }

  useEffect(() => {
    document.addEventListener('click', checkForClickOff)
    return () => {
      document.removeEventListener('click', checkForClickOff)
    }
  })

  // Determines whether menu should appear above or below textbox
  function calculateUpOrDown () {
    if (!controlRef.current) return

    const {
      y: controlTop = 0,
      height: controlHeight
    } = controlRef.current.getBoundingClientRect()
    const bottomOfControl = controlTop + controlHeight

    setOpenUp(bottomOfControl > window.innerHeight - MAX_MENU_HEIGHT)
  }

  function showMenu () {
    calculateUpOrDown()
    setMenuShowing(true)
  }

  function onTextBoxChange (evt) {
    const newValue = evt.target.value

    onSearchChange(newValue)

    if (newValue.trim().length > 0) {
      showMenu()
    }
    setActiveOption(null)
  }

  function onTextBoxDownPressed () {
    if (options.length > 0) {
      showMenu()
      highlightOption(getOptionValue(get(options, [0], '')))
    }
  }

  function onTextBoxClicked () {
    if (options.length > 0) {
      showMenu()
    }
  }

  function onTextBoxUpPressed () {
    if (options.length > 0) {
      showMenu()
      highlightOption(getOptionValue(get(options, [options.length - 1], '')))
    }
  }

  function onTextBoxKeyDown (evt) {
    switch (evt.keyCode) {
      case KEYS.UP:
        onTextBoxUpPressed()
        evt.preventDefault()
        break
      case KEYS.DOWN:
        onTextBoxDownPressed()
        evt.preventDefault()
        break
      case KEYS.ESC:
      case KEYS.TAB:
        setMenuShowing(false)
        break
      case KEYS.ENTER:
        setMenuShowing(false)
        onEnterPressed()
        break
    }
  }

  function onOptionKeyDown (evt, option, index, arr) {
    switch (evt.keyCode) {
      case KEYS.SPACE:
      case KEYS.ENTER:
        selectOption(getOptionValue(option), 'ENTER')
        evt.preventDefault()
        break
      case KEYS.UP:
        if (index > 0) {
          highlightOption(getOptionValue(arr[index - 1]))
        } else {
          focusTextBox()
          setMenuShowing(false)
        }
        evt.preventDefault()
        break
      case KEYS.DOWN:
        if (index < arr.length - 1) {
          highlightOption(getOptionValue(arr[index + 1]))
        } else {
          focusTextBox()
          setMenuShowing(false)
          setActiveOption(null)
        }
        evt.preventDefault()
        break
      case KEYS.TAB:
        setMenuShowing(false)
        evt.preventDefault()
        break
      case KEYS.ESC:
        setMenuShowing(false)
        focusTextBox()
        break
      default:
        focusTextBox()
    }
  }

  function highlightOption (optionValue) {
    setActiveOption(optionValue)
    const optionRef = optionRefs[optionValue]
    if (optionRef) {
      optionRef.focus()
    }
  }

  function selectOption (optionValue, evtType) {
    setMenuShowing(false)
    const wasHandled = onOptionChosen(optionValue, evtType)
    if (wasHandled) return

    onSearchChange(optionValue)
    focusTextBox()
  }

  function focusTextBox () {
    if (textBoxRef) {
      textBoxRef.current.focus()
    }
  }

  return (
    <div className={styles.field} ref={controlRef}>
      {!isEmpty(label) && (
        <label htmlFor={`textbox${id}`}>
          <span id={`textbox${id}_label`} className={styles.fieldLabel}>
            {label}
          </span>
        </label>
      )}

      <select
        aria-label={!isEmpty(label) ? label : placeholder}
        onChange={noop}
        style={{ display: 'none' }}
        value={value}
      >
        {options.map((option, index) => {
          return (
            <option key={`${option}${index}`} value={option}>
              option
            </option>
          )
        })}
      </select>

      <div className={styles.autocomplete}>
        <input
          aria-autocomplete='list'
          aria-controls={`list${id}`}
          aria-expanded={menuShowing}
          aria-owns={`list${id}`}
          autoCapitalize='none'
          autoComplete='off'
          id={`textbox${id}`}
          onChange={onTextBoxChange}
          onClick={onTextBoxClicked}
          onKeyDown={onTextBoxKeyDown}
          placeholder={placeholder}
          ref={textBoxRef}
          role='combobox'
          type='text'
          value={value}
        />

        {get(value, 'length', 0) > 0 && (
          <button
            alt='Clear search'
            aria-label='Clear search'
            className={styles.clearSearch}
            onClick={evt => {
              if (get(value, 'length', 0) > 0) {
                onTextBoxChange({ target: { value: '' } })
                focusTextBox()
                evt.stopPropagation()
              }
            }}
          >
            <CloseIcon
              size='small'
              style={{ verticalAlign: 'top' }}
              ui='light'
            />
          </button>
        )}
        <span className={styles.magnifyingGlass} role='presentation'>
          <SearchIcon
            size='medium'
            style={{ verticalAlign: 'top' }}
            ui='light'
          />
        </span>

        <ul
          className={`${menuShowing && size(value) > 0 ? '' : styles.hidden} ${
            openUp ? styles.openUp : ''
          }`}
          id={`list${id}`}
          role='listbox'
          style={{
            transform: openUp ? `translateY(calc(-100% - 40px))` : '',
            ...menuStyle
          }}
        >
          {options.length === 0 && (
            <li className={styles.autocompleteOptionNoResults}>
              {intl.formatMessage(messages.noResults)}
            </li>
          )}
          {options.length > 0 &&
            options.map((option, index, arr) => {
              return (
                <li
                  aria-selected={activeOption === getOptionValue(option)}
                  id={`autocomplete_${index}`}
                  key={`${getOptionValue(option)}${index}`}
                  onClick={() => {
                    selectOption(getOptionValue(option), 'CLICK')
                  }}
                  onKeyDown={evt => {
                    onOptionKeyDown(evt, option, index, arr)
                  }}
                  ref={ref => {
                    if (ref) {
                      optionRefs[getOptionValue(option)] = ref
                    }
                  }}
                  role='option'
                  tabIndex='-1'
                >
                  {getOptionLabel(option)}
                </li>
              )
            })}
        </ul>

        <div aria-live='polite' role='status' className={styles.visuallyHidden}>
          {options.length} results available
        </div>
      </div>
    </div>
  )
}

Autocomplete.defaultProps = {
  container: document.body,
  label: '',
  menuStyle: {},
  onEnterPressed: noop,
  onOptionChosen: noop,
  onSearchChange: noop,
  options: [],
  placeholder: '',
  value: null
}

Autocomplete.propTypes = {
  container: PropTypes.object,
  label: PropTypes.string,
  menuStyle: PropTypes.object,
  onEnterPressed: PropTypes.func,
  onOptionChosen: PropTypes.func,
  onSearchChange: PropTypes.func,
  options: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.shape({
        value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        label: PropTypes.oneOfType([
          PropTypes.string,
          PropTypes.number,
          PropTypes.node
        ])
      })
    ])
  ),
  placeholder: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
    PropTypes.number
  ])
}

export function getOptionValue (option) {
  if (option.value) {
    return option.value
  } else return option
}

export function getOptionLabel (option) {
  if (option.value) {
    return option.label
  } else return option
}
