/* Copyright © 2016 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */

import axios from 'axios'
import { get } from 'lodash'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import {
  applyRouterMiddleware,
  IndexRoute,
  Router,
  Route,
  Redirect
} from 'react-router'
import useScroll from 'react-router-scroll/lib/useScroll'
import { Element } from 'react-scroll'

import CircularProgress from './../../client-common/components/circular-progress'
import MediaQueryify from './../../client-common/components/media-query'
import { page } from './../../client-common/lib/analytics'
import Themer from './../../client-common/components/themer'
import ContentSet from './content-set'
import ContentItem from './content-item'
import Home from './home'
import updateSubdomain from './../../client-common/lib/axios'
import { loadFeatureFlags } from '../../common/lib/feature-flags'
import { loadReplacements } from '../../client-common/lib/ui-text-replace'
import Addenda from './pages/addenda'
import Courses from './pages/courses'
import CourseView from './pages/courses/course-view'
import Experiences from './pages/experiences'
import ExperienceView from './pages/experiences/experience-view'
import Main from './pages/main'
import NotFound from './pages/not-found'
import Maintenance from './pages/maintenance'
import Policies from './pages/policies'
import Programs from './pages/programs'
import ProgramView from './pages/programs/program-view'
import Search from './pages/search'
import Policy from './policy'
import { IntlProvider, addLocaleData } from 'react-intl'
import * as messagesES from './../../i18n/translations/locales/es.json'
import es from 'react-intl/locale-data/es'

addLocaleData([...es])

const messages = {
  en: null,
  es: messagesES
}

updateSubdomain(window.subdomain)

export default class App extends Component {
  static displayName = 'App'

  static propTypes = {
    history: PropTypes.object,
    user: PropTypes.shape({
      name: PropTypes.string
    })
  }

  constructor (props) {
    super(props)
    this.loadReplacements()
    this.state = {
      curPath: window.location.hash.slice(1).split('?')[0],
      featureFlags: {},
      theme: null,
      locale: 'en',
      catalogId: null
    }
    page(
      'Initial Load',
      window.location,
      window.subdomain.split('//')[1].split('.')[0]
    )
    this.fetchTheme()
    this.shouldScroll = this.shouldScroll.bind(this)
    this.handleLanguageChange = this.handleLanguageChange.bind(this)
    this.loadFeatureFlags()
  }

  async fetchTheme () {
    try {
      const {
        data: { theme }
      } = await axios.get('/systemsettings/theme')
      this.setState({ theme })
    } catch (e) {
      this.setState({ theme: {} })
    }
  }

  async loadReplacements () {
    await loadReplacements()
    this.setState({ replacementsReady: true })
  }

  async loadFeatureFlags () {
    const featureFlags = await loadFeatureFlags()
    this.setState({ featureFlags, flagsReady: true })
  }

  shouldScroll (lastProps, { location }) {
    // This gets called with a PUSH and a POP event for every navigation change, so this just ignores one of the events
    if (location.action === 'PUSH' || location.pathname === '/home') {
      return false
    }
    const { curPath } = this.state
    const isNewPath = location.pathname.toLowerCase() !== curPath.toLowerCase()
    const isProgramView =
      location.pathname.toLowerCase().indexOf('programs') !== -1 &&
      location.pathname.split('/').length > 3
    const shouldResetScroll = isNewPath && !isProgramView
    if (shouldResetScroll) {
      this.setState({ curPath: location.pathname })
      return 'top'
    }
    return false
  }

  handleLanguageChange (locale, catalogId) {
    this.setState({
      locale: locale,
      catalogId: catalogId
    })
  }

  render () {
    const { featureFlags, theme, locale, flagsReady, replacementsReady } = this.state
    if (!theme || !flagsReady || !replacementsReady) return <CircularProgress scale={7} />
    if (get(featureFlags, 'maintenance.value')) return <Maintenance />
    return (
      <IntlProvider key={locale} locale={locale} messages={messages[locale]}>
        <Themer theme={theme}>
          <Element className='__KUALI_THEMABLE__' id='top' name='top'>
            <Router
              history={this.props.history}
              render={applyRouterMiddleware(useScroll(this.shouldScroll))}
            >
              <Redirect path='/' to='/home' />
              <Route
                path='/'
                component={MediaQueryify(Main, {
                  featureFlags,
                  handleLanguageChange: this.handleLanguageChange,
                  catalogId: this.state.catalogId
                })}
              >
                <Route path='/addenda' component={MediaQueryify(Addenda)} />
                <Route path='/content/:id' component={ContentItem} />
                <Route path='/content/:id/:mode' component={ContentItem} />
                <Route path='/courses'>
                  <IndexRoute component={MediaQueryify(Courses)} />
                  <Route path=':pid' component={CourseView} />
                  <Route path='view/:id' component={CourseView} />
                </Route>
                <Route path='/experiences'>
                  <IndexRoute component={MediaQueryify(Experiences)} />
                  <Route path=':pid' component={ExperienceView} />
                  <Route path='view/:id' component={ExperienceView} />
                </Route>
                <Route path='/home' component={Home} />
                <Route path='/policy/:pid' component={Policy} />
                <Route path='/policies' component={MediaQueryify(Policies)} />
                <Route path='/programs'>
                  <IndexRoute component={MediaQueryify(Programs)} />
                  <Route path='category/:categoryId' component={Programs} />
                  <Route path='view/:id' component={ProgramView} />
                  <Route path=':pid' component={ProgramView} />
                  <Route path=':pid/:specPid' component={ProgramView} />
                </Route>
                <Route path='/sets'>
                  <Route path=':id' component={ContentSet} />
                  <Route path=':setId/content/:id' component={ContentItem} />
                </Route>
                <Route path='/search' component={MediaQueryify(Search)} />
                <Route path='/search/:id' component={MediaQueryify(Search)} />
                <Route path='*' component={NotFound} />
              </Route>
            </Router>
          </Element>
        </Themer>
      </IntlProvider>
    )
  }
}
