/* Copyright © 2016 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */

import c from 'classnames'
import { isUndefined, noop } from 'lodash'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { UnmountClosed as Collapse } from 'react-collapse'
import Button from 'react-md/lib/Buttons'
import { Element } from 'react-scroll'

import style from './style.css'
import { injectIntl, defineMessages } from 'react-intl'

const messages = defineMessages({
  linkToTitle: {
    id: 'collapsible-box.link-to-title',
    defaultMessage: 'Link to {title}'
  },
  show: {
    id: 'collapsible-box.show',
    defaultMessage: 'show '
  },
  hide: {
    id: 'collapsible-box.hide',
    defaultMessage: 'hide'
  },
  idRegionExpanded: {
    id: 'collapsible-box.id-region-expanded',
    defaultMessage: '{id} region expanded'
  }
})

class CollapsibleBox extends Component {
  static contextTypes = {
    isPrint: PropTypes.bool
  }

  constructor (props) {
    super(props)
    this.state = {
      _collapsed: !isUndefined(props.expanded)
        ? !props.expanded
        : !isUndefined(props.startExpanded)
          ? !props.startExpanded
          : true,
      _isCollapseControlled: !isUndefined(props.expanded)
    }
    this._toggleCollapse = this._toggleCollapse.bind(this)
  }

  componentWillReceiveProps (nextProps) {
    if (nextProps.expandable && nextProps.expanded !== this.props.expanded) {
      this.setState({ _collapsed: !nextProps.expanded })
    }
  }

  componentDidUpdate (prevProps) {
    if (this.props.startExpanded !== prevProps.startExpanded) {
      this.setState({ _collapsed: !this.props.startExpanded })
    }
  }

  shouldComponentUpdate (nextProps, nextState) {
    if (this.props.children !== nextProps.children) return true
    if (this.props.expandable !== nextProps.expandable) return true
    if (this.props.expanded) return this.props.expanded !== nextProps.expanded
    else return this.state._collapsed !== nextState._collapsed
  }

  _toggleCollapse () {
    const { _collapsed } = this.state
    if (this.props.onExpandChange) {
      this.props.onExpandChange(_collapsed, this.props.id)
    }
    if (_collapsed) {
      setImmediate(() => {
        if (this.bodyRef) this.bodyRef.focus()
      })
    }
    this.setState({ _collapsed: !_collapsed })
  }

  renderPrintView (TitleComponent) {
    const { title, titleClassName, children } = this.props
    return (
      <div>
        <TitleComponent className={titleClassName}>{title}</TitleComponent>
        {children}
      </div>
    )
  }

  render () {
    const { context, props, state } = this
    const renderCollapsed =
      state._isCollapseControlled && props.expandable
        ? !props.expanded
        : props.expandable
          ? state._collapsed
          : false
    const TitleComponent = props.titleComponent || 'div'
    const { isPrint } = context
    const { expandButtonClassName = '', intl, linkButtonClassName = '' } = props
    if (isPrint) return this.renderPrintView(TitleComponent)
    return (
      <Element
        name={props.title}
        className={c(style.collapsibleBox, props.collapsibleBoxClassName)}
      >
        <div
          className={c([style.header, props.headerClassName], {
            [style.headerExpandable]: props.expandable
          })}
          onClick={props.expandable ? this._toggleCollapse : noop}
        >
          <div style={{ flex: 2 }}>
            <TitleComponent className={c(style.title, props.titleClassName)}>
              {props.title}
            </TitleComponent>
            <div className={c(style.subtitle, props.subtitleClassName)}>
              {props.subtitle}
            </div>
          </div>
          {props.titleExtension && (
            <div className={style.titleExtension}>{props.titleExtension}</div>
          )}
          {props.label && <div className={style.label}>{props.label}</div>}
          {props.link && props.expandable && (
            <a
              aria-hidden={renderCollapsed}
              target='_blank'
              tabIndex={-1}
              className={c(
                style.linkButton,
                linkButtonClassName,
                renderCollapsed ? '' : style.linkButtonExpanded
              )}
              href={props.link}
            >
              <Button
                icon
                tabIndex={-1}
                aria-label={intl.formatMessage(messages.linkToTitle, {
                  title: props.title
                })}
                aria-hidden={renderCollapsed}
              >
                open_in_new
              </Button>
            </a>
          )}
          {props.expandable && (
            <Button
              icon
              aria-controls={props.id}
              aria-expanded={!renderCollapsed}
              aria-label={`${
                renderCollapsed
                  ? intl.formatMessage(messages.show)
                  : intl.formatMessage(messages.hide)
              } ${props.title}`}
              className={c(
                style.collapseButton,
                expandButtonClassName,
                renderCollapsed ? '' : style.collapseButtonExpanded
              )}
            >
              keyboard_arrow_down
            </Button>
          )}
        </div>
        <Collapse
          className={c(style.body, props.bodyClassName)}
          isOpened={!renderCollapsed}
          style={props.textStyle}
        >
          <div
            aria-label={intl.formatMessage(messages.idRegionExpanded, {
              id: props.title
            })}
            className={style.collapseContainer}
            id={props.id}
            ref={el => (this.bodyRef = el)}
            tabIndex={0}
          >
            {props.children}
          </div>
        </Collapse>
      </Element>
    )
  }
}

CollapsibleBox.displayName = 'CollapsibleBox'
CollapsibleBox.propTypes = {
  bodyClassName: PropTypes.string,
  children: PropTypes.node.isRequired,
  collapsibleBoxClassName: PropTypes.string,
  expanded: PropTypes.bool,
  expandable: PropTypes.bool,
  expandButtonClassName: PropTypes.string,
  headerClassName: PropTypes.string,
  id: PropTypes.string.isRequired,
  intl: PropTypes.shape({
    formatMessage: PropTypes.func.isRequired
  }).isRequired,
  label: PropTypes.string,
  link: PropTypes.string,
  linkButtonClassName: PropTypes.string,
  onExpandChange: PropTypes.func,
  startExpanded: PropTypes.bool,
  subtitle: PropTypes.string,
  subtitleClassName: PropTypes.string,
  textStyle: PropTypes.object, // seems to no longer be used
  title: PropTypes.string.isRequired,
  titleClassName: PropTypes.string,
  titleComponent: PropTypes.string
}

export default injectIntl(CollapsibleBox)
