export const isColorDark = hexColor => {
  var color = hexColor.substring(1)
  var rgb = parseInt(color, 16)
  var r = (rgb >> 16) & 0xff
  var g = (rgb >> 8) & 0xff
  var b = (rgb >> 0) & 0xff

  var luma = 0.2126 * r + 0.7152 * g + 0.0722 * b
  if (luma < 100) {
    return true
  }
  return false
}
