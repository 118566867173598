import classnames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'
import striptags from 'striptags'

import styles from './style.css'
import HighlightSearchTerms from '../highlight-search-terms'

export default function SearchDescription ({ item, searchTerm }) {
  const terms = searchTerm
    .trim()
    .split(/\s+/)
    .map(v => v.toLowerCase())
  let description = striptags(item.description)
  if (item.type === 'policies' || (!description && item.body)) {
    description = striptags(item.body)
  }
  if (!description) return null
  const lowerDescription = description.toLowerCase()
  const firstTermIndex = Math.min.apply(
    Math,
    terms.map(term => lowerDescription.indexOf(term))
  )
  let frontTrimmed = false
  if (firstTermIndex > 330) {
    frontTrimmed = true
    description = description.slice(firstTermIndex - 165)
  }
  return (
    <p
      className={classnames({
        [styles.searchResultDescription]: true,
        [styles.fadeFront]: frontTrimmed
      })}
    >
      <HighlightSearchTerms text={description} terms={terms} />
    </p>
  )
}

SearchDescription.displayName = 'SearchDescription'

SearchDescription.propTypes = {
  item: PropTypes.shape({
    description: PropTypes.string,
    body: PropTypes.string
  }).isRequired,
  searchTerm: PropTypes.string.isRequired
}

SearchDescription.defaultProps = {
  item: {},
  searchTerm: ''
}
