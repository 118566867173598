/* Copyright © 2016 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */

import PropTypes from 'prop-types'
import React from 'react'
import ReactMDButton from 'react-md/lib/Buttons'

const IconButton = props => {
  const { tooltip, ...rest } = props
  return (
    <ReactMDButton icon tooltipLabel={tooltip} {...rest}>
      {props.children}
    </ReactMDButton>
  )
}

IconButton.displayName = 'IconButton'
IconButton.propTypes = {
  tooltip: PropTypes.string
}

export default IconButton
