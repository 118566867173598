import React from 'react'
import { noop } from 'lodash'
import PropTypes from 'prop-types'
import { FilterIcon } from '@kuali/wok-ui/lib/icons/filter'
import classNames from 'classnames'
import styles from './style.css'

export default function FilterButton ({ active, onClick }) {
  return (
    <button
      className={classNames(styles.filterButton, {
        [styles.active]: active
      })}
      onClick={onClick}
    >
      <FilterIcon size='medium' style={{ verticalAlign: 'middle' }} ui='blue' />
      <span style={{ marginLeft: 5, verticalAlign: 'middle' }}>Filter</span>
    </button>
  )
}

FilterButton.defaultProps = {
  active: false,
  onClick: noop
}

FilterButton.propTypes = {
  active: PropTypes.bool,
  onClick: PropTypes.func
}
