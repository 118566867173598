/* Copyright © 2016 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */

import { pickBy } from 'lodash'
import qs from 'querystring'
import PropTypes from 'prop-types'
import React from 'react'

const BreadcrumbsLink = ({
  children,
  current,
  group,
  itemType,
  to,
  ...rest
}) => {
  const stateString = qs.stringify(
    pickBy({
      bc: true,
      bcCurrent: current,
      bcGroup: group,
      bcItemType: itemType
    })
  )
  return (
    <a
      href={
        to.indexOf('?') === -1
          ? `#/${to}?${stateString}`
          : `#/${to}&${stateString}`
      }
      {...rest}
    >
      {children}
    </a>
  )
}

BreadcrumbsLink.displayName = 'BreadcrumbsLink'

BreadcrumbsLink.propTypes = {
  current: PropTypes.string,
  group: PropTypes.string,
  itemType: PropTypes.string,
  to: PropTypes.string
}

export default BreadcrumbsLink
