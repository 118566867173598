/* Copyright © 2016 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */

/* eslint react/forbid-prop-types:0 */
import { each, find, findIndex, isEqual, keys, map } from 'lodash'
import PropTypes from 'prop-types'
import React from 'react'

const CheckboxesView = props => {
  let { data, options } = props
  data = fixSorting(data, options)
  return (
    <div>
      {map(data, (value, key) => {
        if (!value) return null
        const option = find(options, o => o.key === key)
        if (!option) return null
        return <div key={key}>{option.text}</div>
      })}
    </div>
  )
}

CheckboxesView.displayName = 'CheckboxesView'
CheckboxesView.propTypes = {
  data: PropTypes.object,
  options: PropTypes.arrayOf(PropTypes.object)
}

// this catastrophically stupid fix is because elasticsearch will, under some conditions, reorder the keys on json objects
function fixSorting (data, options) {
  const dataKeys = keys(data)
  const sortedKeys = [ ...dataKeys ].sort((a, b) => {
    const indexA = findIndex(options, o => o.key === a)
    const indexB = findIndex(options, o => o.key === b)
    return indexA - indexB
  })
  if (isEqual(dataKeys, sortedKeys)) {
    return data
  }
  const sorted = {}
  each(sortedKeys, key => {
    sorted[key] = data[key]
  })
  return sorted
}

export default CheckboxesView
