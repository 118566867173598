/* Copyright © 2016 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */

import { debounce, throttle } from 'lodash'
import PropTypes from 'prop-types'
import React from 'react'

export default (OldComponent, id) =>
  class ScrollSpy extends React.Component {
    static displayName = 'ScrollSpyHOC'

    static childContextTypes = {
      scrollRight: PropTypes.number,
      scrollTop: PropTypes.number,
      scrollY: PropTypes.number
    }

    componentDidMount () {
      const element = document.getElementById(id)
      if (element) {
        this.getState()
        this.setupListeners()
      }
    }

    getChildContext () {
      return this.state || {}
    }

    getState () {
      const element = document.getElementById(id)
      if (element) {
        const boundingRect = element.getBoundingClientRect()
        this.setState({
          scrollRight: boundingRect.right,
          scrollTop: boundingRect.top + window.scrollY,
          scrollY: window.scrollY
        })
      }
    }

    setupListeners () {
      this.onScroll = debounce(() => {
        this.setState({ scrollY: window.scrollY })
      }, 500)
      this.onResize = throttle(() => {
        this.getState()
      }, 500)
      window.addEventListener('scroll', this.onScroll)
      window.addEventListener('resize', this.onResize)
    }

    componentWillUnmount () {
      this.onScroll.cancel()
      this.onResize.cancel()
      window.removeEventListener('scroll', this.onScroll)
      window.removeEventListener('resize', this.onResize)
    }

    render () {
      return <OldComponent {...this.state} {...this.props} />
    }
  }
