/* Copyright © 2016 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */

import { capitalize, each, get, groupBy, isEmpty, map } from 'lodash'
import PropTypes from 'prop-types'
import qs from 'querystring'
import React, { Component } from 'react'
import DocumentTitle from 'react-document-title'
import { scroller } from 'react-scroll'

import BreadcrumbLink from '../../../../client-common/components/breadcrumbs-link'
import CircularProgress from '../../../../client-common/components/circular-progress'
import CollapsibleBox from '../../../../common/collapsible-box'
import Divider from '../../../../client-common/components/divider'
import styles from './style'
import TopLevelPanel from '../../../../client-common/components/top-level-panel'
import { page } from '../../../../client-common/lib/analytics'
import { getCachedEndpoint } from '../../../../client-common/lib/cacher'
import { PublicCatalogType } from '../../../../client-common/lib/types'
import replace from '../../../../client-common/lib/ui-text-replace'
import { injectIntl, defineMessages, FormattedMessage } from 'react-intl'
import { printIfNeeded } from '../printing'

const messages = defineMessages({
  searchForPolicy: {
    id: 'policies.search-for-policy',
    defaultMessage: 'Search for {policy}'
  },
  noPolicyResults: {
    id: 'policies.no-policy-results',
    defaultMessage: 'No {policy} results'
  },
  viewPolicies: {
    id: 'policies.view-policies',
    defaultMessage: 'View {policies}'
  },
  policiesList: {
    id: 'policies.policies-list',
    defaultMessage: '{policies} List'
  },
  loadingPolicies: {
    id: 'policies.loading-policies',
    defaultMessage: 'Loading Policies'
  },
  search: {
    id: 'policies.search',
    defaultMessage: 'Search'
  },
  policies: {
    id: 'policies.policies',
    defaultMessage: 'Policies'
  },
  resultsFound: {
    id: 'policies.results-found',
    defaultMessage: 'results found'
  },
  loadingPrintView: {
    id: 'policies.loading-print-view',
    defaultMessage: 'Loading Print View'
  },
  title: {
    id: 'policies.title',
    defaultMessage: 'Title'
  }
})

class Policies extends Component {
  static displayName = 'Policies'

  static propTypes = {
    catalog: PublicCatalogType
  }

  static contextTypes = {
    isPrint: PropTypes.bool
  }

  constructor (props) {
    super(props)
    this.state = {
      didPrint: false,
      latestSearchTerm: '',
      loading: true,
      policies: null
    }
    page('Policies')
    this.fetchPolicies('')
    const { href } = window.location
    const { group } = qs.parse(href.slice(href.indexOf('?') + 1))
    if (group) {
      this.state.singleGroup = group
    }
    this.printIfNeeded = printIfNeeded.bind(this)
  }

  componentDidUpdate () {
    const { singleGroup } = this.state
    const { href } = window.location
    const { group } = qs.parse(href.split('?')[1])
    if (group !== singleGroup) {
      this.setState({ singleGroup: group })
    }

    this.printIfNeeded(this.state.policies)
  }

  componentDidMount () {
    this._isMounted = true
  }

  componentWillUnmount () {
    this._isMounted = false
  }

  async fetchPolicies (searchTerm = '') {
    this.setState({ latestSearchTerm: searchTerm, loading: true })

    const catalogId = this.props.catalog._id
    const query = qs.stringify({ q: searchTerm })
    const endpoint = `/policies/${catalogId}?${query}`
    const policies = await getCachedEndpoint(endpoint)

    if (this.state.latestSearchTerm !== searchTerm) {
      return
    }

    const groups = this.groupByAndSort(policies)
    const { expanded } = qs.parse(window.location.href.split('?')[1])
    if (this._isMounted) {
      this.setState({ groups, loading: false, policies, searchTerm, expanded })
      if (expanded) {
        scroller.scrollTo(expanded, {
          duration: 500,
          delay: 250,
          smooth: true
        })
      }
    }
  }

  groupByAndSort = items => {
    const {
      catalog: { settings },
      intl
    } = this.props
    const category = get(settings, 'policies.category')
    if (!category || !category.trim()) return {}

    let groupedObj
    if (intl.locale === 'es') {
      groupedObj = groupBy(items, `${category}.customFields.${'Spanish Name'}`)
    } else {
      groupedObj = groupBy(items, `${category}.name`)
    }
    const sortedObj = {}
    each(
      Object.keys(groupedObj).sort(),
      key => (sortedObj[key] = groupedObj[key])
    )
    return sortedObj
  }

  renderUngroupedView (policies) {
    return (
      <ul className={styles.ul}>
        {map(
          policies,
          (policy, i, { length }) =>
            policy.title && (
              <li key={policy.pid} className={styles.item}>
                <h3 className={styles.h3}>
                  <BreadcrumbLink
                    current={policy.title}
                    itemType='policies'
                    to={`policy/${policy.pid}`}
                  >
                    {policy.title}
                  </BreadcrumbLink>
                </h3>
                {i < length - 1 && <Divider />}
              </li>
            )
        )}
      </ul>
    )
  }

  renderGroupView () {
    const { groups, singleGroup: group } = this.state
    return (
      <div>
        {group && (
          <CollapsibleBox key={group} title={group} titleComponent='h2'>
            {map(groups[group], (policy, i, { length }) => (
              <div key={i} className={styles.item}>
                <h3 className={styles.h3}>
                  <BreadcrumbLink
                    current={policy.title}
                    group={group}
                    itemType='policies'
                    to={`policy/${policy.pid}`}
                  >
                    {policy.title}
                  </BreadcrumbLink>
                </h3>
                {i < length - 1 && <Divider />}
              </div>
            ))}
          </CollapsibleBox>
        )}
        {!group && (
          <ul className={styles.ul}>
            {map(groups, (_policies, group) => (
              <li key={group} className={styles.li}>
                <CollapsibleBox
                  expandable
                  id={group}
                  key={group}
                  link={
                    window.location.href.split('?')[0] +
                    `?${qs.stringify({ group })}`
                  }
                  startExpanded={this.state.expanded === group}
                  title={group}
                  titleComponent='h2'
                >
                  <ul className={styles.ul}>
                    {map(_policies, (p, i, { length }) => (
                      <li key={p.pid} className={styles.item}>
                        <h3 className={styles.h3}>
                          <BreadcrumbLink
                            current={p.title}
                            group={group}
                            itemType='policies'
                            to={`policy/${p.pid}`}
                          >
                            {p.title}
                          </BreadcrumbLink>
                        </h3>
                        {i < length - 1 && <Divider />}
                      </li>
                    ))}
                  </ul>
                </CollapsibleBox>
              </li>
            ))}
          </ul>
        )}
      </div>
    )
  }

  renderSearchView (policies, catalog) {
    const {
      catalog: {
        settings: {
          policies: { category }
        }
      },
      schemas: { policies: policySchema },
      intl
    } = this.props
    return (
      <div>
        <span role='alert'>
          <b>{`${policies.length} `}</b>
          <FormattedMessage {...messages.resultsFound} />
        </span>
        {!category && (
          <div className={styles.resultHeader}>
            <FormattedMessage {...messages.title} />
          </div>
        )}

        {category && (
          <div className={`${styles.twoColumns} ${styles.resultHeader}`}>
            <span className={styles.left}>
              <FormattedMessage {...messages.title} />
            </span>
            <span className={styles.right}>
              {intl.locale !== 'en'
                ? get(
                  policySchema[category],
                  ['translatedLabels', intl.locale],
                  policySchema[category].label
                )
                : `${policySchema[category].label}`}
            </span>
          </div>
        )}
        {map(policies, (p, i, { length }) => (
          <div key={p.pid}>
            <div className={category ? styles.twoColumns : ''}>
              <div className={`${styles.item} ${category ? styles.left : ''}`}>
                <h3 className={styles.h3}>
                  <BreadcrumbLink
                    current={p.title}
                    itemType='policies'
                    to={`policy/${p.pid}`}
                  >
                    {p.title}
                  </BreadcrumbLink>
                </h3>
              </div>
              {intl.locale === 'es' && category && (
                <div className={styles.right}>
                  {get(p, `${category}.customFields.${'Spanish Name'}`)}
                </div>
              )}
              {intl.locale === 'en' && category && (
                <div className={styles.right}>{get(p, `${category}.name`)}</div>
              )}
            </div>
            {i < length - 1 && <Divider />}
          </div>
        ))}
      </div>
    )
  }

  render () {
    const { isPrint } = this.context
    const {
      groups,
      loading,
      policies,
      searchTerm,
      singleGroup: group
    } = this.state
    const { catalog, intl } = this.props

    return (
      <div>
        <DocumentTitle
          title={intl.formatMessage(messages.viewPolicies, {
            policies: capitalize(replace('Policies', false, intl.locale))
          })}
        />

        <h1 className='hidden'>
          <FormattedMessage
            {...messages.policiesList}
            values={{ policies: replace('Policies', false, intl.locale) }}
          />
        </h1>
        <TopLevelPanel>
          <div>
            <h2 className={styles.h2}>
              <FormattedMessage {...messages.policies} />
            </h2>
            {!loading &&
              isEmpty(groups) &&
              !searchTerm &&
              this.renderUngroupedView(policies)}
            {!loading &&
              !isEmpty(groups) &&
              !searchTerm &&
              this.renderGroupView(policies, groups, group)}
            {!loading &&
              searchTerm &&
              policies.length &&
              this.renderSearchView(policies, catalog)}
            {loading && (
              <div className={styles.progressWrapper}>
                <div className='hidden' role='alert'>
                  <FormattedMessage {...messages.loadingPolicies} />
                </div>
                {isPrint && intl.formatMessage(messages.loadingPrintView)}
                <CircularProgress />
              </div>
            )}
            {!loading && !policies.length && (
              <div role='alert'>
                {intl.formatMessage(messages.noPolicyResults, {
                  policy: replace('policy', false, intl.locale)
                })}
              </div>
            )}
          </div>
        </TopLevelPanel>
      </div>
    )
  }
}

export default injectIntl(Policies)
