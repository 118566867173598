import { get } from 'lodash'
import PropTypes from 'prop-types'
import React from 'react'

import styles from './style.css'

export default function HighlightSearchTerms ({ terms, text }) {
  if (get(terms, ['0', 'length'], 0) === 0) {
    return [text]
  }

  const parts = []
  const regex = new RegExp(terms.join('|'), 'i')
  while (text.length) {
    const match = text.match(regex)
    if (!match) {
      parts.push(text)
      text = ''
    } else {
      const matchedWord = match[0]
      const matchedIndex = match.index
      const before = text.substr(0, matchedIndex)
      text = text.substr(matchedIndex + matchedWord.length)
      parts.push(before)
      parts.push(
        <span key={parts.length} className={styles.searchWordHighlight}>
          {matchedWord}
        </span>
      )
    }
  }
  return parts
}

HighlightSearchTerms.displayName = 'HighlightSearchTerms'

HighlightSearchTerms.propTypes = {
  terms: PropTypes.arrayOf(PropTypes.string),
  text: PropTypes.string
}

HighlightSearchTerms.defaultProps = {
  terms: [],
  text: ''
}
