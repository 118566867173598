/* Copyright © 2016 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */

import ReactMDDrawer from 'react-md/lib/Drawers'
import PropTypes from 'prop-types'
import React from 'react'
import IconButton from '../button/icon'
import { get } from 'lodash'

class Drawer extends React.Component {
  componentDidUpdate (prevProps) {
    if (this.props.open && !prevProps.open) {
      const closeButton = get(
        this,
        'closeButtonContainerRef.current.children[0]'
      )
      if (closeButton) closeButton.focus()
    }
  }

  render () {
    const { docked, open, onRequestChange, navItems, ...rest } = this.props

    this.closeButtonContainerRef = React.createRef()

    const closeButton = (
      <div style={{ textAlign: 'right' }} ref={this.closeButtonContainerRef}>
        <IconButton onClick={() => onRequestChange(false)}>close</IconButton>
      </div>
    )

    const navItemsWithCloseButton =
      navItems && navItems.length > 0 ? [...navItems] : []
    navItemsWithCloseButton.unshift(closeButton)

    return (
      <div>
        <div className='__KUALI_DIALOG_ __RENDER_NODE_DRAWER__' />
        <ReactMDDrawer
          clickableDesktopOverlay={!docked}
          onVisibilityChange={onRequestChange}
          visible={open}
          renderNode={document.querySelector('.__RENDER_NODE_DRAWER__')}
          navItems={navItemsWithCloseButton}
          {...rest}
        />
      </div>
    )
  }
}

Drawer.displayName = 'Drawer'
Drawer.propTypes = {
  docked: PropTypes.bool,
  open: PropTypes.bool,
  onRequestChange: PropTypes.func
}

export default Drawer
