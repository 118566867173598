import ReactMDButton from 'react-md/lib/Buttons/Button'
import React from 'react'

const Button = props => {
  return <ReactMDButton {...props} />
}

Button.displayName = 'Button'

export default Button
