/* Copyright © 2016 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */

/* eslint react/forbid-prop-types:0 */
import { get } from 'lodash'
import PropTypes from 'prop-types'
import React from 'react'
import { injectIntl, defineMessages, FormattedMessage } from 'react-intl'

const messages = defineMessages({
  courseIsRepeatableFor: {
    id: 'repeatable-for-credits-view.course-is-repeatable-for',
    defaultMessage: 'Course is Repeatable for Credit'
  },
  courseIsNotRepeatable: {
    id: 'repeatable-for-credits-view.course-is-not-repeatable',
    defaultMessage: 'Course is Not Repeatable for Credit'
  },
  maxCreditsNumber: {
    id: 'repeatable-for-credits-view.max-credits-number',
    defaultMessage: 'Max Credits: '
  },
  maxAttemptsNumber: {
    id: 'repeatable-for-credits-view.max-attempts-number',
    defaultMessage: 'Max Attempts: '
  }
})

const RepeatableForCreditsView = props => {
  const { data, intl } = props
  return (
    <div>
      <div>
        {get(data, 'repeatableForCredits')
          ? intl.formatMessage(messages.courseIsRepeatableFor)
          : intl.formatMessage(messages.courseIsNotRepeatable)}
      </div>
      {data.maxCredits && (
        <div>
          <FormattedMessage {...messages.maxCreditsNumber} />
          {get(data, 'maxCredits')}
        </div>
      )}
      {data.maxAttempts && (
        <div>
          <FormattedMessage {...messages.maxAttemptsNumber} />
          {get(data, 'maxAttempts')}
        </div>
      )}
    </div>
  )
}

RepeatableForCreditsView.displayName = 'RepeatableForCreditsView'

RepeatableForCreditsView.propTypes = {
  data: PropTypes.object
}

export default injectIntl(RepeatableForCreditsView)
