/* Copyright © 2016 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */

import { isUndefined } from 'lodash'
import qs from 'querystring'
import React from 'react'
import PropTypes from 'prop-types'

import style from './style'
import BreadcrumbLink from '../breadcrumbs-link'
import Button from '../button'
import replace from '../../../client-common/lib/ui-text-replace'
import { injectIntl, defineMessages } from 'react-intl'

const messages = defineMessages({
  goBack: {
    id: 'breadcrumb.go-back',
    defaultMessage: 'Go Back'
  },
  back: {
    id: 'breadcrumb.back',
    defaultMessage: 'Back'
  }
})

const Breadcrumbs = ({ mq, intl }) => {
  try {
    const { href } = window.location
    let {
      bc,
      bcCurrent: current,
      bcGroup: group,
      bcItemType: itemType
    } = qs.parse(href.slice(href.indexOf('?') + 1))
    if (Array.isArray(itemType)) {
      itemType = itemType[0]
    }
    if (isUndefined(bc)) {
      return <span />
    } else {
      return (
        <div className={style.breadcrumbs}>
          <Button
            aria-label={intl.formatMessage(messages.goBack)}
            raised={mq !== 'small'}
            icon={mq === 'small'}
            iconChildren={<span aria-hidden>arrow_back</span>}
            onClick={() => {
              if (itemType && window.history.length <= 1) {
                window.location.href = `#/${itemType}?${qs.stringify({
                  expanded: group
                })}`
              } else {
                window.history.back()
              }
            }}
          >
            {mq !== 'small' && intl.formatMessage(messages.back)}
          </Button>
          <div className={style.truncate}>
            {group && (
              <BreadcrumbLink
                className={style.groupLink}
                current={group}
                itemType={itemType}
                to={`${itemType}?${qs.stringify({ group })}`}
              >
                {group} {replace(itemType, true, intl.locale)}
              </BreadcrumbLink>
            )}
            {group && (
              <span
                style={{
                  color: '#222',
                  marginBottom: '4px',
                  marginLeft: '8px'
                }}
              >
                >
              </span>
            )}
            <span className={style.current}>{current}</span>
          </div>
        </div>
      )
    }
  } catch (e) {
    return <span />
  }
}

Breadcrumbs.displayName = 'Breadcrumbs'
Breadcrumbs.contextTypes = {
  isPrint: PropTypes.bool
}

export default injectIntl(Breadcrumbs)
