/* Copyright © 2016 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */

/* eslint react/no-danger: 0 */
import axios from 'axios'
import { get, isEmpty } from 'lodash'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import DocumentTitle from 'react-document-title'

import styles from './style'
import TopLevelPanel from '../../../client-common/components/top-level-panel'
import { CatalogType } from '../../../client-common/lib/types'
import { loadFeatureFlags } from '../../../common/lib/feature-flags'
import { injectIntl, defineMessages } from 'react-intl'
import FlatButton from '../../../client-common/components/button/flat'
import {
  isTranslationEnabledForCatalog,
  isTranslationRequestedInUrl,
  onTranslationClick
} from '../../../client-common/lib/translation/translation'

const messages = defineMessages({
  viewContentItem: {
    id: 'content-item.view-content-item',
    defaultMessage: 'View Content Item'
  }
})

const paramsType = PropTypes.shape({
  id: PropTypes.string
})

export function getImagesOnPage () {
  return document.querySelectorAll('img').values()
}

export function checkIfCanPrintYet (getImagesOnPageFn, printFn, getContentFn) {
  const images = getImagesOnPageFn()
  let imagesStillLoading = false
  for (let image of images) {
    if (!image.complete) {
      imagesStillLoading = true
      break
    }
  }

  if (imagesStillLoading || isEmpty(getContentFn())) {
    setTimeout(() => {
      checkIfCanPrintYet(getImagesOnPageFn, printFn, getContentFn)
    }, 500)
    return
  }

  printFn()
}

class ContentItem extends Component {
  static displayName = 'ContentItem'

  static propTypes = {
    catalog: CatalogType,
    params: paramsType
  }

  static contextTypes = {
    isPrint: PropTypes.bool
  }

  constructor (props) {
    super(props)
    this.state = {
      content: {}
    }
    const { home } = props
    if (home) return this.fetchContent(props.id)
    const { id } = this.props.params
    if (!id || id === 'undefined') window.location = `#/home`
    else this.fetchContent(id)
  }

  componentWillReceiveProps (nextProps) {
    const { id } = nextProps.params
    if (!id || id === 'undefined') window.location = `#/home`
    else this.fetchContent(id)
  }

  componentDidMount () {
    const { isPrint } = this.context

    this._isMounted = true
    if (isPrint) {
      checkIfCanPrintYet(getImagesOnPage, window.print, () => {
        return this.state.content
      })
    }
    this.loadFeatureFlags()
  }

  componentWillUnmount () {
    this._isMounted = false
  }

  fetchContent (id) {
    axios
      .get(`/content/${id}`)
      .then(({ data: content }) => {
        if (this._isMounted) {
          this.setState({ content })
        }
      })
      .catch(err => {
        if (get(err, 'response.status') === 404) window.location = `#/404`
      })
  }

  async loadFeatureFlags () {
    const featureFlags = await loadFeatureFlags()
    this.setState({ featureFlags })
  }

  body () {
    const { content } = this.state

    if (this.props.params.mode === 'preview-draft') {
      return content.ckBodyDraft
    } else if (this.shouldRenderTranslation(content)) {
      return content.bodyTranslation
    } else {
      return content.ckBodyPublished || content.body
    }
  }

  contentClickHandler = e => {
    const anchorLink = e.target.closest('a')
    if (anchorLink) {
      const anchorHref = anchorLink.getAttribute('href').substr(1)
      const targetElement = document.getElementById(anchorHref)
      if (targetElement) {
        e.preventDefault()
        targetElement.scrollIntoView({ behavior: 'smooth' })
      }
    }
  }

  isTranslationEnabled (content) {
    const { catalog } = this.props
    const { featureFlags } = this.state

    const catalogTranslationsEnabled = isTranslationEnabledForCatalog(
      catalog,
      featureFlags
    )

    const contentTranslationEnabled = content.enableTranslation

    return catalogTranslationsEnabled && contentTranslationEnabled
  }

  shouldRenderTranslation (content) {
    return isTranslationRequestedInUrl() && this.isTranslationEnabled(content)
  }

  maybeRenderTranslationButton (content) {
    if (!this.isTranslationEnabled(content) || this.context.isPrint) {
      return
    }

    const { catalog } = this.props
    const catalogEnableTranslationButtonText = get(
      catalog,
      'settings.alternateTranslation.enableTranslationButtonText',
      ''
    )
    const catalogDisableTranslationButtonText = get(
      catalog,
      'settings.alternateTranslation.disableTranslationButtonText',
      ''
    )

    const translated = isTranslationRequestedInUrl()

    const translationButtonText = translated
      ? catalogDisableTranslationButtonText
      : catalogEnableTranslationButtonText

    return (
      <div>
        <FlatButton
          backgroundColor='white'
          className={styles.translationButton}
          primary
          label={translationButtonText}
          labelStyle={{ color: '#0033A0' }}
          onClick={onTranslationClick.bind(this)}
          style={{
            border: 'solid 1px #cbcbcb'
          }}
        />
      </div>
    )
  }

  render () {
    const { content } = this.state
    const {
      catalog: {
        settings: {
          catalog: { maxCharacters }
        }
      },
      intl
    } = this.props
    const columns =
      maxCharacters && +get(content, 'body.length', 0) > maxCharacters

    let itemTitle = content.title

    if (this.shouldRenderTranslation(content) && content.titleTranslation) {
      itemTitle = content.titleTranslation
    }

    return (
      <TopLevelPanel>
        <div key={content.title} className={styles.wrapper}>
          {content && (
            <div>
              <DocumentTitle
                title={
                  itemTitle || intl.formatMessage(messages.viewContentItem)
                }
              />
              <div className={styles.itemTitleAndTranslationButton}>
                <div>
                  {itemTitle && !content.hideTitle && itemTitle !== '' && (
                    <h2>{itemTitle}</h2>
                  )}
                </div>
                <div className={styles.translationButtonContainer}>
                  {this.maybeRenderTranslationButton(content)}
                </div>
              </div>
              <div
                className={classnames({
                  [styles.contentBody]: true,
                  [styles.contentBodyColumns]: columns
                })}
                dangerouslySetInnerHTML={{ __html: this.body() }}
                onClick={this.contentClickHandler}
              />
            </div>
          )}
        </div>
      </TopLevelPanel>
    )
  }
}

export default injectIntl(ContentItem)
