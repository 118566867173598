import { get } from 'lodash'
import qs from 'querystring'
import React from 'react'
import FlatButton from '../../components/button/flat'

/**
 This translation layer was requested and paid for by York University during their onboarding to support
 the ability to produce a French Catalog.  The PM for this is Hannah Martinez.

 In CM you might have a gadget with apikey:description on a course and to add a translation you will
 create a duplicate gadget of the same type labeled descriptionTRANSLATION to force it have the
 apikey:descriptiontranslation.
 After creating and labeling the gadget you can relabel it whatever makes sense like "La Description"
 and the apikey will remain descriptiontranslation.

 These two gadgets are now linked in the sense that if we are asked to translate a course that includes the
 apikey:description field, we will replace it with the contents of the apikey:descriptiontranslation field.

 In catalog, to enable translations you have to turn on a feature flag, enable a catalog-scoped setting
 and provide button text for the "view in french" and "view in english" buttons.

 The translation toggle button will add &translated=true to the url and that will signal to the render
 to swap out all the untranslated gadgets and only render the translated ones.  This means that if you have
 a course view with a bunch of gadgets but only description is translated, only description will show when
 translation is requested.

 The current spec is that the translation button will only show if the feature flag is on, the catalog
 enables translation, the button text fields are not empty and the item to render has any translated fields
 present (that last bit is not implemented yet...)
 */

export function isTranslationEnabledForCatalog (catalog, featureFlags) {
  const translationFlagEnabled = get(
    featureFlags,
    'alternateTranslations',
    false
  )

  const catalogTranslationsEnabled = get(
    catalog,
    'settings.alternateTranslation.enabled',
    false
  )

  return translationFlagEnabled && catalogTranslationsEnabled
}

export function isTranslationRequestedInUrl () {
  const { translated } = qs.parse(window.location.href.split('?')[1])
  return translated
}

export function shouldRenderTranslation (catalog, featureFlags) {
  const translationRequested = isTranslationRequestedInUrl()
  const translationEnabled = isTranslationEnabledForCatalog(
    catalog,
    featureFlags
  )
  return translationRequested && translationEnabled
}

export function onTranslationClick () {
  const splitUrl = window.location.href.split('?')
  const qParams = qs.parse(splitUrl[1]) || {}

  if (qParams.translated) {
    disableTranslation()
  } else {
    enableTranslation()
  }
}

function disableTranslation () {
  const splitUrl = window.location.href.split('?')
  const qParams = qs.parse(splitUrl[1]) || {}

  delete qParams.translated
  let paramString = qs.stringify(qParams)
  if (Object.keys(qParams).length > 0) {
    window.location = splitUrl[0] + '?' + paramString
  } else {
    window.location = splitUrl[0]
  }
}

function enableTranslation () {
  const splitUrl = window.location.href.split('?')
  const qParams = qs.parse(splitUrl[1]) || {}

  qParams.translated = true
  let paramString = qs.stringify(qParams)
  window.location = splitUrl[0] + '?' + paramString
}

export function getHashParamsString () {
  const splitUrl = window.location.href.split('?')
  if (splitUrl[1]) {
    return '?' + splitUrl[1]
  } else {
    return ''
  }
}

export function getHashParams () {
  const splitUrl = window.location.href.split('?')
  return qs.parse(splitUrl[1])
}

export function itemHasTranslations (item, keys) {
  if (!keys || !item) {
    return false
  }
  for (let key of keys) {
    let translatedKey = key + 'translation'
    if (item[translatedKey]) {
      return true
    }
  }

  return false
}

export function replaceItemKeysWithTranslation (item, keys, schemas, itemType) {
  const result = []
  if (!keys || !item) {
    return keys
  }

  let schema = get(schemas, itemType)

  for (let key of keys) {
    let gadgetType = get(schema, `${key}.type`)
    if (gadgetType === 'Credits' || gadgetType === 'OmegaRules') {
      result.push(key)
      continue
    }

    let translatedKey = key + 'translation'
    result.push(translatedKey)
  }
  return result
}

export function maybeRenderTranslationButton (
  catalog,
  shouldShowTranslationButton,
  featureFlags,
  isPrint
) {
  if (
    !shouldShowTranslationButton ||
    !isTranslationEnabledForCatalog(catalog, featureFlags) ||
    isPrint
  ) {
    return
  }

  const catalogEnableTranslationButtonText = get(
    catalog,
    'settings.alternateTranslation.enableTranslationButtonText',
    ''
  )
  const catalogDisableTranslationButtonText = get(
    catalog,
    'settings.alternateTranslation.disableTranslationButtonText',
    ''
  )

  if (
    !catalog ||
    !catalogEnableTranslationButtonText ||
    !catalogDisableTranslationButtonText
  ) {
    return
  }

  const translated = isTranslationRequestedInUrl()

  const translationButtonText = translated
    ? catalogDisableTranslationButtonText
    : catalogEnableTranslationButtonText

  return (
    <div>
      <FlatButton
        backgroundColor='white'
        primary
        label={translationButtonText}
        labelStyle={{ color: '#0033A0' }}
        onClick={onTranslationClick}
        style={{
          border: 'solid 1px #cbcbcb',
          borderRadius: '4px'
        }}
      />
    </div>
  )
}
