/* Copyright © 2016 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */

/* eslint react/no-danger: 0 */
import axios from 'axios'
import { get, map, isEmpty } from 'lodash'
import ReactPropTypes from 'prop-types'
import React, { Component } from 'react'
import DocumentTitle from 'react-document-title'
import { routerShape } from 'react-router'

import style from './experience-view.css'
import AddendumView from '../../../../client-common/components/addendum-view'
import CircularProgress from '../../../../client-common/components/circular-progress'
import TopLevelPanel from '../../../../client-common/components/top-level-panel'
import { PublicCatalogType } from '../../../../client-common/lib/types'
import ItemDetails from '../../../../common/item-details'
import { defineMessages, FormattedMessage } from 'react-intl'
import { printIfNeeded } from '../printing'
import { loadFeatureFlags } from '../../../../common/lib/feature-flags'

import {
  itemHasTranslations,
  maybeRenderTranslationButton,
  replaceItemKeysWithTranslation,
  shouldRenderTranslation
} from '../../../../client-common/lib/translation/translation'

const messages = defineMessages({
  changesSincePublicationTitle: {
    id: 'changes-since-publication-title',
    defaultMessage: 'Changes Since {title} Publication'
  }
})

export default class ExperienceView extends Component {
  static displayName = 'ExperienceView'

  static propTypes = {
    catalog: PublicCatalogType,
    mq: ReactPropTypes.string,
    params: ReactPropTypes.shape({
      id: ReactPropTypes.string,
      pid: ReactPropTypes.string
    }),
    schemas: ReactPropTypes.object
  }

  static contextTypes = {
    isPrint: ReactPropTypes.bool,
    router: routerShape
  }

  constructor (props) {
    super(props)
    this.state = {
      didPrint: false,
      experience: null
    }
    this.printIfNeeded = printIfNeeded.bind(this)
  }

  async loadFeatureFlags () {
    const featureFlags = await loadFeatureFlags()
    this.setState({ featureFlags })
  }

  componentDidMount () {
    this.fetchExperience(this.props)
    this.loadFeatureFlags()
  }

  componentDidUpdate () {
    this.printIfNeeded(this.state.experience)
  }

  componentWillReceiveProps (nextProps) {
    this.fetchExperience(nextProps)
  }

  async fetchExperience (props) {
    const {
      catalog,
      params: { id, pid }
    } = props
    const { experience } = this.state
    if (
      (pid && get(experience, 'pid') === pid) ||
      (id && get(experience, 'id') === id)
    ) {
      return
    }
    const endpoint = pid
      ? `/experience/${catalog._id}/${pid}`
      : `/experience/byId/${catalog._id}/${id}`
    try {
      const { data: experience } = await axios.get(endpoint)
      this.setState({ experience })
      this.getAddenda()
    } catch (e) {
      if (e.message.indexOf('404') !== -1) window.location = '#/404'
    }
  }

  getAddenda = async () => {
    const {
      catalog,
      params: { pid }
    } = this.props
    const { data: addenda } = await axios.get(
      `/addenda/experiences/${catalog._id}/${pid}`
    )
    this.setState({ addenda })
  }

  buildExperienceTitle = (
    experience,
    experienceTitleExtension,
    showTranslation
  ) => {
    let title = get(experience, 'title')
    let extension = get(experience, experienceTitleExtension)

    let titleData = title
    if (showTranslation) {
      const titleTranslation = get(experience, 'titletranslation', false)

      if (titleTranslation) {
        titleData = titleTranslation
      }
    }

    if (!extension || isEmpty(extension)) return titleData
    const addition = extension.name || extension
    return `${titleData} (${addition})`
  }

  render () {
    const { catalog, mq, schemas } = this.props
    const { title, settings } = catalog
    const { addenda, experience, featureFlags } = this.state
    const { isPrint } = this.context
    const alternateLanguage = get(settings, 'alternateTranslation.language')
    const {
      experiences: { titleExtension: experienceTitleExtension }
    } = settings
    let catKeys = get(settings, 'experiences.keys', [])

    let shouldShowTranslation = shouldRenderTranslation(catalog, featureFlags)

    let experienceHasTranslations = itemHasTranslations(experience, catKeys)

    let translationLocaleCode
    if (shouldShowTranslation && experienceHasTranslations) {
      catKeys = replaceItemKeysWithTranslation(
        experience,
        catKeys,
        schemas,
        'experiences'
      )
      translationLocaleCode = alternateLanguage
    }

    return (
      <div>
        <TopLevelPanel>
          {!experience && <CircularProgress style={{ left: '50%' }} />}
          {experience && (
            <div className={style.wrapper}>
              <DocumentTitle title={experience.title || 'View Experience'} />

              <div className={style.itemTitleAndTranslationButton}>
                <div>
                  <h2 className={style.title}>
                    {this.buildExperienceTitle(
                      experience,
                      experienceTitleExtension,
                      shouldShowTranslation
                    )}
                  </h2>
                </div>
                <div className={style.translationButtonContainer}>
                  {maybeRenderTranslationButton(
                    catalog,
                    experienceHasTranslations,
                    featureFlags,
                    isPrint
                  )}
                </div>
              </div>
              <ItemDetails
                catalogId={catalog._id}
                alternateLanguage={alternateLanguage}
                dataClassName={style.pre}
                displayOptions={get(settings, 'experiences.displayOptions', {})}
                item={experience || {}}
                itemType='experiences'
                keys={catKeys}
                labelClassName={style.label}
                schemas={schemas || {}}
                replacements={get(settings, 'experiences.replacements', {})}
                translationLocaleCode={translationLocaleCode}
              />
            </div>
          )}
        </TopLevelPanel>
        {!isEmpty(addenda) && (
          <TopLevelPanel isMain={false}>
            <div className={style.addendaHeader}>
              <div className={style.cardTitle}>
                <FormattedMessage
                  {...messages.changesSincePublicationTitle}
                  values={{ title: title }}
                />
              </div>
            </div>
            {map(addenda, (addendum, index) => (
              <div className={style.addendumViewWrapper} key={index}>
                <AddendumView
                  addendum={addendum || {}}
                  settings={settings}
                  mq={mq}
                  schemas={schemas}
                />
              </div>
            ))}
          </TopLevelPanel>
        )}
      </div>
    )
  }
}
