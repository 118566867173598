/* Copyright © 2016 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */

import { isEmpty, merge } from 'lodash'
import ReactMDButton from 'react-md/lib/Buttons/Button'
import PropTypes from 'prop-types'
import React from 'react'

import FontIcon from '../../components/icon'

const FlatButton = props => {
  const {
    backgroundColor,
    fullWidth,
    icon,
    iconStyle,
    labelPosition,
    labelStyle,
    onTouchTap,
    style,
    ...rest
  } = props
  return (
    <ReactMDButton
      flat
      iconBefore={labelPosition === 'after' || isEmpty(labelPosition)}
      onClick={onTouchTap}
      style={merge(style, labelStyle, {
        background: backgroundColor || 'transparent',
        borderBottom: backgroundColor ? '2px solid #c4c4c4' : 'none',
        fontSize: '16px',
        textTransform: 'capitalize',
        width: fullWidth ? '100%' : 'auto',
        paddingLeft: icon ? '16px' : '0px'
      })}
      {...rest}
    >
      <FontIcon style={iconStyle}>{icon}</FontIcon>
      {props.children}
    </ReactMDButton>
  )
}

FlatButton.displayName = 'FlatButton'
FlatButton.propTypes = {
  backgroundColor: PropTypes.string,
  fullWidth: PropTypes.bool,
  href: PropTypes.string,
  icon: PropTypes.string,
  iconStyle: PropTypes.object,
  labelPosition: PropTypes.string,
  labelStyle: PropTypes.object,
  onTouchTap: PropTypes.func,
  style: PropTypes.object
}

export default FlatButton
