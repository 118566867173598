/* Copyright © 2016 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */

import { throttle } from 'lodash'
import PropTypes from 'prop-types'
import qs from 'querystring'
import React, { Component } from 'react'

const breakpoints = {
  sm: 775,
  md: 992,
  lg: 1200
}

function getSize () {
  const width = window.innerWidth
  if (width <= breakpoints.sm) {
    return 'small'
  } else if (width <= breakpoints.md) {
    return 'medium'
  } else if (width <= breakpoints.lg) {
    return 'large'
  }
  return 'huge'
}

export default (OldComponent, extraProps) =>
  class MediaQueryify extends Component {
    static displayName = 'MediaQueryHoc'

    static childContextTypes = {
      mq: PropTypes.string,
      isPrint: PropTypes.bool,
      windowWidth: PropTypes.number
    }

    constructor () {
      super()
      this.state = { mq: getSize(), windowWidth: window.innerWidth }
    }

    componentDidMount () {
      this.setupListeners()
    }

    getChildContext () {
      return {
        mq: this.state.mq,
        isPrint: this.isPrint(),
        windowWidth: this.state.windowWidth
      }
    }

    isPrint () {
      const { isPrint } = qs.parse(window.location.href.split('?')[1])
      return isPrint === 'true'
    }

    setupListeners () {
      this.onResize = throttle(() => {
        this.setState({ mq: getSize(), windowWidth: window.innerWidth })
      }, 100)
      window.addEventListener('resize', this.onResize)
    }

    componentWillUnmount () {
      window.removeEventListener('resize', this.onResize)
    }

    render () {
      return (
        <OldComponent
          mq={this.state.mq}
          windowWidth={this.state.windowWidth}
          {...extraProps}
          {...this.props}
        />
      )
    }
  }
