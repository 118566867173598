/* Copyright © 2016 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */

import axios from 'axios'
import { capitalize, get, isEmpty } from 'lodash'
import { singular, plural } from 'pluralize'
import spanishMessages from './../../i18n/translations/locales/es.json'
import frenchMessages from './../../i18n/translations/locales/fr.json'

let replacements

export async function loadReplacements () {
  try {
    const {
      data: { uiTextReplacements }
    } = await axios.get('/systemsettings/uiTextReplacements')
    replacements = uiTextReplacements || {}
  } catch (e) {
    replacements = {}
  }

  replacements['catalog.es'] = spanishMessages.catalog
  replacements['program.es'] = spanishMessages.program
  replacements['course.es'] = spanishMessages.course
  replacements['policy.es'] = spanishMessages.policy
  replacements['experience.es'] = spanishMessages.experience
  replacements['new.es'] = spanishMessages.new
  replacements['updated.es'] = spanishMessages.updated
  replacements['retired.es'] = spanishMessages.retired
  replacements['removed.es'] = spanishMessages.removed
  replacements['specialization.es'] = spanishMessages.specialization
  replacements['specializations.es'] =
    spanishMessages['specialization.specializations']
  // please be aware, for french specializations, the institution York replaces 'Specializations' with
  // the word 'Degree Programs' so we are using the French translation for 'Degree Programs' at the moment.
  // We'll need to fix this if other schools want to use a different name for Specialization in French.
  replacements['specialization.fr'] = frenchMessages.specialization
  replacements['specializations.fr'] = frenchMessages.specializations
  replacements['addendum.es'] = spanishMessages.addenda
  replacements['tableOfContents.es'] = spanishMessages.tableOfContents
  replacements['institutional-information.es'] =
    spanishMessages.institutionalInformation
  return replacements
}

export default function replace (key, toUpper = false, locale) {
  let lowerCaseKey = key.toLowerCase()
  let _singular = singular(lowerCaseKey)
  if (locale && locale !== 'en') {
    lowerCaseKey += '.' + locale
    _singular += '.' + locale
  }

  if (isEmpty(get(replacements, _singular))) {
    // handle keys with dashes
    return key
      .split('-')
      .map(word => (toUpper ? capitalize(word) : word))
      .join(' ')
  }
  if (_singular === lowerCaseKey) {
    return replacements[lowerCaseKey]
  }
  // for handling some special spanish pluralization
  if (locale !== 'en' && replacements[lowerCaseKey]) {
    return replacements[lowerCaseKey]
  }
  return plural(replacements[_singular])
}

export function setCache (newReplacements) {
  replacements = newReplacements
}
