/* Copyright © 2016 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */

import Button from 'react-md/lib/Buttons'
import ReactMDToolbar from 'react-md/lib/Toolbars'
import PropTypes from 'prop-types'
import React from 'react'

const AppBar = props => {
  const { onLeftIconButtonTouchTap, ...rest } = props
  return (
    <ReactMDToolbar
      nav={
        <Button icon onClick={onLeftIconButtonTouchTap}>
          menu
        </Button>
      }
      {...rest}
    />
  )
}

AppBar.displayName = 'AppBar'
AppBar.propTypes = {
  onLeftIconButtonTouchTap: PropTypes.func
}

export default AppBar
