/* Copyright © 2016 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */

import PropTypes from 'prop-types'
import { map } from 'lodash'
import React, { Component } from 'react'
import { injectIntl, defineMessages } from 'react-intl'

import replace from '../../lib/ui-text-replace'
import styles from './style'
import NavListItem from './nav-list-item'

const messages = defineMessages({
  archivedCatalogs: {
    id: 'nav-bar.archived-catalogs',
    defaultMessage: 'Archived Catalogs'
  }
})

const itemType = PropTypes.shape({
  isSubMenuItem: PropTypes.bool,
  items: PropTypes.array,
  to: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired
})

export class _NavBar extends Component {
  static displayName = 'NavBar'

  static propTypes = {
    archiveLink: PropTypes.string,
    items: PropTypes.arrayOf(itemType)
  }

  static contextTypes = {
    isPrint: PropTypes.bool
  }

  render () {
    const { items, archiveLink, intl, onItemClick, isCurrentRoute } = this.props
    const { isPrint } = this.context
    if (isPrint) return null
    return (
      <nav className={styles.navBar}>
        <ul className={styles.navList}>
          {map(items, item => {
            return (
              <NavListItem
                key={item._id}
                primaryText={replace(item.title, false, intl.locale)}
                route={item.to}
                isExternal={item.external}
                onClick={onItemClick}
                isCurrentRoute={isCurrentRoute}
                intl={intl}
                nestedItems={item.nestedItems}
              />
            )
          })}
          {!!archiveLink && (
            <NavListItem
              primaryText={intl.formatMessage(messages.archivedCatalogs)}
              route={archiveLink}
              onClick={onItemClick}
              isExternal
              isCurrentRoute={isCurrentRoute}
            />
          )}
        </ul>
      </nav>
    )
  }
}

export default injectIntl(_NavBar)
