/* Copyright © 2016 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */

import React from 'react'
import ReactMDDivider from 'react-md/lib/Dividers'

const Divider = props => {
  return <ReactMDDivider {...props} />
}

Divider.displayName = 'Divider'

export default Divider
