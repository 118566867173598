/* Copyright © 2016 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */

import { merge } from 'lodash'
import PropTypes from 'prop-types'
import React from 'react'
import ReactMDFontIcon from 'react-md/lib/FontIcons'

const ArrowBack = props => {
  return <FontIcon {...props}>arrow_back</FontIcon>
}
ArrowBack.displayName = 'ArrowBack'

const ArrowDown = props => {
  return <FontIcon {...props}>keyboard_arrow_down</FontIcon>
}
ArrowDown.displayName = 'ArrowDown'

const ArrowUp = props => {
  return <FontIcon {...props}>keyboard_arrow_up</FontIcon>
}
ArrowUp.displayName = 'ArrowUp'

const ChevronLeft = props => {
  return <FontIcon {...props}>chevron_left</FontIcon>
}
ChevronLeft.displayName = 'ChevronLeft'

const ChevronRight = props => {
  return <FontIcon {...props}>chevron_right</FontIcon>
}
ChevronRight.displayName = 'ChevronRight'

const Clear = props => {
  return <FontIcon {...props}>clear</FontIcon>
}
Clear.displayName = 'Clear'

const ContentAdd = props => {
  return <FontIcon {...props}>add</FontIcon>
}
ContentAdd.displayName = 'ContentAdd'

const Done = props => {
  return <FontIcon {...props}>done</FontIcon>
}
Done.displayName = 'Done'

const Download = props => {
  return <FontIcon {...props}>file_download</FontIcon>
}
Download.displayName = 'Download'

const Info = props => {
  return <FontIcon {...props}>info</FontIcon>
}
Info.displayName = 'Info'

const MoreVert = props => {
  return <FontIcon {...props}>more_vert</FontIcon>
}
MoreVert.displayName = 'MoreVert'

const Search = props => {
  return <FontIcon {...props}>search</FontIcon>
}
Search.displayName = 'Search'

const Visibility = props => {
  return <FontIcon {...props}>visibility</FontIcon>
}
Visibility.displayName = 'Visibility'

export {
  ArrowBack,
  ArrowDown,
  ArrowUp,
  ChevronLeft,
  ChevronRight,
  Clear,
  ContentAdd,
  Download,
  Done,
  Info,
  MoreVert,
  Search,
  Visibility
}

const FontIcon = props => {
  const { children, color, style, ...rest } = props
  return (
    <ReactMDFontIcon style={merge({ color }, style)} {...rest}>
      {children}
    </ReactMDFontIcon>
  )
}
FontIcon.displayName = 'FontIcon'
FontIcon.propTypes = {
  children: PropTypes.node,
  color: PropTypes.string,
  style: PropTypes.object
}

export default FontIcon
