import { find, get, isEmpty } from 'lodash'

export function getExtensionValue (
  catalogSettings,
  schemas,
  item,
  itemType,
  showTranslation
) {
  const locale = get(catalogSettings, 'locale')
  let extensionKey = get(catalogSettings, `${itemType}.titleExtension`, null)
  let extensionGadgetData = get(schemas, `${itemType}.${extensionKey}`)
  let extension = get(item, extensionKey)

  if (showTranslation) {
    extensionKey = extensionKey + 'translation'
    extensionGadgetData = get(schemas, `${itemType}.${extensionKey}`)
    extension = get(item, extensionKey)
  }

  if (extension) {
    switch (get(extensionGadgetData, 'type')) {
      case 'Checkboxes':
        let trueOptions = []
        const options = get(extensionGadgetData, 'options')
        Object.keys(extension).forEach(key => {
          if (!extension[key]) return
          const option = find(options, o => o.key === key)
          if (!option || get(option, 'hidden')) return
          trueOptions.push(option.text)
        })
        return trueOptions.sort().join(', ')
      default:
        if (locale !== 'en') {
          return get(
            extension,
            `translatedNames.${locale}`,
            get(extension, 'name', extension)
          )
        } else {
          return get(extension, 'name', extension)
        }
    }
  }
  return null
}

export function appendExtensionToTitle (
  catalogSettings,
  item,
  itemType,
  schemas,
  title,
  showTranslation
) {
  const addition = getExtensionValue(
    catalogSettings,
    schemas,
    item,
    itemType,
    showTranslation
  )
  if (!addition || isEmpty(addition)) return title
  return `${title} (${addition})`
}
