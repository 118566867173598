/* Copyright © 2016 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */

import PropTypes from 'prop-types'
import React from 'react'
import { scroller } from 'react-scroll'

import FAB from '../button/floating-action'
import { injectIntl, defineMessages } from 'react-intl'

const messages = defineMessages({
  goToTop: {
    id: 'go-to-top.go-to-top',
    defaultMessage: 'Go to Top'
  }
})

const GoToTop = ({ show, ...props }) => {
  return (
    <FAB
      tabindex={-1}
      aria-label={props.intl.formatMessage(messages.goToTop)}
      aria-hidden={!show}
      onClick={() =>
        scroller.scrollTo('top', {
          duration: 500,
          smooth: true
        })
      }
      {...props}
    >
      file_upload
    </FAB>
  )
}

GoToTop.displayName = 'GoToTop'

GoToTop.propTypes = {
  show: PropTypes.bool
}

export default injectIntl(GoToTop)
